import Badge from '@mui/material/Badge';
// import { useTheme } from '@mui/material/styles';

const UnreadDot = () => {
  // const theme = useTheme();
  return (
    <Badge
      variant="dot"
      sx={{
        '& .MuiBadge-badge': {
          backgroundColor: 'info.light',
        },
      }}
      className="unread-dot"
    />
  );
};

export default UnreadDot;
