// export { DetailType } from 'shared/queries/myReportsQueries/enums';

export enum DetailType {
  FREE_TEXT = 'freeText',
  OCCURRENCE_TYPE = 'occurrenceType',
  RISKMANAGEMENT_TYPE = 'riskManagementType',
  NR = 'nr',
  TIME_OCCURRED = 'timeOccurred',
  TIME_REPORTED = 'timeReported',
  TIME_COMPLETED = 'timeCompleted',
  WEEKDAY = 'weekday',
  MONTH = 'month',
  TIME_HOURS = 'timeHours',
  STATUS = 'status',
  STATUS_RISKMANAGEMENT = 'statusRiskManagement',
  SECTOR = 'sector',
  ORGANIZATION = 'organization',
  EXTERNAL_LOCATION = 'externalLocation',
  LATITUDE = 'latitude',
  LONGITUDE = 'longitude',
  UNIT_OCCURRENCE = 'unitOccurrence',
  UNIT_OCCURRENCE_SUM = 'unitOccurrenceSum',
  UNIT_EMPLOYMENT = 'unitEmployment',
  UNIT_EMPLOYMENT_SUM = 'unitEmploymentSum',
  UNIT_RISKMANAGEMENT = 'unitRiskManagement',
  UNIT_RISKMANAGEMENT_SUM = 'unitRiskManagementSum',
  RESPONSIBLE = 'responsible',
  RESPONSIBLE_OHS = 'responsibleOhs',
  INVESTIGATOR = 'investigator',
  EMPLOYMENT_TYPE = 'employmentType',
  PERSON_OBSERVED = 'personObserved',
  TITLE = 'title',
  OCCURRENCE_TITLE = 'occurrenceTitle',
  OCCURRENCE_DESCRIPTION = 'occurrenceDescription',
  OBJECT = 'object',
  SOURCE = 'source',
  FIRE = 'fire',
  FLAGGED = 'flagged',
  RISK = 'risk',
  ILLNESS_CAUSE = 'illnessCause',
  DIVERGENCE = 'divergence',
  OPERATION = 'operation',
  LOCATION = 'location',
  ACTIVITY = 'activity',
  OBJECT_INVOLVED = 'objectInvolved',
  OBJECT_ID = 'objectId',
  OCCURRENCE_CATEGORY = 'occurrenceCategory',
  SICK_LEAVE = 'sickLeave',
  ESTIMATED_SICK_LEAVE = 'estimatedSickLeave',
  ACTUAL_SICK_DAYS = 'actualSickDays',
  LTI = 'lti',
  MEDICALLY_TREATED = 'medicallyTreated',
  JOB_TRANSFER = 'jobTransfer',
  INJURY_TYPE = 'injuryType',
  INJURED_BODYPART = 'injuredBodypart',
  ILLNESS_TYPE = 'illnessType',
  RISK_BEFORE_ACTION = 'riskBeforeAction',
  RISK_AFTER_ACTION = 'riskAfterAction',
  RISK_REDUCTION = 'riskReduction',
  RISK_REDUCTION_PERCENTAGE = 'riskReductionPercentage',
  CAUSE = 'cause',
  BARRIER = 'barrier',
  CLAIM = 'claim',
  CLAIM_FK = 'claimFK',
  CLAIM_TFA = 'claimTFA',
  RELATED_OCCURRENCE = 'relatedOccurrence',
  RELATED_OCCURRENCE_COUNT = 'relatedOccurrenceCount',
  ACTION_COUNT = 'actionCount',
  ACTIONS = 'actions',
  ACTION_STEP = 'actionStep',
  ACTION_TYPE = 'actionType',
  ACTION_STATUS = 'actionStatus',
  ACTION_TIME_COMPLETED = 'actionTimeCompleted',
  ACTION_OWNER = 'actionOwner',
  ACTION_OWNER_OTHER = 'actionOwnerOther',
  ACTION_TITLE = 'actionTitle',
  ACTION_DESCRIPTION = 'actionDescription',
  ACTION_WORK_ORDER_NUMBER = 'actionWorkOrderNumber',
  FOLLOWUP_INFO = 'followUpInfo',
  FOLLOWUP_OTHER_INFO = 'followUpOtherInfo',
  FOLLOWUP_COMMENT = 'followUpComment',
  FOLLOWUP_OTHER_COMMENT = 'followUpOtherComment',
  FOLLOWUP_STATUS = 'followUpStatus',
  FOLLOWUP_TIME_COMPLETED = 'followUpTimeCompleted',
  FOLLOWUP_OK = 'followUpOk',
  FOLLOWUP_OTHER_OK = 'followUpOtherOk',
  FOLLOWUP_RESPONSIBLE = 'followUpResponsible',
  FOLLOWUP_OTHER_RESPONSIBLE = 'followUpOtherResponsible',
  TEMPLATE = 'template',
  OK = 'ok',
  OK_TRUE = 'okTrue',
  OK_FALSE = 'okFalse',
  BEHAVIOR = 'behavior',
  SAFETY_OBSERVATION_TYPE = 'safetyObservationType',
  BARRIER_TYPE = 'barrierType',
  CAUSE_TYPE = 'causeType',
  INVESTIGATION = 'investigation',
  INVESTIGATION_TITLE = 'investigationTitle',
  INVESTIGATION_DESCRIPTION = 'investigationDescription',
  INVESTIGATION_STATUS = 'investigationStatus',
  INVESTIGATION_TIME_STARTED = 'investigationTimeStarted',
  INVESTIGATION_TIME_COMPLETED = 'investigationTimeCompleted',
  INVESTIGATION_INVESTIGATOR = 'investigationInvestigator',
  INVESTIGATION_PARTICIPANTS = 'investigationParticipants',
  PARTICIPANTS = 'participants',
  OCCUPATION = 'occupation',
  GENDER = 'gender',
  OCCUPATION_YEARS = 'occupationYears',
  AGE = 'age',
  WORKING_HOURS = 'workingHours',
  IMAGES = 'images',
  ATTACHMENTS_OTHER = 'attachmentsOther',
  FALLING_ROCK = 'fallingRock',
  PERFORMANCE_OCCURRED = 'performanceOccurred',
  PERFORMANCE_OCCURRED_COMPLETED = 'performanceOccurredCompleted',
  PERFORMANCE_REPORTED = 'performanceReported',
  PERFORMANCE_REPORTED_INVESTIGATED = 'performanceReportedInvestigated',
  PERFORMANCE_REPORTED_ACTIONS_COMPLETED = 'performanceReportedActionsCompleted',
  PERFORMANCE_REPORTED_COMPLETED = 'performanceReportedCompleted',
  PERFORMANCE_INVESTIGATED = 'performanceInvestigated',
  PERFORMANCE_INVESTIGATION_STARTED_ACTIONS_COMPLETED = 'performanceInvestigationStartedActionsCompleted',
  PERFORMANCE_INVESTIGATED_ACTIONS_COMPLETED = 'performanceInvestigatedActionsCompleted',
  PERFORMANCE_INVESTIGATED_COMPLETED = 'performanceInvestigatedCompleted',
  PERFORMANCE_FOLLOW_UP = 'performanceFollowUp',
  PERFORMANCE_APPROVAL = 'performanceApproval',
  PERFORMANCE_OCCURRED_TODAY = 'performanceOccurredToday',
  PERFORMANCE_REPORTED_TODAY = 'performanceReportedToday',
  PERFORMANCE_INVESTIGATION_STARTED_TODAY = 'performanceInvestigationStartedToday',
  PERFORMANCE_INVESTIGATED_TODAY = 'performanceInvestigatedToday',
  PERFORMANCE_ACTIONS_COMPLETED_TODAY = 'performanceActionsCompletedToday',
  PERFORMANCE_FOLLOW_UP_COMPLETED_TODAY = 'performanceFollowUpCompletedToday',
  USER_DEFINED = 'userDefined',
}

export enum Activity {
  PLANNING,
  IMPLEMENT,
  REPORTING,
  INVESTIGATION,
  ACTION_HANDLING,
  ACTION,
  FOLLOW_UP,
  FOLLOW_UP_OTHER,
  COMPLETE,
}

export enum RiskLevel {
  ELIMINATED = 0,
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
}

export const enum State {
  DONE,
  CURRENT,
  NOT_DONE,
  HAS_ALERT,
}

export enum NewsLevel {
  Afa = 'Afa',
  Sector = 'Sector',
  Group = 'Group',
  Organization = 'Organization',
  Unit = 'Unit',
}

export enum Func {
  RESPONSIBLE = 'responsible',
  RESPONSIBLE_OCCURRENCE = 'responsibleOccurrence',
  OHS_CONTACTED = 'ohsContacted',
  INVESTIGATOR = 'investigator',
  ACTON_OWNER = 'actionOwner',
  FOLLOW_UP_RESPONSIBLE = 'followUpResponsible',
  FOLLOW_UP_OTHER_RESPONSIBLE = 'followUpOtherResponsible',
  PARTICIPANT = 'participant',
}

export enum TodoType {
  ALL = 0,
  CONFIRM = 1,
  COMPLETE = 2,
  INVESTIGATE = 3,
  TAKE_ACTION = 4,
  FOLLOW_UP = 5,
  REDECIDE = 6,
  REMIND = 7,
  MONITOR = 8,
  REPORTER = 9,
}

export enum MainFilterType {
  ALL = 0,
  RISK_MANAGEMENT = 1,
  OCCURRENCES = 2,
}

export enum ContentLevel {
  Sector = 'sector',
  Grouping = 'grouping',
  Organization = 'organization',
  Responsibility = 'responsibilityArea',
  User = 'user',
  Unit = 'unit',
}

export enum WidgetTypes {
  DaysSinceAccident = 'daysSinceAccident',
  OpenRisks = 'openRisks',
  ActionsOngoing = 'actionsOngoing',
  OccurrencesOngoing = 'occurrencesOngoing',
  RiskManagementOngoing = 'riskManagementsOngoing',
  MyIA = 'myIa',
  News = 'news',
  ActionsCompleted = 'actionsCompleted',
  OccurrencesCompleted = 'occurrences_completed',
  RiskManagementsCompleted = 'riskmanagementsCompleted',
  RiskReduction = 'riskReduction',
  AccidentsWithAbsence = 'accidentsWithAbsence',
  ReportedOccurrences = 'reportedOccurrences',
  PerformedRiskmanagements = 'performedRiskmanagemenets',
  CustomCharts = 'customCharts',
}
