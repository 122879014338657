import endOfDay from 'date-fns/endOfDay';
import differenceInDays from 'date-fns/differenceInDays';
import startOfYear from 'date-fns/startOfYear';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import { useGetConfig } from 'shared/startpageService/settings';
import { Period } from 'shared/types/types';

export const useGetPeriod = () => {
  const { config } = useGetConfig();
  const period: Period = config?.period ?? 365;
  const today = new Date();

  const endDate = endOfDay(today);
  const daysToSubtract = period === 'during' ? differenceInDays(today, startOfYear(today)) : period;

  const startDate = startOfDay(subDays(endDate, daysToSubtract));

  return { startDate, endDate, period };
};
