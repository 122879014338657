import React, { createContext, useContext, useState } from 'react';

interface IGlobalContext {
  userId?: string;
  userGuid?: string;
  userCulture?: string;
  industryId?: number;
  companyGuid?: string;
  companyId?: string;
  companyName?: string;
  baseUrl?: string;
  registersLTI?: string;
  groupLevelPossible?: string;
  userIsAdmin?: string;
  sectorName?: string;
  userCompanies?: Record<number, string>;
  showLoggedOutDialog?: boolean;
  setShowLoggedOutDialog?: (show: boolean) => void;
  unitId?: number;
  unitName?: string;
}

const GlobalContext = createContext<IGlobalContext>({});

interface Props {
  defaultValues: IGlobalContext;
}
export const GlobalContextProvider = ({ children, defaultValues }: React.PropsWithChildren<Props>) => {
  const [showLoggedOutDialog, setShowLoggedOutDialog] = useState(false);
  const _defaultValues = {
    ...defaultValues,
    showLoggedOutDialog,
    setShowLoggedOutDialog,
    baseUrl: window.location.origin,
  };
  return <GlobalContext.Provider value={_defaultValues}>{children}</GlobalContext.Provider>;
};

export const useGlobalContext = (): IGlobalContext => useContext<IGlobalContext>(GlobalContext);
