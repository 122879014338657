import OverdueIcon from '@mui/icons-material/PriorityHigh';
import { Stack, Typography } from '@mui/material';
import { useTranslations } from 'shared/hooks/useTranslations';

interface Props {
  date?: string;
  isOverdue?: boolean;
}
const DateWithIcon = ({ date, isOverdue }: Props) => {
  const { t } = useTranslations();

  return (
    <Stack direction="row" spacing={0.2}>
      {date && (
        <Typography variant="body2" sx={{ color: isOverdue ? '#fd6107' : 'black' }}>
          {date}
        </Typography>
      )}
      {date && isOverdue && (
        <OverdueIcon aria-label={t('Selections_StatusOverdue')} sx={{ fontSize: 18, color: '#fd6107' }} />
      )}
    </Stack>
  );
};

export default DateWithIcon;
