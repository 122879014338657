import { Typography } from '@mui/material';
import { useTranslations } from 'shared/hooks/useTranslations';

interface Props {
  title: string;
  bold?: boolean;
}

const ReportTitleTranslated = ({ title, bold = false }: Props) => {
  const { t } = useTranslations();

  return (
    <Typography variant="body2" sx={{ fontWeight: bold ? 'bold' : 'regular' }}>
      {title.startsWith('___') ? t(`ReportTitle_${title}`) : title}
    </Typography>
  );
};

export default ReportTitleTranslated;
