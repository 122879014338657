import { ThemeProvider } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import themeJson from '@ia/theme/theme.json';
import IaAppBar from './IaAppBar';
import SystemStatus from './SystemStatus';
import { useEffect } from 'react';
import BetaBanner from './BetaBanner';
import Footer from './Footer/Footer';
import { LoggingErrorBoundary } from 'shared/error/LoggingErrorBoundary';
import WidgetGrid from './WidgetGrid';

const globalStyles = {
  '*': {},
  body: {
    // backgroundColor: '#f5f5f5',
    backgroundColor: '#e5e5e5',
    // color: '#333',
    padding: 0,
    margin: 0,
  },
};

const theme = createTheme({
  ...themeJson,
  // TODO: Styling
  palette: {
    ...themeJson.palette,
    primary: {
      light: '#666',
      main: '#020678', // '#333',
      dark: '#020678', // '#000',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
  typography: {
    fontSize: 14,
    h1: {
      fontWeight: 700,
      fontSize: '1.3rem',
    },
    h2: {
      fontWeight: 700,
      fontSize: '1rem',
    },
    h3: {
      fontWeight: 700,
      fontSize: '0.9rem',
    },
    h4: {
      fontStyle: 'italic',
      color: '#333',
      fontSize: '0.8rem',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: '1rem',
          },
        },
      },
    },
  },
});
const Container = styled('div')`
  min-height: 100vh;
  position: relative;
  padding-bottom: 3rem;
  box-sizing: border-box;
`;
const NewMyIA = () => {
  useEffect(() => {
    if (!window.localStorage.getItem('beta_startpage_tester')) {
      window.localStorage.setItem('beta_startpage_tester', 'true');
    }
  }, []);
  return (
    <LoggingErrorBoundary>
      <Container>
        <IaAppBar />
        <WidgetGrid />
        <SystemStatus />
        <BetaBanner />
        <Footer />
      </Container>
    </LoggingErrorBoundary>
  );
};

export default () => (
  <ThemeProvider theme={theme}>
    <GlobalStyles styles={globalStyles} />
    <NewMyIA />
  </ThemeProvider>
);
