import { Alert, IconButton, Stack, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useTranslations } from 'shared/hooks/useTranslations';

const BetaBanner = () => {
  const { t } = useTranslations();
  const [hasRead, setHasRead] = useState(false);
  const handleClose = () => {
    setHasRead(true);
  };
  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );
  return (
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={!hasRead}>
      <Alert severity="info" icon={false} action={action} sx={{ background: '#333', color: '#fff' }}>
        <Stack spacing={2}>
          <Typography>{t('Startpage_BetaInformation')}</Typography>
        </Stack>
      </Alert>
    </Snackbar>
  );
};

export default BetaBanner;
