export enum SortingDirection {
  Desc = 'desc',
  Asc = 'asc',
}

export enum UnitType {
  Occurrence = 'occurrence',
  Employment = 'employment',
}

export enum PeriodUnit {
  Year = 'year',
  Month = 'month',
  Quarter = 'quarter',
  Week = 'week',
  Day = 'day',
}
export enum PeriodType {
  Occurred = 'occurred',
  Reported = 'reported',
  Completed = 'completed',
  Investigated = 'investigated',
  ActionsCompleted = 'actionsCompleted',
  FollowedUp = 'followedUp',
}

export enum ContentType {
  ALL = 'all',
  OCCURRENCE = 'occurrence',
  RISKMANAGEMENT = 'riskManagement',
  RISK = 'risk',
  ACTION = 'action',
  INVESTIGATION = 'investigation',
  FOLLOW_UP = 'followUp',
}

export const AllContentTypes = [
  ContentType.RISK,
  ContentType.ACTION,
  ContentType.RISKMANAGEMENT,
  ContentType.OCCURRENCE,
];

export enum PersonCategoryString {
  OWN_STAFF = 'ownStaff',
  ENTREPRENEUR = 'entrepreneur',
  OTHER_PERSON = 'otherPerson',
}

export enum OccurrenceTypes {
  RISK_OBSERVATION = 7,
  NEAR_MISS = 4,
  ACCIDENT = 1,
  COMMUTING_ACCIDENT = 2,
  WORK_RELATED_ILLNESS = 3,
  ENVIRONMENT = 5,
  QUALITY = 9,
  PROPERTY_SECURITY = 8,
  IMPROVMENT_PROPOSAL = 11,
  OTHER_DIVERGENCE = 12,
  SAFETY_OBSERVATION = 13,
  PUPIL = 14,
  PRESCHOOLER = 15,
  CARE_RECIPIENT = 16,
  PATIENT = 17,
  VICTIMIZATION = 18,
}

export enum OccurrenceStatus {
  RegistreradviaLightmodul = 1,
  Grundregistrerad = 2,
  Utredningpågår = 3,
  Åtgärdshanteringpågår = 4,
  Klarejuppföljd = 5,
  Klar = 6,
  Klarejgodkänd = 7,
  Utredningklar = 8,
}

export enum RiskManagementStatus {
  PLANNED = 1,
  PERFORMED = 3,
  COMPLETED = 6,
}

export enum MainStatus {
  ALL = 0,
  ONGOING = 1,
  OVERDUE = 2,
  COMPLETED = 3,
}

export enum WithWithout {
  WITH = 1,
  WITHOUT = 2,
}

export enum ViewMode {
  MY = 1,
  GROUP = 2,
  REPORT = 3,
  RISK_MANAGEMENT = 4,
  INCIDENT = 5,
}

export const enum Activity {
  PLANNING,
  PERFORMED,
  IMPLEMENT,
  REPORTING,
  REGISTRATION,
  INVESTIGATION,
  ACTION_HANDLING,
  ACTION,
  FOLLOW_UP,
  FOLLOW_UP_OTHER,
  COMPLETE,
}
