import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, styled } from '@mui/material';
import { useGlobalContext } from '../../../globalContext';
import { useGetSystemAlert } from './systemAlertQueries';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

const SystemStatus = () => {
  const [showAlert, setShowAlert] = useState(false);
  const { userGuid } = useGlobalContext();
  const { data: systemAlertData } = useGetSystemAlert();
  const header = decodeURIComponent(systemAlertData?.SystemAlert.Header ?? '');
  const message = decodeURIComponent(systemAlertData?.SystemAlert.Message ?? '');

  useEffect(() => {
    const lastSeenAlertId = Number(localStorage.getItem(`lastAlertSeen_${userGuid}`));
    setShowAlert(Boolean(systemAlertData && lastSeenAlertId !== systemAlertData?.SystemAlert.Id));
  }, [systemAlertData, userGuid]);

  const handleClose = () => {
    if (systemAlertData?.SystemAlert.Id !== undefined) {
      localStorage.setItem(`lastAlertSeen_${userGuid}`, systemAlertData.SystemAlert.Id.toString());
      setShowAlert(false);
    }
  };

  return (
    <>
      <Dialog open={showAlert} onClose={handleClose}>
        <Stack direction="row" justifyContent={'space-between'}>
          <DialogTitle>{header}</DialogTitle>
          <DialogActions>
            <StyledCloseIcon onClick={handleClose} />
          </DialogActions>
        </Stack>
        <DialogContent>
          <DialogContentText style={{ whiteSpace: 'pre' }} dangerouslySetInnerHTML={{ __html: message }} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SystemStatus;
