import { ContentLevel, RiskLevel } from 'shared/types/enums';
import { useGetMyReportsData } from '../common/myReportsQuery';
import { IMyReportsWidget } from '../common/types';
import { RequestTypes } from '../common/requestTypes';
import { IMyReportsDetailsResponseItem } from 'shared/types/types';

interface OpenRisks {
  totalCount: number;
  highCount: number;
  mediumCount: number;
  lowCount: number;
}
export const transformOpenRisksData = (originalData?: IMyReportsDetailsResponseItem[]) => {
  const first = originalData?.[0];
  if (!first) {
    return {
      totalCount: 0,
      highCount: 0,
      mediumCount: 0,
      lowCount: 0,
    };
  }
  const data: OpenRisks = {
    totalCount: first?.total?.reduce((acc, cur) => cur.data + acc, 0) ?? 0,
    highCount: first?.total?.find((x) => x.id === RiskLevel.HIGH)?.data ?? 0,
    mediumCount: first?.total?.find((x) => x.id === RiskLevel.MEDIUM)?.data ?? 0,
    lowCount: first?.total?.find((x) => x.id === RiskLevel.LOW)?.data ?? 0,
  };
  return data;
};

interface OpenRisksResponse {
  data: OpenRisks;
  isLoading: boolean;
}

export const useGetOpenRisksData = (
  level?: ContentLevel,
  request1?: IMyReportsWidget,
  request2?: IMyReportsWidget
): OpenRisksResponse => {
  const { data: openRisksOccurrenceResponse, isLoading: isLoadingOccurrence } = useGetMyReportsData(
    request1 ? RequestTypes.CustomChart : RequestTypes.OpenRisksOccurrence,
    request1,
    level
  );
  const { data: openRisksRiskManagementResponse, isLoading: isLoadingRiskManagements } = useGetMyReportsData(
    request2 ? RequestTypes.CustomChart : RequestTypes.OpenRisksRiskManagement,
    request2,
    level
  );

  const occurrenceData = transformOpenRisksData(openRisksOccurrenceResponse);
  const rmData = transformOpenRisksData(openRisksRiskManagementResponse);

  const data = {
    totalCount: Number(occurrenceData?.totalCount) + Number(rmData?.totalCount),
    highCount: Number(occurrenceData?.highCount) + Number(rmData?.highCount),
    mediumCount: Number(occurrenceData?.mediumCount) + Number(rmData?.mediumCount),
    lowCount: Number(occurrenceData?.lowCount) + Number(rmData?.lowCount),
  };

  return {
    data,
    isLoading: isLoadingOccurrence && isLoadingRiskManagements,
  };
};
