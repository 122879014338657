import { Stack, Typography } from '@mui/material';
import { GoldStar } from 'shared/UI/Icons/GoldStar';

interface Props {
  count: number;
  showGoldStar?: boolean;
  extra?: string;
}

const BigNumber = ({ count, showGoldStar = false, extra }: Props) => {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: '80%' }}>
      {showGoldStar && <GoldStar />}
      <Typography sx={{ fontSize: 40, fontWeight: 'bold', color: 'black' }}>
        {count} {extra}
      </Typography>
    </Stack>
  );
};
export default BigNumber;
