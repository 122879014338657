import { TodoType } from 'shared/types/enums';
import { Count } from '../common/types';
import { TodoItem } from 'shared/types/types';

export type Counts = Partial<Record<TodoType, Count>>;

export const getCounts = (items: TodoItem[], todoTypesLabels: Record<TodoType, string>) => {
  const types = items.reduce((acc: Counts, i) => {
    if (!acc[i.todoType]) {
      acc[i.todoType] = {
        count: 0,
        labelKey: todoTypesLabels[i.todoType],
      };
    }
    return {
      ...acc,
      [i.todoType]: {
        count: (acc[i.todoType]?.count ?? 0) + 1,
        labelKey: todoTypesLabels[i.todoType],
      },
    };
  }, {});

  return {
    ...types,
    [TodoType.ALL]: {
      count: items.length,
      labelKey: todoTypesLabels[TodoType.ALL],
    },
  };
};
