import { Grid, Typography } from '@mui/material';

interface Props {
  title: string;
}

const SectionTitle = ({ title }: Props) => {
  return (
    <Grid item xs={12}>
      <Typography variant="h6">{title}</Typography>
    </Grid>
  );
};

export default SectionTitle;
