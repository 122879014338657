import { IFilter, IMyReportsWidget, ISection } from 'shared/startpageService/common/types';
import { DetailType } from 'shared/types/enums';
export const getRequestForSection = (section: ISection): IMyReportsWidget | undefined => {
  const distributions = section?.settings.details?.distributions ?? [];
  const distribution = distributions[0];
  const serie = section?.settings.mainChart?.load[0] ?? DetailType.STATUS;
  return getCustomRequest(section?.settings.filter, distribution?.type, serie);
};

export const getCustomRequest = (
  filter?: IFilter,
  detailType?: DetailType,
  serieDetailType?: DetailType
): IMyReportsWidget | undefined => {
  if (!filter || !detailType || !serieDetailType) return;
  return {
    request: {
      filter: {
        base: filter.base,
        details: filter.details,
      },
      details: {
        load: [detailType],
      },
      mainChart: {
        load: [serieDetailType],
      },
      list: {
        load: [],
      },
    },
    settings: {
      usePeriod: true,
    },
  };
};
