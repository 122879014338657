/* eslint-disable no-eval */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient } from '@tanstack/react-query';
import * as AllComponents from './sections';
import { GlobalContextProvider } from './globalContext';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';

import './global.scss';

const getProps = (el: Element): any => {
  const names = el
    .getAttributeNames()
    .filter((name) => name !== 'data-react')
    .filter((name) => name.startsWith('data-'));
  const obj: any = {};

  for (let i = 0; i < names.length; i++) {
    const name = names[i].replace('data-', '');
    obj[name] = el.getAttribute(names[i]);
  }

  return obj;
};

const getComponent: any = (name: string, props: any) => {
  const Component = (AllComponents as any)[name];
  if (Component !== undefined) {
    return <Component.default {...props} componentName={name} />;
  }

  // eslint-disable-next-line no-console
  console.warn(`Module ${name} does not exist`);
  return null;
};

const docReady: any = (fn: () => void) => {
  if (document.readyState === 'complete' || document.readyState === 'interactive') {
    setTimeout(fn, 1);
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      gcTime: 24 * 60 * 60 * 1000,
      retry: false,
    },
  },
});

const localStoragePersister = createSyncStoragePersister({ storage: window.localStorage });

docReady(function () {
  const elements = document.querySelectorAll('[data-react]');

  elements.forEach((el) => {
    const name = el.getAttribute('data-react') ?? '';
    const root = ReactDOM.createRoot(el);
    const props = getProps(el);

    root.render(
      <React.StrictMode>
        <PersistQueryClientProvider client={queryClient} persistOptions={{ persister: localStoragePersister }}>
          <GlobalContextProvider defaultValues={(window as any).GlobalConfig}>
            {getComponent(name, props)}
          </GlobalContextProvider>
        </PersistQueryClientProvider>
      </React.StrictMode>
    );
  });
});
