import { useGlobalContext } from 'globalContext';
import { RequestTypes } from '../common/requestTypes';
import { getAllDetailViewInfo } from './model';
import { useGetTodoListFromServer } from '../list';

export const useGetDetailView = (itemId: number, isRiskManagement?: boolean) => {
  const type = isRiskManagement ? RequestTypes.RiskManagementList : RequestTypes.OccurrenceList;
  const { userId } = useGlobalContext();
  const { data, ...query } = useGetTodoListFromServer(type);

  const detailView = getAllDetailViewInfo(
    Number(userId),
    data?.find((item) => item.id === itemId)
  );
  return {
    ...query,
    data: detailView,
  };
};
