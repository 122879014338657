import { useCallback } from 'react';
import { TodoType } from 'shared/types/enums';
import { useGetConfig, useSaveConfig } from 'shared/startpageService/settings';
import { SortType, SortOrder } from './utils';
import { MainFilterSettings } from 'shared/startpageService/common/types';

export const useFiltersAndSorting = () => {
  const { config } = useGetConfig();
  const saveConfig = useSaveConfig();

  const setSortType = useCallback(
    (type?: SortType, order?: SortOrder) => {
      const newSortOrder = order ?? config?.todoList?.sortOrder ?? 1;
      saveConfig('todoList', {
        ...config?.todoList,
        sortType: type,
        sortOrder: newSortOrder,
      });
    },
    [config, saveConfig]
  );

  const toggleSortOrder = useCallback(() => {
    const newSortOrder = (config?.todoList?.sortOrder ?? 1) * -1;
    saveConfig('todoList', {
      ...config?.todoList,
      sortOrder: newSortOrder,
    });
  }, [config, saveConfig]);

  const setShowOnlyOneRowPerId = useCallback(
    (showOnlyOneRowPerId: boolean) => {
      saveConfig('todoList', {
        ...config?.todoList,
        showOnlyOneRowPerId,
        currentFilter: TodoType.ALL,
      });
    },
    [config, saveConfig]
  );

  const setCurrentFilter = useCallback(
    (currentFilter: TodoType) => {
      saveConfig('todoList', {
        ...config?.todoList,
        currentFilter,
        showOnlyOneRowPerId: false,
      });
    },
    [config, saveConfig]
  );

  const setMainFilterSettings = useCallback(
    (mainFilterSettings: MainFilterSettings) => {
      saveConfig('todoList', {
        ...config?.todoList,
        currentFilter: TodoType.ALL,
        mainFilterSettings,
      });
    },
    [config, saveConfig]
  );

  return {
    setSortType,
    sortType: config?.todoList?.sortType ?? SortType.Date,
    toggleSortOrder,
    sortOrder: config?.todoList?.sortOrder ?? SortOrder.ASC,
    showOnlyOneRowPerId: config?.todoList?.showOnlyOneRowPerId,
    setShowOnlyOneRowPerId,
    currentFilter: config?.todoList?.currentFilter ?? TodoType.ALL,
    setCurrentFilter,
    mainFilterSettings: config?.todoList?.mainFilterSettings,
    setMainFilterSettings,
  };
};
