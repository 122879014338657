import { useCallback } from 'react';
import { iafetch } from 'shared/utils';
import { useGlobalContext } from '../../globalContext';
import { useQuery } from 'shared/queries/useQueryWithError';

export const useGetTranslations = (target: string) => {
  const { userCulture, baseUrl } = useGlobalContext();
  const culture = userCulture ?? 'SV-se';
  const url = `${baseUrl ?? ''}/PreventionIA/IA/api/Web/Global/ResourceObjects/${target}`;
  const fetchTranslations = (): Promise<Record<string, string>> => iafetch(url);

  return useQuery<Record<string, string>>({
    queryKey: ['translations', culture, target],
    queryFn: fetchTranslations,
    enabled: Boolean(baseUrl),
    staleTime: 5, // 60 * 60 * 1000,
  });
};

export type Translate = (key: string, defaultString?: string) => string;
export type TranslateWithParameters = (key: string, parameters: string[], defaultString?: string) => string;
export type TranslateWithPluralized = (
  key: string,
  count: number,
  defaultStringSinglar?: string,
  defaultStringPluralized?: string
) => string;

const useTranslations = (): { t: Translate; tFormat: TranslateWithParameters; tPluralize: TranslateWithPluralized } => {
  const { data, isLoading } = useGetTranslations(
    'Startpage,Period,Report,ReportTitle,General,News,Selections,RiskManagement,Favorites'
  );

  const format = (text: string, parameters: string[]) =>
    text.replace(/{(\d+)}/g, function (match, number) {
      return typeof parameters[number] !== 'undefined' ? parameters[number] : match;
    });

  const t = useCallback(
    (key: string, defaultString?: string) => {
      if (isLoading) return '';
      if (data?.[key]) return data?.[key];

      // console.warn(`[translation] No translation exists for key: ${key} ${defaultString}`);
      if (defaultString) return defaultString;
      return key;
    },
    [data, isLoading]
  );

  const tFormat = useCallback(
    (key: string, parameters: string[], defaultString?: string) => {
      if (isLoading) return '';

      if (data?.[key]) {
        return format(data?.[key], parameters);
      }

      // console.warn(`[translation] No translation exists for key: ${key} ${defaultString}`);
      if (defaultString) return format(defaultString, parameters);
      return key;
    },
    [data, isLoading]
  );

  const tPluralize = useCallback(
    (singularKey: string, count: number, defaultStringSingular?: string, defaultStringPluralized?: string) => {
      const parameters = [String(count)];
      const isSingular = count === 1;

      const key = isSingular ? singularKey : `${singularKey}_Plural`;

      if (isLoading) return '';

      if (data?.[key]) {
        return format(data?.[key], parameters);
      }

      if (isSingular && defaultStringSingular) return format(defaultStringSingular, parameters);
      if (!isSingular && defaultStringPluralized) return format(defaultStringPluralized, parameters);
      return key;
    },
    [data, isLoading]
  );

  return { t, tFormat, tPluralize };
};

export { useTranslations };
