import Stack from '@mui/material/Stack';
import { useFiltersAndSorting } from '../useFiltersAndSorting';
import { Counts } from '../utils';
import { TodoType } from 'shared/types/enums';
import { Button } from '@mui/material';

interface Props {
  counts?: Counts;
  direction: 'row' | 'column';
}
const TodoFilterButtons = ({ counts, direction }: Props) => {
  const { currentFilter, setCurrentFilter } = useFiltersAndSorting();
  const handleSetFilter = (type: TodoType) => {
    setCurrentFilter(type);
  };

  return (
    <Stack direction={direction}>
      {Object.entries(counts ?? {})
        ?.filter(([, item]) => item.count > 0)
        .map(([type, { count, labelKey }]) =>
          currentFilter === (Number(type) as TodoType) ? (
            <Button key={type} color={'primary'} variant={'contained'}>{`${labelKey} (${count})`}</Button>
          ) : (
            <Button
              key={type}
              variant="contained"
              color={'inherit'}
              aria-label="outlined primary button group"
              onClick={() => handleSetFilter(Number(type))}
            >{`${labelKey} (${count})`}</Button>
          )
        )}
    </Stack>
  );
};

export default TodoFilterButtons;
