import { ErrorBoundary, ErrorBoundaryProps } from 'react-error-boundary';
import { useErrorLogging } from './useErrorLogging';
import { Typography } from '@mui/material';
import { useTranslations } from 'shared/hooks/useTranslations';
import { ErrorInfo, PropsWithChildren } from 'react';

type ErrorBoundaryPropsWithoutFallback = PropsWithChildren<{
  fallback?: never;
  FallbackComponent?: never;
  fallbackRender?: never;
  onError?: (error: Error, info: ErrorInfo) => void;
  onReset?: (
    details:
      | {
          reason: 'imperative-api';
          args: any[];
        }
      | {
          reason: 'keys';
          prev: any[] | undefined;
          next: any[] | undefined;
        }
  ) => void;
  resetKeys?: any[];
}>;

export const LoggingErrorBoundary = (props: ErrorBoundaryProps | ErrorBoundaryPropsWithoutFallback) => {
  const { logError } = useErrorLogging();
  const { t } = useTranslations();

  const fallback = props.fallback ?? <Typography>{t('General_ExceptionHeader')}</Typography>;

  return (
    <ErrorBoundary {...props} fallback={fallback as unknown as any} onError={logError}>
      {props.children}
    </ErrorBoundary>
  );
};
