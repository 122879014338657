import { Grid, Typography } from '@mui/material';
import { useGetNewsForLevel } from 'sections/startpage/News/newsQueries';
import { useGetLinkContentDataFromserver } from 'shared/startpageService/charts';
import { ContentLevel, NewsLevel } from 'shared/types/enums';
import CustomChart from 'sections/startpage/CustomCharts/CustomChart';
import News from '../../News';
import { useGlobalContext } from 'globalContext';
import { useTranslations } from 'shared/hooks/useTranslations';
import SectionTitle from './SectionTitle';
import { useGetPeriodLabel } from 'shared/hooks/useGetPeriodAndLevelLabels';
import { LoggingErrorBoundary } from 'shared/error/LoggingErrorBoundary';

interface Props {
  xs: number;
  xsContent?: number;
}

const Organization = ({ xs, xsContent }: Props) => {
  const { t } = useTranslations();
  const { hasNews } = useGetNewsForLevel(NewsLevel.Organization);
  const maxNumberOfSlots = hasNews ? 3 : 4;
  const { selectedContentOrg, selectedContentOrgCount } = useGetLinkContentDataFromserver();
  const { companyName } = useGlobalContext();
  const periodLabel = useGetPeriodLabel();

  const chartsToShow = selectedContentOrg.slice(0, maxNumberOfSlots);
  const sectionTitle = `${periodLabel}, ${companyName}`;

  const GetXsContent = () => {
    if (xsContent) return xsContent;
    const numberOfContent = hasNews ? Number(selectedContentOrgCount) + 1 : selectedContentOrgCount;
    if (numberOfContent >= 4) return 3;
    return 12 / numberOfContent;
  };

  return (
    <LoggingErrorBoundary fallback={<Typography>{t('General_ExceptionHeader')}</Typography>}>
      <Grid item container xs={xs}>
        <SectionTitle title={sectionTitle} />
        <Grid item container spacing={2} xs={12}>
          {hasNews && (
            <Grid item xs={GetXsContent()}>
              <News level={NewsLevel.Organization} />
            </Grid>
          )}
          {chartsToShow.map((content) => (
            <Grid item xs={GetXsContent()} key={content.Id}>
              <CustomChart linkId={content.Id} level={ContentLevel.Organization} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </LoggingErrorBoundary>
  );
};

export default Organization;
