import { Stack, Typography, styled } from '@mui/material';
import { IRiskAssessment } from 'shared/types/types';
import RiskLevelWithCount from './RiskLevelWithCount';
import { RiskLevel } from 'shared/types/enums';

export const CountAndLevel = styled('div')`
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
`;

export const getRiskColorText = (riskLevel?: RiskLevel) => {
  return riskLevel === 2 ? '#000' : '#fff';
};

interface Props {
  riskAssessment: IRiskAssessment;
  title?: string;
}
const RiskAssessment = ({ riskAssessment, title }: Props) => {
  return (
    <Stack direction="row" spacing={1.5} marginBottom="0.25rem" alignItems="center">
      {title && (
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          {title}:
        </Typography>
      )}
      {riskAssessment.riskAssessments?.map(({ level, value, count }) => {
        if (!level) return null;
        return <RiskLevelWithCount key={level} level={level} count={count} value={value} />;
      })}
    </Stack>
  );
};

export default RiskAssessment;
