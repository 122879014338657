import { Box, CircularProgress, IconButton, Link, Skeleton, Stack, Tooltip, useMediaQuery } from '@mui/material';
import { format } from 'date-fns';
import { useGetPeriod } from '../../../shared/hooks/useGetPeriod';
import WidgetBox from '../../../shared/UI/WidgetBox';
import { styled, useTheme } from '@mui/material/styles';
import ReportTitle from '../Settings/ReportTitle';
import SumAsBigNumber from './ChartTypes/SumAsBigNumber';
import GaugeChart from './ChartTypes/GaugeChart';
import DefaultChart from './ChartTypes/DefaultChart';
import { useTranslations } from 'shared/hooks/useTranslations';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { useState } from 'react';
import CustomChartModal from './CustomChartModal';
import { useGetCustomChartByLinkId } from 'shared/startpageService/charts';
import { ContentLevel, DetailType } from 'shared/types/enums';
import AggregatedChart from './ChartTypes/AggregatedChart';
import { StandardReportTitle } from 'shared/startpageService/charts/enums';
import RiskReduction from './ChartTypes/RiskReduction';
import OpenRisks from './ChartTypes/OpenRisks';
import DaysSinceLastAccident from './ChartTypes/DaysSinceLastAccident';
import { useGetDaysSinceLastAccident } from 'shared/startpageService/statistics';

const ChartsWrapper = styled('div')`
  position: relative;
  height: 220px;
`;
const AbsoluteProgress = styled(CircularProgress)`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 200;
`;

interface Props {
  linkId: number;
  level: ContentLevel;
}
const CustomChart = ({ linkId, level }: Props) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [open, setOpen] = useState(false);
  const { t } = useTranslations();
  const { startDate, endDate } = useGetPeriod();
  const { link, reportTitle, distribution, reportSettings, settings, serie, isLoading, isFetching } =
    useGetCustomChartByLinkId(linkId);
  const { data: listData } = useGetDaysSinceLastAccident(level);

  const overrideLevelString = level !== ContentLevel.Organization ? `&Level=${level}` : '';

  const showRiskReductionAggregate = reportTitle === StandardReportTitle.RiskReduction;
  const showGauge =
    !showRiskReductionAggregate &&
    (distribution?.showPercentage || settings?.details?.load[0] === DetailType.RISK_REDUCTION_PERCENTAGE);

  const showAggregate = reportTitle === StandardReportTitle.ActionsCompleted;
  const showOpenRisks = reportTitle === StandardReportTitle.OpenRisks;
  const showDaysSinceLastAccident = reportTitle === StandardReportTitle.DaysSinceLastAccident;
  let startDateForReport = startDate.toString();
  if (showDaysSinceLastAccident && listData && listData.length > 0) {
    startDateForReport = listData[0].timeOccurred;
  }
  const showBigNumber = distribution?.showSumAsBigNumber && !showAggregate && !showRiskReductionAggregate;

  const showDefault =
    !showBigNumber &&
    !showOpenRisks &&
    !showDaysSinceLastAccident &&
    !showGauge &&
    !showAggregate &&
    !showRiskReductionAggregate;
  const showGoldStar = reportTitle === StandardReportTitle.OccurrencesCompleted;
  const showZoomInIcon = (showDefault || showGauge) && isDesktop;
  const reportTitleTranslated = reportTitle.startsWith('___') ? t(`ReportTitle_${reportTitle}`) : reportTitle;

  return (
    <Tooltip title={t('Startpage_ClickToOpenReport')}>
      <Box>
        <WidgetBox>
          <ChartsWrapper data-testid="custom-chart">
            {isLoading && <Skeleton />}
            {!isLoading && reportSettings && (
              <>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <ReportTitle title={reportTitle} bold={true} />
                  {showZoomInIcon && (
                    <Tooltip title="Förstora">
                      <IconButton
                        aria-label="zooma in"
                        onClick={() => {
                          setOpen(true);
                        }}
                        sx={{ padding: '0', mt: -1, mr: -1, color: '#999' }}
                      >
                        <ZoomInIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
                <Link
                  href={`${link?.Link}&PeriodStart=${format(
                    new Date(startDateForReport),
                    'yyyy-MM-dd'
                  )}&PeriodEnd=${format(new Date(endDate), 'yyyy-MM-dd')}${overrideLevelString}`}
                  target="_blank"
                  underline="none"
                  sx={{ width: '100%', color: 'black' }}
                >
                  {isFetching && <AbsoluteProgress size={12} color="inherit" />}
                  {showBigNumber && (
                    <SumAsBigNumber
                      settings={settings}
                      distribution={distribution}
                      serie={serie}
                      level={level}
                      showGoldStar={showGoldStar}
                    />
                  )}
                  {showGauge && distribution && (
                    <>
                      <GaugeChart
                        settings={settings}
                        distribution={distribution}
                        serieDetailType={serie}
                        level={level}
                      />
                      <CustomChartModal
                        reportTitle={reportTitle}
                        open={open}
                        onClose={() => {
                          setOpen(false);
                        }}
                      >
                        <GaugeChart
                          settings={settings}
                          distribution={distribution}
                          serieDetailType={serie}
                          level={level}
                        />
                      </CustomChartModal>
                    </>
                  )}
                  {showAggregate && <AggregatedChart reportSettings={reportSettings} level={level} />}
                  {showDaysSinceLastAccident && <DaysSinceLastAccident reportSettings={reportSettings} level={level} />}
                  {showOpenRisks && <OpenRisks reportSettings={reportSettings} level={level} />}
                  {showRiskReductionAggregate && <RiskReduction reportSettings={reportSettings} level={level} />}
                  {showDefault && distribution && (
                    <>
                      <DefaultChart
                        settings={settings}
                        distribution={distribution}
                        serie={serie}
                        level={level}
                        title={reportTitleTranslated}
                      />
                      <CustomChartModal
                        reportTitle={reportTitleTranslated}
                        open={open}
                        onClose={() => {
                          setOpen(false);
                        }}
                      >
                        <DefaultChart
                          settings={settings}
                          distribution={distribution}
                          serie={serie}
                          level={level}
                          title={reportTitleTranslated}
                        />
                      </CustomChartModal>
                    </>
                  )}
                </Link>
              </>
            )}
          </ChartsWrapper>
        </WidgetBox>
      </Box>
    </Tooltip>
  );
};

export default CustomChart;
