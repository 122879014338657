export type Palette = Record<number, string>;

export const colorPaletteIAdefault: Palette = {
  999999: '#ddd',
  0: '#999',
  999: '#601a24',
  1: '#ff5050',
  2: '#c49d76',
  3: '#a9a2d1',
  4: '#ff9e71',
  5: '#73d395',
  6: '#2f7d5e',
  7: '#efca65',
  8: '#464646',
  9: '#21578a',
  10: '#6697cc',
  11: '#3baba1',
  12: '#5c7f92',
  13: '#aaaaaa',
  14: '#84cac6',
  15: '#604a7b',
  16: '#006871',
  17: '#c3d7d6',
  18: '#a0be71',
  19: '#E8B09E',
  20: '#D98075',
  21: '#DB5438',
  22: '#BA6547',
  23: '#995B47',
  24: '#6b9987',
  25: '#DB544A',
  26: '#6cbdd6',
  27: '#bb3346',
  28: '#ff7d7d',
  29: '#601a24',
  30: '#ef8214',
  31: '#a68fa4',
  32: '#32a852',
  33: '#ff9e71',
  34: '#bd6e89',
  35: '#73605a',
  36: '#3b2937',
  37: '#6f7a3c',
  38: '#f27d2f',
};

export const colorPaletteIAmix: Palette = {
  999999: '#ddd',
  0: '#999',
  1: '#601a24',
  11: '#ff5050',
  12: '#c49d76',
  13: '#a9a2d1',
  14: '#efca65',
  15: '#2f7d5e',
  16: '#73d395',
  17: '#a0be71',
  18: '#ff9e71',
  19: '#21578a',
  20: '#464646',
  21: '#6697cc',
  22: '#5c7f92',
  23: '#aaaaaa',
  24: '#84cac6',
  25: '#604a7b',
  26: '#006871',
  27: '#c3d7d6',
  28: '#6f7a3c',
  29: '#E8B09E',
  30: '#D98075',
  31: '#DB5438',
  32: '#3baba1',
  33: '#995B47',
  34: '#6b9987',
  35: '#DB544A',
  36: '#ff9e71',
  37: '#bb3346',
  38: '#6cbdd6',
  10: '#201a24',
  9: '#ef8214',
  2: '#ff7d7d',
  3: '#3b2937',
  4: '#bd6e89',
  5: '#BA6547',
  6: '#32a852',
  7: '#73605a',
  8: '#f27d2f',
};

export const colorPaletteHighchartDefault: Palette = {
  999999: '#ddd',
  0: '#999',
  1: '#7cb5ec',
  2: '#f7a35c',
  3: '#e4d354',
  4: '#434348',
  5: '#8085e9',
  6: '#f15c80',
  7: '#90ed7d',
  8: '#2b908f',
  9: '#f45b5b',
  10: '#6697cc',
  11: '#91e8e1',
  12: 'RGB(92,127,146)',
  13: 'RGB(170,170,170)',
  14: 'RGB(132,202,198)',
  15: 'RGB(96,74,123)',
  16: 'RGB(0,104,113)',
  17: 'RGB(195,215,214)',
  18: '#a0be71',
  19: '#601a24',
};

export enum ColorSets {
  Default = 1,
  Mix = 2,
  HighchartDefault = 3,
}

export const chartColorPalettes: Record<ColorSets, Palette> = {
  [ColorSets.Default]: colorPaletteIAdefault,
  [ColorSets.Mix]: colorPaletteIAmix,
  [ColorSets.HighchartDefault]: colorPaletteHighchartDefault,
};

export const RISK = {
  ELIMINATED: 0,
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3,
};

export const RISK_LEVEL = [
  {
    level: 0,
    title: 'Eliminated',
    class: 'riskE',
    color: '#296b05',
  },
  {
    level: 1,
    title: 'Low',
    class: 'riskL',
    color: '#74c28b',
  },
  {
    level: 2,
    title: 'Medium',
    class: 'riskM',
    color: '#fae87e',
  },
  {
    level: 3,
    title: 'High',
    class: 'riskH',
    color: '#d9120d',
  },
];

export const OK = [
  {
    id: 0,
    title: 'No',
    class: 'okNo',
    color: '#fc0',
  },
  {
    id: 1,
    title: 'Yes',
    class: 'okYes',
    color: '#4ad',
  },
];
