import { Button, Dialog, Paper, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import CloseIcon from '@mui/icons-material/Close';

interface CustomChartModalProps {
  reportTitle: string;
  open: boolean;
  onClose: () => void;
}

const CustomChartModal = ({ reportTitle, open, onClose, children }: PropsWithChildren<CustomChartModalProps>) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Paper sx={{ padding: '1rem' }}>
        <Typography variant="subtitle1" textAlign={'center'}>
          {reportTitle}
        </Typography>
        <Button color="inherit" onClick={onClose} sx={{ position: 'absolute', right: 0, top: 0, padding: '1rem' }}>
          <CloseIcon />
        </Button>
        {children}
      </Paper>
    </Dialog>
  );
};

export default CustomChartModal;
