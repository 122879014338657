import { format } from 'date-fns';
import { useGetConfig } from 'shared/startpageService/settings';
import { Period } from 'shared/types/types';
import { useTranslations } from './useTranslations';

export const useGetPeriodLabel = () => {
  const { data: config } = useGetConfig();
  const { tFormat } = useTranslations();
  const period: Period = config?.period ?? 365;

  if (!period) return '';
  return period === 'during'
    ? `${tFormat('Period_DuringPeriod', [format(new Date(), 'yyyy')])}`
    : `${tFormat('Period_LastDays', [period.toString()])}`;
};
