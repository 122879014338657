import { Box, CircularProgress, IconButton, Link, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import WidgetBox from '../../../shared/UI/WidgetBox';
import { styled } from '@mui/material/styles';
import ReportTitle from '../Settings/ReportTitle';
import SumAsBigNumber from './ChartTypes/SumAsBigNumber';
import GaugeChart from './ChartTypes/GaugeChart';
import DefaultChart from './ChartTypes/DefaultChart';
import { useTranslations } from 'shared/hooks/useTranslations';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { useState } from 'react';
import CustomChartModal from './CustomChartModal';
import { ContentLevel, DetailType } from 'shared/types/enums';
import AggregatedChart from './ChartTypes/AggregatedChart';
import { StandardReportTitle } from 'shared/startpageService/charts/enums';
import RiskReduction from './ChartTypes/RiskReduction';
import OpenRisks from './ChartTypes/OpenRisks';
import DaysSinceLastAccident from './ChartTypes/DaysSinceLastAccident';
import { useUserSettings } from '../Settings/useUserSettings';
import HeightIcon from '@mui/icons-material/Height';
import CloseIcon from '@mui/icons-material/Close';
import { useGetCustomChartByLinkId } from '../backendQueries/useGetData';
import PeriodSelect from './PeriodSelect';
import LevelSelect from './LevelSelect';
import { LevelContentItem } from '../backendQueries/useGetLevelContent';
import { PeriodContentItem } from '../backendQueries/useGetPeriodContent';
import { ILevelViewModel, IPeriodViewModel } from 'shared/startpageService/common/types';
import { PeriodType, PeriodUnit } from 'shared/startpageService/common/enums';
import { format } from 'date-fns';

const ChartsWrapper = styled('div')`
  position: relative;
  height: 220px;
`;
const AbsoluteProgress = styled(CircularProgress)`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 200;
`;

export const WidthIcon = styled(HeightIcon)`
  position: absolute;
  top: 5;
  right: 0;
  z-index: 200;
  transform: rotate(90deg);
  color: #bbb;
  font-size: 20px;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 5;
  right: 2;
  z-index: 200;
  color: #bbb;
  font-size: 18px;
`;

export const StyledZoomInIcon = styled(ZoomInIcon)`
  position: absolute;
  top: 5;
  right: 2;
  z-index: 200;
  color: #bbb;
  font-size: 18px;
`;

interface Props {
  linkId: number;
  forTopSection: boolean;
  level?: LevelContentItem;
  period?: PeriodContentItem;
}
// eslint-disable-next-line sonarjs/cognitive-complexity
const CustomChart = ({ linkId, forTopSection, level, period }: Props) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslations();
  const { link, reportTitle, distribution, reportSettings, settings, serie, isLoading, isFetching } =
    useGetCustomChartByLinkId(linkId);
  const { toggleWidgetWidth, toggleWidget } = useUserSettings();

  const showRiskReductionAggregate = reportTitle === StandardReportTitle.RiskReduction;
  const showGauge =
    !showRiskReductionAggregate &&
    (distribution?.showPercentage || settings?.details?.load[0] === DetailType.RISK_REDUCTION_PERCENTAGE);

  const aggregatedCharts = [
    StandardReportTitle.ActionsCompleted.toString(),
    StandardReportTitle.ActionsOngoing.toString(),
  ];
  const showAggregate = aggregatedCharts.includes(reportTitle);
  const showOpenRisks = reportTitle === StandardReportTitle.OpenRisks;
  const showDaysSinceLastAccident = reportTitle === StandardReportTitle.DaysSinceLastAccident;
  const showBigNumber = distribution?.showSumAsBigNumber && !showAggregate && !showRiskReductionAggregate;

  const showDefault =
    !showBigNumber &&
    !showOpenRisks &&
    !showDaysSinceLastAccident &&
    !showGauge &&
    !showAggregate &&
    !showRiskReductionAggregate;
  const showGoldStar = reportTitle === StandardReportTitle.OccurrencesCompleted;
  const showZoomInIcon = forTopSection; //  ? (showDefault || showGauge) && isDesktop : false;
  const reportTitleTranslated = reportTitle.startsWith('___') ? t(`ReportTitle_${reportTitle}`) : reportTitle;
  const levelName = settings?.filter.base.level?.name;
  const periodName = `${settings?.filter.base.period?.quantity} ${
    settings?.filter.base.period?.unit === PeriodUnit.Day ? 'dagar' : 'månader'
  }`;
  let overrideLevelString = '';
  let overridePeriodString = '';

  if (settings) {
    if (level) {
      const newLevel: ILevelViewModel = {
        type: level.type,
        id: level.id,
        orgId: level.orgId,
        unitTypes: settings?.filter.base.level?.unitTypes,
        includeSubunits: settings?.filter.base.level?.includeSubunits,
        name: level.name,
        orgName: level.orgName,
      };
      overrideLevelString = `&Level=${level.type}&LevelId=${level.id}&LevelName=${level.name}`;
      overrideLevelString = level.orgId
        ? overrideLevelString + `&LevelOrgId=${level.orgId}&LevelOrgName=${level.orgName}`
        : overrideLevelString;
      settings.filter.base.level = newLevel;
    } else {
      const newLevel: LevelContentItem = {
        typeLabel: '',
        type: settings.filter.base.level?.type ?? ContentLevel.Organization,
        id: settings.filter.base.level?.id ?? 0,
        orgId: settings.filter.base.level?.orgId ?? 0,
        name: settings.filter.base.level?.name,
        orgName: settings.filter.base.level?.orgName,
      };
      level = newLevel;
    }

    if (period) {
      const newPeriod: IPeriodViewModel = {
        unit: period.unit,
        quantity: period.quantity,
        offsetBackwards: settings.filter.base.period?.offsetBackwards,
        type: settings.filter.base.period?.type ?? PeriodType.Occurred,
        startDate: format(new Date(), 'yyyy-MM-dd'),
        endDate: format(new Date(), 'yyyy-MM-dd'),
        customMode: false,
        fixedMode: false,
        reportCulture: settings.filter.base.period?.reportCulture ?? 'sv-SE',
      };
      overridePeriodString = `&PeriodUnit=${period.unit}&PeriodQuantity=${period.quantity}`;
      settings.filter.base.period = newPeriod;
    }
  }
  return (
    <Box>
      <WidgetBox>
        <ChartsWrapper data-testid="custom-chart">
          {isLoading && <Skeleton />}
          {!isLoading && reportSettings && (
            <>
              <Stack direction={'row'} justifyContent="space-between" mt="-0.8rem" mr="-0.5rem">
                {forTopSection && (
                  <Stack direction="row" spacing={1}>
                    <Typography variant="body2" sx={{ fontStyle: 'italic', color: '#999', fontSize: 13 }}>
                      {levelName}
                    </Typography>
                    <Typography variant="body2" sx={{ fontStyle: 'italic', color: '#999', fontSize: 13 }}>
                      {periodName}
                    </Typography>
                  </Stack>
                )}
                {!forTopSection && (
                  <Stack direction="row" spacing={2}>
                    <LevelSelect linkId={linkId} level={level} />
                    <PeriodSelect linkId={linkId} period={period} />
                  </Stack>
                )}
                {showZoomInIcon && (
                  <Tooltip title="Förstora">
                    <IconButton
                      aria-label="zooma in"
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      <StyledZoomInIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {!showZoomInIcon && !forTopSection && (
                  <Stack direction="row" spacing={1} /* justifyContent="end" mt="-0.8rem" mr="-0.5rem" */>
                    <Tooltip title="Gör bredare">
                      <IconButton onClick={() => toggleWidgetWidth(linkId)}>
                        <WidthIcon />
                      </IconButton>
                    </Tooltip>
                    <IconButton onClick={() => toggleWidget(linkId)}>
                      <StyledCloseIcon />
                    </IconButton>
                  </Stack>
                )}
              </Stack>
              <Stack mt="-1.5" spacing={1} mb={1}>
                <Tooltip
                  title={
                    <>
                      <Typography>{settings?.details?.comment}</Typography>
                      <Typography variant="body2" sx={{ fontStyle: 'italic', mt: 1 }}>
                        {t('Startpage_ClickToOpenReport')}
                      </Typography>
                    </>
                  }
                >
                  <Stack sx={{ cursor: 'pointer' }}>
                    <ReportTitle title={reportTitle} variant="h3" />
                  </Stack>
                </Tooltip>
              </Stack>
              <Link
                href={`${link?.Link}${overrideLevelString}${overridePeriodString}`}
                target="_blank"
                underline="none"
                sx={{ width: '100%', color: 'black' }}
              >
                {isFetching && <AbsoluteProgress size={12} color="inherit" />}
                {showBigNumber && (
                  <SumAsBigNumber
                    settings={settings}
                    distribution={distribution}
                    serie={serie}
                    showGoldStar={showGoldStar}
                  />
                )}
                {showGauge && distribution && (
                  <>
                    <GaugeChart settings={settings} distribution={distribution} serieDetailType={serie} />
                    <CustomChartModal
                      reportTitle={reportTitle}
                      open={open}
                      onClose={() => {
                        setOpen(false);
                      }}
                    >
                      <GaugeChart settings={settings} distribution={distribution} serieDetailType={serie} />
                    </CustomChartModal>
                  </>
                )}
                {showAggregate && <AggregatedChart reportSettings={reportSettings} />}
                {showDaysSinceLastAccident && <DaysSinceLastAccident reportSettings={reportSettings} />}
                {showOpenRisks && <OpenRisks reportSettings={reportSettings} />}
                {showRiskReductionAggregate && <RiskReduction reportSettings={reportSettings} />}
                {showDefault && distribution && (
                  <>
                    <DefaultChart
                      settings={settings}
                      distribution={distribution}
                      serie={serie}
                      title={reportTitleTranslated}
                    />
                    <CustomChartModal
                      reportTitle={reportTitleTranslated}
                      open={open}
                      onClose={() => {
                        setOpen(false);
                      }}
                    >
                      <DefaultChart
                        settings={settings}
                        distribution={distribution}
                        serie={serie}
                        title={reportTitleTranslated}
                      />
                    </CustomChartModal>
                  </>
                )}
              </Link>
              {/* </Stack> */}
            </>
          )}
        </ChartsWrapper>
      </WidgetBox>
    </Box>
  );
};

export default CustomChart;
