import { Theme, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import { functionTitles } from 'sections/startpage/CustomCharts/resources';
import InitialsAvatar from './InitialsAvatar';
import { PropsWithChildren, useState } from 'react';
import { useTranslations } from 'shared/hooks/useTranslations';
import RiskAssessment from './RiskAssessment';
import { Activity, State } from 'shared/types/enums';
import { IActivityItem } from 'shared/types/types';
import { filterOnlyMine } from '../utils';
import DateWithIcon from './DateWithIcon';

const defaultActivityLabels = {
  [Activity.REPORTING]: `Startpage_Reported`,
  [Activity.INVESTIGATION]: `Selections_DetailGroup_Investigation`,
  [Activity.ACTION_HANDLING]: `Selections_DetailGroup_Action`,
  [Activity.ACTION]: `Report_Action`,
  [Activity.FOLLOW_UP]: `Selections_DetailGroup_FollowUp`,
  [Activity.FOLLOW_UP_OTHER]: `Startpage_CompletedNotApproved`,
  [Activity.PLANNING]: `Selections_StatusPlanned`,
  [Activity.IMPLEMENT]: `RiskManagement_Performance`,
  [Activity.COMPLETE]: `Selections_StatusCompleted`,
};

const getIndicatorColor = (theme: Theme, state: State) => {
  if (state === State.DONE) return theme.palette.common.black;
  if (state === State.CURRENT) return theme.palette.primary.main;
  return theme.palette.grey[100];
};
interface StatusCircleProps {
  state: State;
}
const StatusCircle = styled('div')<StatusCircleProps>`
  width: 1.5rem;
  height: 1.5rem;
  background-color: ${({ theme, state }) => getIndicatorColor(theme, state)};
  border-radius: 50%;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:after {
    content: '';
    border: 2px solid
      ${({ theme, state }) => ([State.CURRENT, State.NOT_DONE].includes(state) ? theme.palette.grey[100] : '#000')};
    position: absolute;
    left: calc(50% - 2px);
    top: 100%;
    height: 1000px;
  }
`;

const StyledActivityModule = styled('div')`
  display: flex;
  align-items: flex-start;
  padding-bottom: 1rem;
  overflow: hidden;
  &:last-child {
    .status-circle:after {
      content: none;
    }
  }
`;

const ActorsRow = styled('div')`
  display: flex;
  column-gap: 0.5rem;
`;

const ActivityInfo = styled(Box)`
  background-color: #eee;
  padding: 0.5rem;
  border-radius: 3px;
`;

const ActivityHeader = styled('div')`
  display: flex;
  justify-content: space-between;
`;
const SubActivities = styled('div')`
  margin-top: 1rem;
`;

const StyledTitleInfoRow = styled('div')`
  display: flex;
  column-gap: 0.5rem;
  margin-bottom: 0.25rem;
`;

const StyledText = styled(Typography)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

interface TitleInfoProps {
  title: string;
}
const TitleInfoRow = ({ title, children }: PropsWithChildren<TitleInfoProps>) => {
  return (
    <StyledTitleInfoRow>
      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
        {title}:
      </Typography>
      {children}
    </StyledTitleInfoRow>
  );
};

interface Props {
  activityItem: IActivityItem;
  onlyMine: boolean;
}
const ActivityModule = ({ activityItem, onlyMine }: Props) => {
  const { t } = useTranslations();
  const [showText, setShowText] = useState(false);
  const {
    state,
    activity,
    actors,
    title,
    text,
    date,
    isOverdue,
    subActivites,
    riskAssessment,
    completedCount,
    totalCount,
    object,
    template,
    rowsYesCount,
    rowsNoCount,
    isPerformed,
  } = activityItem;
  const toggleShowText = () => {
    setShowText(!showText);
  };

  const hasInfoContent = () => {
    if (onlyMine && !actors?.some((actor) => actor.isMe)) {
      return false;
    }
    return [
      actors && actors.length > 0,
      title,
      text,
      riskAssessment && (riskAssessment.riskAssessments?.length || 0) > 1,
      object,
      template,
      isPerformed,
      rowsNoCount,
      rowsYesCount,
    ].some((item) => Boolean(item));
  };

  return (
    <StyledActivityModule>
      {activity === Activity.ACTION ? (
        <CheckIcon sx={{ color: state === State.DONE ? 'black' : 'white', fontSize: 20 }} />
      ) : (
        <StatusCircle state={state} className="status-circle">
          {state === State.DONE && <CheckIcon sx={{ color: 'common.white', fontSize: 16 }} />}
        </StatusCircle>
      )}
      <Stack flexGrow={1}>
        <ActivityHeader>
          <Typography variant="body2" sx={{ color: state === State.NOT_DONE ? 'rgba(0,0,0,0.5)' : 'default' }}>
            {t(defaultActivityLabels[activity])}
          </Typography>
          <DateWithIcon date={date} isOverdue={isOverdue} />
          {Boolean(completedCount !== undefined && totalCount !== undefined) && (
            <Typography variant="body2">
              {completedCount}/{totalCount}
            </Typography>
          )}
        </ActivityHeader>
        {hasInfoContent() && (
          <ActivityInfo>
            {isPerformed !== undefined && (
              <TitleInfoRow title={t('RiskManagement_Completed')}>
                <Typography variant="body2">
                  {isPerformed ? t('Selections_Detail_Yes') : t('Selections_Detail_No')}
                </Typography>
              </TitleInfoRow>
            )}
            {actors?.map((actor, idx) => (
              <ActorsRow key={idx}>
                <TitleInfoRow title={t(functionTitles[actor.function])}>
                  <Typography variant="body2">{actor.name}</Typography>
                  {actor.isMe && <InitialsAvatar />}
                </TitleInfoRow>
              </ActorsRow>
            ))}
            {riskAssessment?.riskAssessments && riskAssessment?.riskAssessments.some((a) => a.level) && (
              <RiskAssessment
                riskAssessment={riskAssessment}
                title={
                  riskAssessment.isAfter
                    ? t('Selections_Detail_RiskAfterAction')
                    : t('Selections_Detail_RiskBeforeAction')
                }
              />
            )}
            {object && (
              <TitleInfoRow title={t('Selections_Detail_Object')}>
                <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                  {object}
                </Typography>
              </TitleInfoRow>
            )}
            {template && (
              <TitleInfoRow title={t('Selections_Detail_Template')}>
                <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                  {template}
                </Typography>
              </TitleInfoRow>
            )}
            <Stack direction="row" spacing={1}>
              {rowsYesCount && (
                <Typography variant="body2">
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }} display="inline" component="span">
                    {t('Selections_Detail_Yes')}
                  </Typography>{' '}
                  {rowsYesCount}
                </Typography>
              )}
              {rowsNoCount && (
                <Typography variant="body2">
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }} display="inline" component="span">
                    {t('Selections_Detail_No')}
                  </Typography>{' '}
                  {rowsNoCount}
                </Typography>
              )}
            </Stack>
            {title && (
              <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                {title}
              </Typography>
            )}
            {text && !showText && (
              <StyledText onClick={toggleShowText} variant="body2" sx={{ color: '#999', cursor: 'pointer' }}>
                {text}
              </StyledText>
            )}
            {text && showText && (
              <Typography onClick={toggleShowText} variant="body2" sx={{ color: '#999', cursor: 'pointer' }}>
                {text}
              </Typography>
            )}
          </ActivityInfo>
        )}
        <SubActivities>
          {subActivites?.filter(filterOnlyMine(onlyMine)).map((subActivity) => (
            <ActivityModule key={subActivity.id} activityItem={subActivity} onlyMine={onlyMine} />
          ))}
        </SubActivities>
      </Stack>
    </StyledActivityModule>
  );
};

export default ActivityModule;
