import { MyReportsQuerySource, RequestTypes } from './requestTypes';

export const dataTypeQuerySource: Record<RequestTypes, MyReportsQuerySource> = {
  [RequestTypes.OccurrenceList]: MyReportsQuerySource.List,
  [RequestTypes.RiskManagementList]: MyReportsQuerySource.List,
  [RequestTypes.OpenRisksOccurrence]: MyReportsQuerySource.Details,
  [RequestTypes.OpenRisksRiskManagement]: MyReportsQuerySource.Details,
  [RequestTypes.OngoingOccurrences]: MyReportsQuerySource.TotalItemCount,
  [RequestTypes.RiskmanagementsOngoing]: MyReportsQuerySource.TotalItemCount,
  [RequestTypes.OpenActionsOccurrence]: MyReportsQuerySource.Details,
  [RequestTypes.OpenActionsRiskManagement]: MyReportsQuerySource.Details,
  [RequestTypes.CompletedOccurrences]: MyReportsQuerySource.TotalItemCount,
  [RequestTypes.RiskmanagementsCompleted]: MyReportsQuerySource.TotalItemCount,
  [RequestTypes.CompletedActionsOccurrence]: MyReportsQuerySource.Details,
  [RequestTypes.CompletedActionsRiskManagement]: MyReportsQuerySource.Details,
  [RequestTypes.ReportedOccurrences]: MyReportsQuerySource.Details,
  [RequestTypes.PerformedRiskManagements]: MyReportsQuerySource.Details,
  [RequestTypes.DaysSinceLastAccident]: MyReportsQuerySource.List,
  [RequestTypes.RiskReductionOccurrences]: MyReportsQuerySource.Details,
  [RequestTypes.RiskReductionRiskManagement]: MyReportsQuerySource.Details,
  [RequestTypes.AccidentsWithAbsence]: MyReportsQuerySource.TotalItemCount,
  [RequestTypes.AccidentsWithAbsenceLTI]: MyReportsQuerySource.TotalItemCount,
  [RequestTypes.CustomChart]: MyReportsQuerySource.Details,
  [RequestTypes.CustomChartList]: MyReportsQuerySource.List,
  [RequestTypes.CustomTotalCount]: MyReportsQuerySource.TotalItemCount,
};
