import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { PropsWithChildren } from 'react';
import { Box, CardContent, SxProps, styled } from '@mui/material';

const WidgetBox = styled(Box)`
  & .MuiPaper-root {
    height: 250px;
    border-radius: 10px;
  }
`;

interface Props {
  title?: string;
  subheader?: string | React.ReactElement;
  sx?: SxProps;
}

export default ({ title, subheader, children, sx }: PropsWithChildren<Props>) => {
  return (
    <WidgetBox sx={sx}>
      <Card>
        {title && <CardHeader title={title} subheader={subheader} />}
        <CardContent>{children}</CardContent>
      </Card>
    </WidgetBox>
  );
};
