import { Button, Divider, Drawer, Stack, styled, Switch, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import ReportTitleTranslated from './ReportTitle';
import { LoggingErrorBoundary } from 'shared/error/LoggingErrorBoundary';
import MakeChartInfo from './MakeChartInfo';
import { useUserSettings } from './useUserSettings';
import { useGetLinkContentDataFromserver } from '../backendQueries/useGetData';

const Container = styled('div')`
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  max-width: 400px;
`;

const DrawerHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
`;

const SettingsWidget = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledSwitchRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.3rem;
`;

const UserSettings = () => {
  const { allContentUser, defaultContentUser } = useGetLinkContentDataFromserver();
  const { showTodoList, toggleShowTodoList, toggleWidget, activeWidgets } = useUserSettings();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const onClose = () => {
    setDrawerOpen(false);
  };
  const onClick = () => {
    setDrawerOpen(true);
  };

  if (activeWidgets?.length === 0) {
    for (const item of defaultContentUser) {
      toggleWidget(item.Id);
    }
  }

  const isActive = (id: number) => {
    return activeWidgets?.map((a) => a.linkId).includes(id);
  };

  const order = (id: number) => {
    const index = activeWidgets?.findIndex((a) => a.linkId === id);
    return index !== -1 ? Number(index) + 1 : '';
  };

  return (
    <LoggingErrorBoundary>
      <SettingsWidget>
        <Button variant="outlined" color="primary" onClick={onClick} sx={{ background: '#fff' }}>
          <SettingsIcon sx={{ mr: 1 }} />
          Anpassa min startsida{/* {t('Startpage_Customize')} */}
        </Button>
      </SettingsWidget>
      <Drawer anchor="right" open={drawerOpen} onClose={onClose}>
        <Container>
          <DrawerHeader>
            <Typography variant="h5">Anpassa min startsida{/* {t('Startpage_Customize')} */}</Typography>
            <Button onClick={onClose}>
              <CloseIcon />
            </Button>
          </DrawerHeader>
          <MakeChartInfo />
          {/* <PeriodSelect /> */}
          <Typography variant="subtitle2" mt={3}>
            Modul{/* {t('Startpage_MyInformation')} */}
          </Typography>
          <Divider />
          <StyledSwitchRow>
            <ReportTitleTranslated title={'Mina uppgifter'} />
            <Switch checked={showTodoList} onChange={() => toggleShowTodoList()} />
          </StyledSwitchRow>
          {allContentUser.map(({ Id, Text }) => (
            <React.Fragment key={Id}>
              <StyledSwitchRow>
                <ReportTitleTranslated title={Text} />
                <Stack direction="row">
                  <Typography sx={{ mt: 1 }}>{order(Id)}</Typography>
                  <Switch checked={isActive(Id)} onChange={() => toggleWidget(Id)} />
                </Stack>
              </StyledSwitchRow>
            </React.Fragment>
          ))}
        </Container>
      </Drawer>
    </LoggingErrorBoundary>
  );
};
export default UserSettings;
