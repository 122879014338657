import styled from '@emotion/styled';
import { Stack, Typography } from '@mui/material';
import { useGetOpenRisksData } from 'sections/newmyia/backendQueries/openRisks';
import { useGetRiskColor } from 'shared/hooks/useGetRiskColor';
import { IReportSettings } from 'shared/startpageService/common/types';
import { RiskLevel, ContentLevel } from 'shared/types/enums';
import { getRequestForSection } from '../customRequest';

const OpenRiskIndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
`;

const Indicator = styled.span`
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  margin-right: 0.25rem;
  background-color: ${({ color }) => color};
`;

const ChartContainer = styled.div`
  width: 7rem;
  margin-left: 0.75rem;
`;

interface Props {
  reportSettings: IReportSettings;
}

const OpenRisks = ({ reportSettings }: Props) => {
  const getRiskColor = useGetRiskColor();
  const colors = [getRiskColor(RiskLevel.HIGH), getRiskColor(RiskLevel.MEDIUM), getRiskColor(RiskLevel.LOW)];

  const request1 = getRequestForSection(reportSettings?.sections[0]);
  const request2 = getRequestForSection(reportSettings?.sections[1]);

  const { data } = useGetOpenRisksData(request1, request2);

  const getPercent = (n: number) => {
    return Math.round((n / data.totalCount) * 100);
  };

  const openRisksArray = [data.highCount, data.mediumCount, data.lowCount];

  return (
    <Stack direction="column" alignItems="center" sx={{ mt: 2 }}>
      {data.totalCount > 0 && (
        <ChartContainer>
          <svg viewBox="0 0 50 50">
            {openRisksArray.map((count, index) => {
              const percentage = getPercent(count);
              const remainder = 100 - percentage;
              const average = openRisksArray.slice(0, index).reduce<number>((acc, curr) => acc + getPercent(curr), 0);
              return (
                <circle
                  key={index}
                  cx="20"
                  cy="20"
                  r="15.91549430918954"
                  strokeDasharray={`${percentage} ${remainder}`}
                  strokeDashoffset={100 - average + 25}
                  strokeWidth="9"
                  fill="transparent"
                  stroke={colors[index]}
                />
              );
            })}
          </svg>
        </ChartContainer>
      )}
      <Stack direction="row">
        <Typography
          sx={{ fontSize: 30, fontWeight: 700, mt: -1, mr: 1, color: 'black' }}
          display="inline"
          component="span"
        >
          {data.totalCount}
        </Typography>
      </Stack>
      <Stack direction="row">
        <OpenRiskIndicatorWrapper>
          <Indicator color={colors[0]} />
          <Typography sx={{ fontSize: 18, fontWeight: 500, color: 'black' }}>{data.highCount}</Typography>
        </OpenRiskIndicatorWrapper>
        <OpenRiskIndicatorWrapper>
          <Indicator color={colors[1]} />
          <Typography sx={{ fontSize: 18, fontWeight: 500, color: 'black' }}>{data.mediumCount}</Typography>
        </OpenRiskIndicatorWrapper>
        <OpenRiskIndicatorWrapper>
          <Indicator color={colors[2]} />
          <Typography sx={{ fontSize: 18, fontWeight: 500, color: 'black' }}>{data.lowCount}</Typography>
        </OpenRiskIndicatorWrapper>
      </Stack>
    </Stack>
  );
};

export default OpenRisks;
