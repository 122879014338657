import { Stack, Typography, Chip, Link, Switch } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { useDatefnsLocale } from '../../../../shared/hooks/useDatefnsLocale';
import { useState } from 'react';
import { useTranslations } from 'shared/hooks/useTranslations';
import ActivityModule from './ActivityModule';
import { useGetDetailView } from 'shared/startpageService';
import { TodoItem } from 'shared/types/types';
import { useGlobalContext } from 'globalContext';
import { useFormatDate } from 'shared/utils/formatDateWithLocale';

export const InfoPanelContent = styled('div')`
  padding: 1.5rem 1rem;
  width: 25vw;
  max-width: 600px;
  @media (max-width: 900px) {
    width: 80vw;
  }
`;

export const InactiveChip = styled(Chip)`
  background-color: rgba(0, 0, 0, 0.08);
`;

export const StyledSwitchRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface Props {
  currentListItem: TodoItem;
  onClose: () => void;
  open: boolean;
}

const DetailView = ({ currentListItem, onClose, open }: Props) => {
  const { companyName } = useGlobalContext();
  const { data: currentItem, isLoading } = useGetDetailView(currentListItem.id, currentListItem.isRiskManagement);
  const [onlyMine, setOnlyMine] = useState<boolean>(false);

  const dateLocale = useDatefnsLocale();
  const { t } = useTranslations();
  const { formatDate } = useFormatDate();

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <InfoPanelContent>
        {isLoading && <Typography>{t('General_Loading')}</Typography>}
        {!isLoading && (
          <>
            <Stack direction="row" spacing={2}>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                <Link href={currentItem?.link} target="_blank">
                  {currentItem?.itemType} {currentItem?.nr}
                </Link>{' '}
                {' | '}
                {formatDate(currentItem?.timeOccurred, dateLocale)}
              </Typography>
            </Stack>
            {currentItem?.organization !== companyName && (
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                {currentItem?.organization}
              </Typography>
            )}
            <Stack direction="row" justifyContent="space-between" alignItems="center" marginTop={1} marginBottom={1}>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                {t('Startpage_MyIA.Table.ColumnHeader3')}
              </Typography>
              <StyledSwitchRow>
                <Typography variant="body1">{t('Startpage_ShowMine')}</Typography>
                <Switch checked={onlyMine} onChange={(_, checked) => setOnlyMine(checked)} />
              </StyledSwitchRow>
            </Stack>
            {currentItem?.activities.map((activity) => (
              <ActivityModule key={activity.id} activityItem={activity} onlyMine={onlyMine} />
            ))}
          </>
        )}
      </InfoPanelContent>
    </Drawer>
  );
};

export default DetailView;
