import { useGlobalContext } from 'globalContext';
import { UseQueryOptions } from '@tanstack/react-query';
import { iafetch } from 'shared/utils';
import { IMyReportsWidget } from 'shared/startpageService/common/types';
import { useGetFilterObject } from './useGetFilterObject';
import { dataTypeQuerySource } from 'shared/startpageService/common/dataTypeQuerySource';
import { RequestTypes, ReturnType } from 'shared/startpageService/common/requestTypes';
import { useQuery } from 'shared/queries/useQueryWithError';

export const useGetMyReportsData = <T extends RequestTypes>(
  type: T,
  widgetSettings?: IMyReportsWidget,
  options?: Partial<UseQueryOptions<ReturnType<T>>>
) => {
  const { baseUrl } = useGlobalContext();
  const request = useGetFilterObject(type, widgetSettings);

  const queryFn = (): Promise<ReturnType<T>> =>
    iafetch(
      `${baseUrl}/PreventionIA/IA/api/Web/Reports/MyReports/${dataTypeQuerySource[type]}?t=${request?.details?.load?.[0]}?${type}`,
      {
        method: 'POST',
        body: JSON.stringify(request),
        headers: new Headers({ 'content-type': 'application/json; charset=UTF-8' }),
      }
    );
  return useQuery<ReturnType<T>>({ queryKey: ['my-report-api', type, request], queryFn, ...options });
};
