import { IMyReportsDetailsResponseItem } from 'shared/types/types';
import { getDetailSeries, getPieColors } from '../chartDataHandling';
import { getPiechartOptions } from '../piechart';
import { IDistribution, IMyReportRequest } from 'shared/startpageService/common/types';

export const getHighchartOptions = (
  distribution: IDistribution,
  item: IMyReportsDetailsResponseItem | undefined,
  settings: IMyReportRequest,
  title: string
): Highcharts.Options => {
  if (distribution?.chartType === 'pie') return getPiechartOptions(distribution, item, settings, title);

  const showSerie = distribution?.chartDistributionType
    ? distribution.chartType !== 'pie' &&
      (distribution.chartDistributionType.includes('serie') ||
        distribution.chartDistributionType.includes('serieStacked'))
    : false;
  const chartType = distribution ? distribution.chartType : 'column';
  const series = getDetailSeries(showSerie, settings, distribution, item);

  return {
    accessibility: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    chart: {
      type: chartType,
      reflow: true,
    },
    title: {
      text: '', // title,
      useHTML: true,
      align: 'left',
      style: {
        fontFamily: 'arial',
        fontSize: '14px',
        color: '#333',
        fontWeight: 'bold',
      },
    },
    subtitle: {
      text: '', // getChartSubtitle(i, type, false, true),
      useHTML: true,
      align: 'left',
      style: {
        fontFamily: 'arial',
        fontSize: '12px',
        color: '#333',
        zIndex: 1,
      },
    },
    xAxis: {
      categories: item ? item.total?.map((a) => a.name) : [],
      title: {
        text: '',
      },
      labels: {
        allowOverlap: true,
      },
    },
    yAxis: {
      allowDecimals: false,
      title: {
        text: '',
      },
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: distribution.showLabelCount ?? false,
          formatter() {
            if (this.y !== 0) {
              return this.y;
            } else {
              return '';
            }
          },
          style: {
            textOutline: '0px',
            color: '#000',
            fontSize: '11px',
            fontWeight: 'normal',
          },
        },
        stacking: distribution.chartDistributionType?.includes('serieStacked') ? 'normal' : undefined,
        showInLegend: distribution.showSeriesInfo,
        // detailSettings.showSeriesInfo() && serieInfoAvailable(i, type),
        cursor: 'pointer',
      },
      pie: {
        colors: getPieColors(settings, item?.total),
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          // formatter: (point) => {
          //   console.log({ point });
          //   // const sliceIndex = this.point.index;
          //   // const sliceName = this.series.chart.axes[0].categories[sliceIndex];
          //   // this.point.name = sliceName;
          //   // return `${sliceName} ${this.y} (${this.percentage.toFixed(0)}%)`;
          //   return 'label';
          // },
          format: `<b>{point.name}</b>:<br>{point.y} ({point.percentage:.0f}%) `,
          padding: 2,
          enabled: true,
          style: {
            fontSize: '11px',
            textOutline: 'false',
            color: '#333',
          },
        },
        // tooltip: {
        //   shared: true,
        //   useHTML: true,
        //   pointFormat: '{point.key} <b>{point.y} ({point.percentage:.0f}%)</b><br/>',
        // },
      },
    },
    series,
  };
};
