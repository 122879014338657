import { useQuery } from 'shared/queries/useQueryWithError';
import { iafetch } from '../../../shared/utils';

interface SystemAlertResponse {
  SystemAlert: SystemAlert;
  BlockedContentList: any[];
}
interface SystemAlert {
  Id: number;
  Message: string;
  Header: string;
}

const fetchSystemAlert = (url: string): Promise<SystemAlertResponse> => iafetch(url);

export const useGetSystemAlert = () => {
  const url = '/PreventionIA/IA/api/Web/Global/SystemStatus';
  return useQuery<SystemAlertResponse>({ queryKey: ['systemAlert'], queryFn: () => fetchSystemAlert(url) });
};
