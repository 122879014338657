import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useTranslations } from 'shared/hooks/useTranslations';
import { useGetDaysSinceLastAccident } from 'shared/startpageService/statistics';
import { ContentLevel } from 'shared/types/enums';
import { IReportSettings } from 'shared/startpageService/common/types';
import { differenceInDays, startOfDay } from 'date-fns';

interface Props {
  reportSettings: IReportSettings;
  level: ContentLevel;
}

const DaysSinceLastAccident = ({ reportSettings, level }: Props) => {
  const { data } = useGetDaysSinceLastAccident(level);
  const { t } = useTranslations();

  let interval: number = -1;

  if (data && data.length > 0) {
    interval = data[0].timeOccurred
      ? differenceInDays(startOfDay(new Date()), startOfDay(new Date(data[0].timeOccurred)))
      : -1;
  }
  return (
    <Stack direction="column" justifyContent="center" alignItems="center" sx={{ height: '80%' }}>
      {interval !== -1 && (
        <>
          <Stack direction="row" alignItems="baseline" sx={{ m: 1 }}>
            <Typography sx={{ fontSize: 40, fontWeight: 700, mt: '0.5rem' }}>{interval}</Typography>
            <Typography sx={{ fontSize: 20, ml: 1, mt: 1 }}>{t('Period_Days')}</Typography>
          </Stack>
        </>
      )}
      {interval === -1 && <Typography>{t('Startpage_DaysInjury.NoResult')}</Typography>}
    </Stack>
  );
};

export default DaysSinceLastAccident;
