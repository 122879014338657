import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslations } from 'shared/hooks/useTranslations';

const StyledFooter = styled('div')`
  display: flex;
  background-color: ${({ theme }) => theme.customComponents?.iaAppBar.defaultFill};
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  color: ${({ theme }) => theme.palette.common.white};
  margin-top: 4rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
`;
const Footer = () => {
  const { t } = useTranslations();
  return (
    <StyledFooter>
      <Typography>{t('General_Footer')}</Typography>
    </StyledFooter>
  );
};

export default Footer;
