import { IMyReportsDetailsResponseItem } from 'shared/types/types';
import { getPieItems, getPieColors } from './chartDataHandling';
import { IDistribution, IMyReportRequest } from 'shared/startpageService/common/types';

export const getPiechartOptions = (
  distribution: IDistribution,
  item: IMyReportsDetailsResponseItem | undefined,
  settings: IMyReportRequest,
  title: string
): Highcharts.Options => {
  const seriesData = getPieItems(settings, item);

  return {
    accessibility: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    chart: {
      backgroundColor: 'transparent',
    },
    title: {
      text: '', // title,
      useHTML: true,
      align: 'left',
      style: {
        fontFamily: 'arial',
        fontSize: '13px',
        color: '#333',
        lineHeight: '20px',
      },
    },
    tooltip: {
      pointFormat: `{point.y} ${'av'} {point.total}<br>{point.percentage:.0f} %<br>`,
    },
    plotOptions: {
      pie: {
        colors: getPieColors(settings, item?.total),
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          distance: '-30%',
          enabled: true,
          format: `<b>{point.name}</b> <br>{point.y} ({point.percentage:.0f}%) `,
          textPath: {
            enabled: false,
          },
        },
      },
    },
    series: [
      {
        type: 'pie',
        name: 'Totalt',
        data: seriesData,
        size: 150,
        showInLegend: false,
        dataLabels: {
          enabled: true,
          format: `{point.name}`,
          style: {
            color: 'black',
            textOutline: 'transparent',
          },
        },
      },
    ],
  };
};
