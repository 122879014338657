import { useMutation } from '@tanstack/react-query';
import { useGlobalContext } from '../../../../globalContext';
import { useGetApiUrlFavorites } from '../../../../shared/hooks/useGetApiUrl';
import { iafetch } from '../../../../shared/utils';
import { useQuery } from 'shared/queries/useQueryWithError';

export interface Favorite {
  id: string;
  name: string;
  userId: string;
  companyId: string;
  url: string;
  createdDate: string | null;
}
// const mockFavorites: Favorite[] = [
//   {
//     id: 'ff746f0d-5a69-4af4-9048-4e093f894732',
//     name: 'Uppföljningar -',
//     userId: '16b11ddf-db27-401b-a6e5-498f3ebe1666',
//     companyId: '352ac2d6-7ac9-4dfb-914e-15bba0c195e0',
//     url: '/PreventionIA/IA/Pages/ActionPlan/AtgarderLista.aspx?Typ=Handlingsplan&SelekteraTyp=3&Source=Fav&Open=1&No=50&Per=cy',
//     createdDate: null,
//   },
//   {
//     id: '25cee536-6c8c-488e-97e1-ae382cd0e5de',
//     name: 'Åtgärder - jättebra, och jättejättejättelång text som ser ut såhär',
//     userId: '16b11ddf-db27-401b-a6e5-498f3ebe1666',
//     companyId: '352ac2d6-7ac9-4dfb-914e-15bba0c195e0',
//     url: '/PreventionIA/IA/Pages/ActionPlan/AtgarderLista.aspx?Typ=Handlingsplan&SelekteraTyp=2&Source=Fav&Open=1&No=50&Per=cy',
//     createdDate: null,
//   },
// ];

export const useGetFavorites = (enabled: boolean) => {
  const { userGuid, companyGuid } = useGlobalContext();
  const url = useGetApiUrlFavorites('Favorites/api/Companies', [companyGuid, 'Users', userGuid, 'Favorites']);
  const fetchFavorites = () => iafetch<Favorite[]>(url);
  return useQuery<Favorite[]>({
    queryKey: ['favorites', userGuid, companyGuid],
    queryFn: () => fetchFavorites(),
    enabled,
  });
  // return useQuery<Favorite[]>('favorites', () => Promise.resolve(mockFavorites));
};

export const useDeleteFavorite = () => {
  const { userGuid, companyGuid } = useGlobalContext();
  const url = useGetApiUrlFavorites('Favorites/api/Companies', [companyGuid, 'Users', userGuid, 'Favorites']);
  const deleteFavorites = (id: string) =>
    iafetch<Favorite[]>(`${url}/${id}`, {
      method: 'DELETE',
    });
  return useMutation({
    mutationFn: deleteFavorites,
  });
};
