import { styled } from '@mui/material/styles';
import { IMenuItem } from './navbarQueries';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import MenuList from '@mui/material/MenuList';

const StyledAccordion = styled(Accordion)`
  &.Mui-expanded {
    border-left: 3px solid ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.common.white};
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
  &:before {
    display: none;
  }
`;
const StyledAccordionDetails = styled(AccordionDetails)`
  background-color: ${({ theme }) => theme.palette.background.default};
`;

interface Props {
  item: IMenuItem;
}

const MobileMenuItem = ({ item }: Props) => {
  return (
    <StyledAccordion disableGutters square elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle2">{item.Text}</Typography>
      </AccordionSummary>
      <StyledAccordionDetails>
        {item.SubMenuItemList.map((subItem) => {
          return (
            <Stack key={subItem.Url}>
              <MenuList>
                <Link href={subItem.Url} underline="none">
                  <Typography variant="subtitle2" color="common.black">
                    {subItem.Text}
                  </Typography>
                </Link>
              </MenuList>
            </Stack>
          );
        })}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default MobileMenuItem;
