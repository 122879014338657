import Stack from '@mui/material/Stack';
import { useFiltersAndSorting } from '../useFiltersAndSorting';
import { SortOrder, SortType } from '../utils';
import { Grid, IconButton, Typography } from '@mui/material';
import { useLabels } from '../useLabels';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

const MobileSort = () => {
  const { todoListLabels } = useLabels();
  const { sortType, sortOrder, setSortType } = useFiltersAndSorting();

  const handleClick = (sortType: SortType, sortOrder: SortOrder) => {
    setSortType(sortType, sortOrder);
  };

  const sortTypes = [
    SortType.Todo,
    SortType.Date,
    SortType.Overdue,
    SortType.Type,
    SortType.Number,
    SortType.RiskValue,
    SortType.Title,
  ];

  return (
    <>
      {sortTypes.map((item) => (
        <Grid container spacing={1} direction="row" alignItems="center" key={item}>
          <Grid item xs={7} container justifyContent={'flex-end'}>
            <Typography>{todoListLabels[item]}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Stack direction="row" spacing={1} sx={{ ml: 2 }}>
              <IconButton onClick={() => handleClick(item, SortOrder.DESC)} sx={{ whiteSpace: 'nowrap', p: 1 }}>
                <ExpandCircleDownIcon
                  sx={{
                    color: item === sortType && sortOrder === SortOrder.DESC ? '#003CD2' : '#ccc',
                  }}
                />
              </IconButton>
              <IconButton onClick={() => handleClick(item, SortOrder.ASC)} sx={{ whiteSpace: 'nowrap', p: 1 }}>
                <ExpandCircleDownIcon
                  sx={{
                    color: item === sortType && sortOrder === SortOrder.ASC ? '#003CD2' : '#ccc',
                    transform: 'rotate(180deg)',
                  }}
                />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default MobileSort;
