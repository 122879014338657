import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import { ReactComponent as UserIcon } from '../../../assets/userIcon.svg';
import { MenuItem, Typography, Menu } from '@mui/material';
import React from 'react';
import { useTranslations } from '../../../shared/hooks/useTranslations';

const StyledAvatar = styled(Avatar)`
  width: auto;
  height: 1.5rem;
  border-radius: 8px;
  background-color: rgb(212, 225, 255);
  padding-right: 0.5rem;
  margin-right: 1rem;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.dark};
`;
interface Props {
  userName?: string;
}
const MobileUserMenu = ({ userName }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslations();

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const userInitials = ({ userName }: Props) => {
    const parsedUserName = userName?.split(' ');
    return parsedUserName === undefined ? '' : parsedUserName[0][0] + parsedUserName[1][0];
  };
  return (
    <>
      <StyledAvatar variant="rounded" onClick={handleClick}>
        <UserIcon color="primary.dark" />
        <Typography variant="subtitle1" color="primary.dark">
          {userInitials({ userName })}
        </Typography>
      </StyledAvatar>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleClose}>{t('Startpage_Header.AccountSettings')}</MenuItem>
        <MenuItem onClick={handleClose}>{t('Startpage_Header.SignOut')}</MenuItem>
      </Menu>
    </>
  );
};
export default MobileUserMenu;
