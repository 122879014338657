import Stack from '@mui/material/Stack';
import { useTranslations } from 'shared/hooks/useTranslations';
import { TodoItem } from 'shared/types/types';
import MainFilterButton from './MainFilterButton';
import { MainFilterSettings } from 'shared/startpageService/common/types';
import { ContentType } from 'shared/startpageService/common/enums';

interface Props {
  todoItems: TodoItem[];
  setMainFilterSettings: (mainFilterSettings: MainFilterSettings) => void;
  mainFilterSettings?: MainFilterSettings;
}
const MainFilter = ({ todoItems, setMainFilterSettings, mainFilterSettings }: Props) => {
  const { t } = useTranslations();
  const todoItemsOrigin = todoItems;
  todoItems = mainFilterSettings?.occurrences ? todoItems.filter((a) => !a.isRiskManagement) : todoItems;
  todoItems = mainFilterSettings?.riskManagements ? todoItems.filter((a) => a.isRiskManagement) : todoItems;

  const countById = (arr: TodoItem[]) => {
    const acc: TodoItem[] = [];
    for (const item of arr) {
      if (!acc.find((a: TodoItem) => a.id === item.id)) {
        acc.push(item);
      }
    }
    return acc.length;
  };

  const getCount = (contentType: ContentType) => {
    switch (contentType) {
      case ContentType.RISKMANAGEMENT:
        return countById(todoItemsOrigin.filter((a) => a.isRiskManagement));
      case ContentType.OCCURRENCE:
        return countById(todoItemsOrigin.filter((a) => !a.isRiskManagement));
      default:
        return 0;
    }
  };

  const toggleAll = () => {
    setMainFilterSettings({
      ...mainFilterSettings,
      riskManagements: false,
      occurrences: false,
    });
  };

  const toggleRiskManagements = () => {
    setMainFilterSettings({
      ...mainFilterSettings,
      riskManagements: !mainFilterSettings?.riskManagements,
      occurrences: !mainFilterSettings?.riskManagements ? false : mainFilterSettings?.occurrences,
    });
  };

  const toggleOccurrences = () => {
    setMainFilterSettings({
      ...mainFilterSettings,
      occurrences: !mainFilterSettings?.occurrences,
      riskManagements: !mainFilterSettings?.occurrences ? false : mainFilterSettings?.riskManagements,
    });
  };

  return (
    <Stack direction="row" spacing={2}>
      <MainFilterButton
        count={getCount(ContentType.RISKMANAGEMENT) + getCount(ContentType.OCCURRENCE)}
        toggle={toggleAll}
        isSelected={!mainFilterSettings?.riskManagements && !mainFilterSettings?.occurrences}
        text={t('Report_AllContent')}
        showCount={false}
      />
      <MainFilterButton
        count={getCount(ContentType.RISKMANAGEMENT)}
        toggle={toggleRiskManagements}
        isSelected={mainFilterSettings?.riskManagements}
        text={t('Report_RiskManagementPlural')}
        showCount={true}
      />
      <MainFilterButton
        count={getCount(ContentType.OCCURRENCE)}
        toggle={toggleOccurrences}
        isSelected={mainFilterSettings?.occurrences}
        text={t('Report_OccurrencesPlural')}
        showCount={true}
      />
    </Stack>
  );
};

export default MainFilter;
