import { useState } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TodoMobileList from './TodoList/TodoMobileList';
import { Button, Divider, Drawer, Grid, IconButton, Skeleton, Stack, Switch, Tooltip, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DetailView, { InfoPanelContent, StyledSwitchRow } from './DetailView/DetailView';
import { useFiltersAndSorting } from './useFiltersAndSorting';
import { getCounts, sortItems } from './utils';
import { useTranslations } from 'shared/hooks/useTranslations';
import { useGetTodoList } from 'shared/startpageService';
import { TodoItem } from 'shared/types/types';
import { TodoType } from 'shared/types/enums';
import WidgetBoxDynamic from 'shared/UI/WidgetBoxDynamic';
import TodoList from './TodoList/TodoList';
import TodoFilterButtons from './TodoList/TodoFilterButtons';
import MainFilter from './TodoList/MainFilter';
import { useLabels } from './useLabels';
import { LoggingErrorBoundary } from 'shared/error/LoggingErrorBoundary';
import FilterListIcon from '@mui/icons-material/FilterList';
import MobileSort from './TodoList/MobileSort';
import { useUserSettings } from '../Settings/useUserSettings';
import { StyledCloseIcon, WidthIcon } from '../CustomCharts/CustomChart';

const TableSkeleton = () => {
  return (
    <>
      <Stack direction="row" spacing={2}>
        <Skeleton width={120} height={42} variant="rounded" />
        <Skeleton width={120} height={42} variant="rounded" />
        <Skeleton width={120} height={42} variant="rounded" />
        <Skeleton width={120} height={42} variant="rounded" />
      </Stack>
      <Skeleton width="80%" />
      <Skeleton width="60%" />
      <Divider />
      <Skeleton width="80%" />
      <Skeleton width="60%" />
      <Divider />
      <Skeleton width="80%" />
      <Skeleton width="60%" />
      <Divider />
    </>
  );
};

const EmptyTodoList = styled('div')`
  display: flex;
  align-items: center;
`;

const filterById = (arr: TodoItem[]) => {
  const acc: TodoItem[] = [];
  for (const item of arr) {
    if (!acc.find((a: TodoItem) => a.id === item.id)) {
      acc.push(item);
    }
  }
  return acc;
};

const numberOfItems = 10;

interface Props {
  width: number;
}

const MyIA = ({ width }: Props) => {
  const {
    sortType,
    sortOrder,
    showOnlyOneRowPerId,
    setShowOnlyOneRowPerId,
    currentFilter,
    mainFilterSettings,
    setMainFilterSettings,
  } = useFiltersAndSorting();
  const { t, tFormat } = useTranslations();
  const { todoTypesLabels } = useLabels();
  const { items: todoItems, hasOccurence, hasRiskManagement } = useGetTodoList();
  const { toggleShowTodoList, toggleTodoListWidth } = useUserSettings();

  const isLoading = false;

  const [currentItemId, setCurrentItemId] = useState<number>();
  const [detailViewOpen, setDetailViewOpen] = useState<boolean>(false);
  const [mobileFilterViewOpen, setMobileFilterViewOpen] = useState<boolean>(false);
  const [page, setPage] = useState(0);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const sectionTitle = t('Startpage_MyTasks');

  const itemFilterFn = (item: TodoItem) => {
    if (currentFilter === TodoType.ALL) return item;
    return item.todoType === currentFilter;
  };

  const itemSourceFn = (item: TodoItem) => {
    if (mainFilterSettings?.riskManagements) {
      return item.isRiskManagement;
    }
    if (mainFilterSettings?.occurrences) {
      return !item.isRiskManagement;
    }
    return true;
  };

  const openDetailView = (id: number) => {
    setCurrentItemId(id);
    setDetailViewOpen(true);
  };

  let filteredItems = todoItems.filter(itemSourceFn).filter(itemFilterFn);
  filteredItems = showOnlyOneRowPerId ? filterById(filteredItems) : filteredItems;

  const totalCount = todoItems?.length ?? 0;
  const currentItem = filteredItems.find((item) => item.id === currentItemId);

  const getTypeString = () => {
    const occurenceString = t('Report_Occurrence').toLocaleLowerCase();
    const riskManagementString = t('Report_RiskManagement').toLocaleLowerCase();
    if (hasOccurence && hasRiskManagement) {
      return ` ${occurenceString}/${riskManagementString}`;
    }
    if (hasOccurence) {
      return occurenceString;
    }
    return riskManagementString;
  };

  const showNarrowList = !isDesktop || width <= 4;

  const oneRowString = tFormat('Startpage_ShowOneRow', [getTypeString()]);
  return (
    <LoggingErrorBoundary>
      <Grid item xs={width}>
        <Stack spacing={2}>
          <WidgetBoxDynamic>
            <Stack direction="row" spacing={1} justifyContent="end" mt="-0.8rem" mr="-0.5rem">
              <Tooltip title="Gör bredare">
                <IconButton onClick={() => toggleTodoListWidth()}>
                  <WidthIcon />
                </IconButton>
              </Tooltip>
              <IconButton onClick={() => toggleShowTodoList()}>
                <StyledCloseIcon />
              </IconButton>
            </Stack>
            {!isLoading && totalCount > 0 && (
              <Stack spacing={2} mt="-0.2rem">
                {filteredItems && !showNarrowList && (
                  <>
                    <Stack direction="row" justifyContent={'space-between'}>
                      <Typography variant="h6">{sectionTitle}</Typography>
                      <StyledSwitchRow>
                        <Typography variant="body1">{oneRowString}</Typography>
                        <Switch
                          checked={showOnlyOneRowPerId}
                          onChange={(_, checked) => setShowOnlyOneRowPerId(checked)}
                        />
                      </StyledSwitchRow>
                    </Stack>
                    <MainFilter
                      todoItems={todoItems}
                      setMainFilterSettings={setMainFilterSettings}
                      mainFilterSettings={mainFilterSettings}
                    />

                    <TodoFilterButtons
                      counts={getCounts(todoItems.filter(itemSourceFn), todoTypesLabels)}
                      direction="row"
                    />
                    <TodoList
                      todoItems={sortItems(filteredItems, todoTypesLabels, sortType, sortOrder).slice(
                        0,
                        page * numberOfItems + numberOfItems
                      )}
                      openDetailView={openDetailView}
                      currentItemId={currentItemId}
                    />
                  </>
                )}
                {filteredItems && showNarrowList && (
                  <>
                    <Stack direction="row" justifyContent="space-between">
                      {filteredItems && showNarrowList && (
                        <>
                          <Typography variant="h6">{sectionTitle}</Typography>
                          <IconButton onClick={() => setMobileFilterViewOpen(true)} sx={{ p: 0 }}>
                            <FilterListIcon />
                          </IconButton>
                        </>
                      )}
                    </Stack>
                    <TodoMobileList
                      todoItems={sortItems(filteredItems, todoTypesLabels, sortType, sortOrder).slice(
                        0,
                        page * numberOfItems + numberOfItems
                      )}
                      openDetailView={openDetailView}
                      currentItemId={currentItemId}
                    />
                    {mobileFilterViewOpen && (
                      <Drawer anchor="right" open={mobileFilterViewOpen} onClose={() => setMobileFilterViewOpen(false)}>
                        <InfoPanelContent>
                          <Stack spacing={6}>
                            <Stack spacing={2}>
                              <Typography variant="h3" fontSize="1rem" sx={{ background: '#eee', p: 2 }}>
                                Mina uppgifter - Inställningar
                              </Typography>
                              <Stack spacing={2} direction="row" sx={{ alignItems: 'center', ml: 4 }}>
                                <Typography variant="body1" sx={{ ml: 4 }}>
                                  {oneRowString}
                                </Typography>
                                <Switch
                                  checked={showOnlyOneRowPerId}
                                  onChange={(_, checked) => setShowOnlyOneRowPerId(checked)}
                                />
                              </Stack>
                            </Stack>
                            <Stack spacing={3}>
                              <Typography variant="h3" fontSize="1rem" sx={{ background: '#eee', p: 2 }}>
                                Filtrera på
                              </Typography>
                              <MainFilter
                                todoItems={todoItems}
                                setMainFilterSettings={setMainFilterSettings}
                                mainFilterSettings={mainFilterSettings}
                              />
                              <TodoFilterButtons
                                counts={getCounts(todoItems.filter(itemSourceFn), todoTypesLabels)}
                                direction="column"
                              />
                            </Stack>
                            <Stack spacing={2}>
                              <Typography variant="h3" fontSize="1rem" sx={{ background: '#eee', p: 2 }}>
                                Sortera på
                              </Typography>
                              <MobileSort />
                            </Stack>
                          </Stack>
                        </InfoPanelContent>
                      </Drawer>
                    )}
                  </>
                )}

                {currentItem && (
                  <DetailView
                    currentListItem={currentItem}
                    onClose={() => setDetailViewOpen(false)}
                    open={detailViewOpen}
                  />
                )}
              </Stack>
            )}
            {isLoading && <TableSkeleton />}
            {!isLoading && totalCount === 0 && (
              <EmptyTodoList>
                <CheckCircleIcon color="success" sx={{ fontSize: 36 }} />
                <Typography variant="subtitle1" color="text.secondary" sx={{ fontWeight: 400, ml: 1 }}>
                  {t('Startpage_NoTasks')}
                </Typography>
              </EmptyTodoList>
            )}
            {!isLoading &&
              filteredItems.length > filteredItems.slice(0, page * numberOfItems + numberOfItems).length && (
                <Button onClick={() => setPage(page + 1)}>{t('Startpage_LoadMore')}</Button>
              )}
          </WidgetBoxDynamic>
        </Stack>
      </Grid>
    </LoggingErrorBoundary>
  );
};

export default MyIA;
