import styled from '@emotion/styled';
import { useGetMainMenu } from './navbarQueries';
import { Link, useTheme } from '@mui/material';
import IALogo from 'shared/UI/IALogo';

const StyledImage = styled('img')`
  max-height: 47px;
  max-width: 140px;
  margin-left: 1rem;
`;

const Logo = () => {
  const { data: navbarData } = useGetMainMenu();
  const theme = useTheme();
  return (
    <>
      {navbarData?.HasCompanyLogotype && (
        <StyledImage src={`data:image/JPEG;base64,${navbarData?.CompanyLogoByteArray}`} alt="Organisation logga" />
      )}
      {!navbarData?.HasCompanyLogotype && (
        <Link href="/PreventionIA/IA/Pages/Start/StartPage.aspx">
          <IALogo color={theme.customComponents?.iaAppBar.defaultFill} width="48" height="34" className="menuElement" />
        </Link>
      )}
    </>
  );
};

export default Logo;
