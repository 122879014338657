import { Menu, MenuItem, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useUserSettings } from '../Settings/useUserSettings';
import { PeriodContentItem, useGetPeriodContent } from '../backendQueries/useGetPeriodContent';

interface Props {
  linkId: number;
  period?: PeriodContentItem;
}

const PeriodSelect = ({ linkId, period }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { periods } = useGetPeriodContent();
  const { toggleWidgetPeriod } = useUserSettings();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Button onClick={(e) => setAnchorEl(e.currentTarget)} sx={{ padding: 0, color: '#999', fontWeight: '400' }}>
        {period?.label} {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {periods.map((item) => (
          <MenuItem key={item.label} onClick={() => toggleWidgetPeriod(linkId, item)}>
            <Typography variant="body1" color="text.secondary" fontSize="0.875rem" ml={1}>
              {item.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default PeriodSelect;
