import { Menu, MenuItem, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useGlobalContext } from 'globalContext';
import { useState } from 'react';
import { baseUrl } from 'shared/constants';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { iafetch } from 'shared/utils';

const OrgSelect = () => {
  const { companyName, companyId, userCompanies } = useGlobalContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (id: string) => () => {
    void iafetch(`${baseUrl}/PreventionIA/IA/api/Web/Global/SwitchOrganization/${id}`, { method: 'POST' }).then(() =>
      window.location.reload()
    );
  };

  const selectableCompanyList = Object.entries(userCompanies || {}).filter(([id]) => id !== companyId);
  const open = Boolean(anchorEl);

  if (selectableCompanyList.length === 0) {
    return (
      <Typography variant="body1" color="text.secondary" fontSize="0.875rem">
        {companyName}
      </Typography>
    );
  }
  return (
    <div>
      <Button onClick={(e) => setAnchorEl(e.currentTarget)} sx={{ padding: 0 }}>
        {companyName} {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {selectableCompanyList.map(([id, name]) => (
          <MenuItem key={id} onClick={handleSelect(id)}>
            {name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default OrgSelect;
