import { isToday, isYesterday } from 'date-fns';
import format from 'date-fns/format';
import { useCallback } from 'react';
import { useTranslations } from 'shared/hooks/useTranslations';

type FormatDate = (dateStr: string | undefined, locale: Locale) => string;

const useFormatDate = (): { formatDate: FormatDate } => {
  const { t } = useTranslations();

  const formatDate = useCallback(
    (dateStr?: string, locale?: Locale) => {
      if (!dateStr || !locale) return '';

      const itemDate = new Date(dateStr);

      if (isYesterday(itemDate)) {
        return t('Startpage_Yesterday');
      }

      if (isToday(itemDate)) {
        return t('Startpage_Today');
      }

      return format(itemDate, 'P', { locale });
    },
    [t]
  );

  return { formatDate };
};

export { useFormatDate };
