import { ContentLevel, NewsLevel } from 'shared/types/enums';
import { Grid, Stack, Typography } from '@mui/material';
import CustomChart from 'sections/startpage/CustomCharts/CustomChart';
import { useGetLinkContentDataFromserver } from 'shared/startpageService/charts';
import { useGetNewsForLevel } from 'sections/startpage/News/newsQueries';
import { useTranslations } from 'shared/hooks/useTranslations';
import News from 'sections/startpage/News';
import { LoggingErrorBoundary } from 'shared/error/LoggingErrorBoundary';

interface Props {
  xs: number;
}

const Sector = ({ xs }: Props) => {
  const { t } = useTranslations();
  const { selectedContentSector } = useGetLinkContentDataFromserver();
  const { hasNews } = useGetNewsForLevel(NewsLevel.Sector);
  const maxNumberOfSlots = hasNews ? 0 : 1;

  const chartsToShowSector = selectedContentSector.slice(0, maxNumberOfSlots);
  const sectionTitle = t('Startpage_SectorInformation');

  return (
    <LoggingErrorBoundary>
      <Grid item xs={xs} sx={{ alignItems: 'flex-start' }}>
        <Typography variant="h6">{sectionTitle}</Typography>
        <Stack spacing={2}>
          {hasNews && <News level={NewsLevel.Sector} />}
          {chartsToShowSector?.map((content) => (
            <CustomChart key={content.Id} linkId={content.Id} level={ContentLevel.Sector} />
          ))}
        </Stack>
      </Grid>
    </LoggingErrorBoundary>
  );
};

export default Sector;
