import { ContentLevel, DetailType } from 'shared/types/enums';
import { IDetailsViewModel, IMyReportRequest, IMyReportsWidget } from 'shared/startpageService/common/types';
import { RequestTypes, requestTypes } from 'shared/startpageService/common/requestTypes';

const getFilterDetails = (filterDetails: IDetailsViewModel[]) => {
  const USER_DEFINED = 'userDefined-';

  // Set type of user defined fields to enum to match backend
  for (const item of filterDetails) {
    if (!item.fieldId) {
      item.fieldId = item.type.includes(USER_DEFINED) ? parseInt(item.type.split(USER_DEFINED)[1], 10) : 0;
    }
    item.type = item.type.includes(USER_DEFINED) ? DetailType.USER_DEFINED : item.type;
  }
  return filterDetails;
};

export const useGetFilterObject = (
  filterType: RequestTypes = RequestTypes.CustomChart,
  customChartSettings?: IMyReportsWidget
) => {
  const selectedFilter = customChartSettings ?? requestTypes[filterType];
  const filterObject: IMyReportRequest = {
    isAnalysisMode: [ContentLevel.Responsibility, ContentLevel.User].includes(
      selectedFilter.request.filter?.base?.level?.type ?? ContentLevel.Organization
    )
      ? false
      : selectedFilter.request.isAnalysisMode,
    filter: {
      base: selectedFilter.request.filter?.base,
      details: getFilterDetails(selectedFilter.request.filter?.details ?? []),
    },
    details: selectedFilter.request.details,
    list: selectedFilter.request.list,
    mainChart: selectedFilter.request.mainChart,
  };

  return filterObject;
};
