import { styled, useTheme } from '@mui/material/styles';
import Stack from '@mui/system/Stack';
import { MenuItem } from './MenuItem';
import { useGetMainMenu } from './navbarQueries';
import TopBar from './TopBar';
import UserMenu from './UserMenu';
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileAppBar from './MobileAppBar';
import Logo from './Logo';
import { Button } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

const StyledIaAppbar = styled('div')`
  padding: 1rem;
  background-color: ${({ theme }) => theme.palette.common.white};
  color: ${({ theme }) => theme.palette.primary.dark};
  display: flex;
  align-items: center;
  .menuElement:last-child {
    margin-left: auto;
  }
`;
const StyledStack = styled(Stack)`
  margin-left: 2.5rem;
`;

const IaAppBar = () => {
  const { data: navbarData } = useGetMainMenu();
  const theme = useTheme();
  const underTablet = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <>
      {underTablet && <MobileAppBar navbarData={navbarData} />}
      {!underTablet && (
        <>
          <TopBar />
          <StyledIaAppbar data-testid="app-bar">
            <Logo />
            <StyledStack direction="row" spacing={3} className="menuElement">
              <Button variant="contained" color="primary" target="_blank" href="../occurrences/HandelseVisa.aspx">
                Rapportera skada/observation
              </Button>
              <Button
                variant="contained"
                color="primary"
                target="_blank"
                href="../RiskManagement/Riskhantering.aspx?Ny=Ja"
              >
                Registrera skyddsrond
              </Button>
              <Button variant="contained" color="primary" target="_blank" href="../Reports/MyReportsList.aspx">
                Alla rapporter
              </Button>
              {/*  <Button variant="outlined" color="primary" href="../admin/AdminAnvKonton.aspx">
                <SettingsIcon sx={{ mr: 1 }} />
                Inställningar (Admin)
              </Button> */}
              {navbarData?.MenuItemList.filter((a) => a.Text === 'Admin').map((item) => (
                <MenuItem item={item} key={item.Text} />
              ))}
            </StyledStack>
            <UserMenu userName={navbarData?.UserName} className="menuElement" />
          </StyledIaAppbar>
        </>
      )}
    </>
  );
};

export default IaAppBar;
