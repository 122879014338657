import { Tooltip } from '@mui/material';
import FlagIcon from '@mui/icons-material/Flag';
import { useTranslations } from '../../../shared/hooks/useTranslations';
import { styled } from '@mui/material/styles';

const FlagLabel = styled('span')`
  color: ${({ theme }) => theme.palette.error.main};
  margin-right: 0.25rem;
`;

const FlaggedOccurrenceLabel = () => {
  const { t } = useTranslations();

  return (
    <FlagLabel>
      <Tooltip title={t('Selections_Detail_Flagged')}>
        <FlagIcon fontSize="inherit" />
      </Tooltip>
    </FlagLabel>
  );
};

export default FlaggedOccurrenceLabel;
