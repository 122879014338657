import { Button, Checkbox, Divider, Drawer, Grid, Stack, styled, Switch, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import ReportTitleTranslated from './ReportTitle';
import { ReportActivation } from 'shared/startpageService/charts/enums';
import { ajaxContentType, baseUrl } from 'shared/constants';
import { useGlobalContext } from 'globalContext';
import { useQueryClient } from '@tanstack/react-query';
import { LoggingErrorBoundary } from 'shared/error/LoggingErrorBoundary';
import MakeChartInfo from './MakeChartInfo';
import CreateNewsInfo from './CreateNewsInfo';
import { ILinkContent } from 'shared/startpageService/common/types';
import { useGetLinkContentDataFromserver } from '../backendQueries/useGetData';
import { iapost } from 'shared/utils';

const Container = styled('div')`
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  min-width: 600px;
`;

const DrawerHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
`;

const SettingsWidget = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledSwitchRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.5rem;
`;

const OrgSettings = () => {
  const queryClient = useQueryClient();
  const { companyName } = useGlobalContext();

  const { orgModules } = useGetLinkContentDataFromserver();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const onClose = () => {
    setDrawerOpen(false);
  };
  const onClick = () => {
    setDrawerOpen(true);
  };

  const SaveReport = (item: ILinkContent) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    iapost(`${baseUrl}/PreventionIA/IA/api/Web/Global/LinkContent/`, {
      method: 'POST',
      body: JSON.stringify({
        ...item,
      }),
      headers: new Headers({ 'content-type': ajaxContentType }),
    }).then(() => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({ queryKey: ['links-query'] });
    });
  };

  const toggleReportSetting =
    (item: ILinkContent, funcStr: string) => (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (item.SystemPart?.includes(funcStr)) {
        SaveReport({ ...item, SystemPart: item.SystemPart.replace(funcStr, '') });
      } else {
        SaveReport({ ...item, SystemPart: item.SystemPart + funcStr });
      }
    };

  const isActive = (item: ILinkContent) => {
    return item.SystemPart?.includes(ReportActivation.Active);
  };

  const isSelected = (item: ILinkContent) => {
    return item.SystemPart?.includes(ReportActivation.Selectable);
  };

  const isDefault = (item: ILinkContent) => {
    return item.SystemPart?.includes(ReportActivation.Default);
  };

  return (
    <LoggingErrorBoundary>
      <SettingsWidget>
        <Button variant="outlined" color="primary" onClick={onClick} sx={{ background: '#fff' }}>
          <SettingsIcon sx={{ mr: 1 }} />
          Anpassa startsidan för {companyName}
          {/* {t('Startpage_Customize')} */}
        </Button>
      </SettingsWidget>
      <Drawer anchor="right" open={drawerOpen} onClose={onClose}>
        <Container sx={{ width: '500px' }}>
          <DrawerHeader>
            <Stack spacing={0}>
              <Typography variant="h1">
                Anpassa startsidan för {companyName}
                {/* {t('Startpage_Customize')} */}
              </Typography>
              {/* <Typography variant="h4">användare inom {companyName}</Typography> */}
            </Stack>
            <Button onClick={onClose}>
              <CloseIcon />
            </Button>
          </DrawerHeader>
          <CreateNewsInfo />
          <MakeChartInfo showStandardReports={true} />

          <Grid container mt={4}>
            <Grid item xs={6}>
              <Typography variant="h3">Modul{/* {t('Startpage_StandardInformation')} */}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h3">Visas för alla</Typography>
            </Grid>
            <Grid item xs={2} alignItems="center">
              <Typography variant="h3">Valbar</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h3">Förvald</Typography>
            </Grid>
          </Grid>
          <Divider />
          {orgModules?.map((item) => {
            return (
              <Grid container key={item.Id}>
                <Grid item xs={6} sx={{ mt: 1.2 }}>
                  <ReportTitleTranslated title={item.Text} />
                </Grid>
                <Grid item xs={2}>
                  <Switch checked={isActive(item)} onChange={toggleReportSetting(item, ReportActivation.Active)} />
                </Grid>
                <Grid item xs={2}>
                  <Checkbox
                    checked={isSelected(item)}
                    onChange={toggleReportSetting(item, ReportActivation.Selectable)}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Checkbox checked={isDefault(item)} onChange={toggleReportSetting(item, ReportActivation.Default)} />
                </Grid>
              </Grid>
            );
          })}
        </Container>
      </Drawer>
    </LoggingErrorBoundary>
  );
};
export default OrgSettings;
