import { DetailType } from 'shared/types/enums';
import { STATUS, LIST_TAKE_LIMIT } from './constants';
import {
  ContentType,
  OccurrenceStatus,
  OccurrenceTypes,
  PeriodType,
  PersonCategoryString,
  RiskManagementStatus,
  SortingDirection,
  WithWithout,
} from './enums';
import { IMyReportsWidget } from './types';
import { IStartpageListResponseItem } from './responseTypes';
import { IMyReportsDetailsResponseItem, IMyReportsTotalCountResponseItem } from 'shared/types/types';

// TODO: Det mesta av innehållet i den här filen kan nog raderas - eftersom de queries som behövs ändå sätts i backend - gå igenom och gör! /Sara
const RISKMANAGEMENT_TYPES_ALL = [
  8, 32, 9, 12, 23, 11, 6, 10, 35, 22, 16, 15, 21, 26, 17, 3, 2, 31, 7, 28, 33, 34, 14, 24, 5, 1, 18, 20, 19, 30, 13,
  25, 36, 37, 27, 4,
];

const ongoingStatusesOccurrence = [
  OccurrenceStatus.RegistreradviaLightmodul,
  OccurrenceStatus.Grundregistrerad,
  OccurrenceStatus.Utredningpågår,
  OccurrenceStatus.Åtgärdshanteringpågår,
  OccurrenceStatus.Klarejuppföljd,
  OccurrenceStatus.Klarejgodkänd,
  OccurrenceStatus.Utredningklar,
];
const ongoingStatusesRiskManagement = [RiskManagementStatus.PLANNED, RiskManagementStatus.PERFORMED];

export enum RequestTypes {
  OccurrenceList = 'occurrenceList',
  RiskManagementList = 'riskManagementList',
  OpenRisksOccurrence = 'openRisksOccurrence',
  OpenRisksRiskManagement = 'openRisksRiskManagement',
  OpenActionsOccurrence = 'openActionsOccurrence',
  OpenActionsRiskManagement = 'openActionsRiskManagement',
  OngoingOccurrences = 'ongoingOccurrences',
  RiskmanagementsOngoing = 'RiskManagementsOngoing',
  CompletedActionsOccurrence = 'completedActionsOccurrence',
  CompletedActionsRiskManagement = 'completedActionsRiskManagement',
  CompletedOccurrences = 'completedOccurrences',
  RiskmanagementsCompleted = 'RiskManagementsCompleted',
  ReportedOccurrences = 'reportedOccurrences',
  PerformedRiskManagements = 'performedRiskManagements',
  DaysSinceLastAccident = 'daysSinceLastAccident',
  RiskReductionOccurrences = 'riskReductionOccurrences',
  RiskReductionRiskManagement = 'riskReductionRiskManagement',
  AccidentsWithAbsence = 'accidentsWithAbsence',
  AccidentsWithAbsenceLTI = 'accidentsWithAbsenceLTI',
  CustomChart = 'customChart',
  CustomChartList = 'customChartList',
  CustomTotalCount = 'customTotalCount',
}

export enum MyReportsQuerySource {
  List = 'List',
  Details = 'Details',
  TotalItemCount = 'TotalItemCount',
}

export const personCategoriesAll = [
  PersonCategoryString.OWN_STAFF,
  PersonCategoryString.ENTREPRENEUR,
  PersonCategoryString.OTHER_PERSON,
];

export const occurrenceTypesAll = [
  OccurrenceTypes.RISK_OBSERVATION,
  OccurrenceTypes.NEAR_MISS,
  OccurrenceTypes.ACCIDENT,
  OccurrenceTypes.COMMUTING_ACCIDENT,
  OccurrenceTypes.WORK_RELATED_ILLNESS,
  OccurrenceTypes.ENVIRONMENT,
  OccurrenceTypes.QUALITY,
  OccurrenceTypes.PROPERTY_SECURITY,
  OccurrenceTypes.IMPROVMENT_PROPOSAL,
  OccurrenceTypes.OTHER_DIVERGENCE,
  OccurrenceTypes.SAFETY_OBSERVATION,
  OccurrenceTypes.PUPIL,
  OccurrenceTypes.PRESCHOOLER,
  OccurrenceTypes.CARE_RECIPIENT,
  OccurrenceTypes.PATIENT,
  OccurrenceTypes.VICTIMIZATION,
];

export const requestTypes: Record<RequestTypes, IMyReportsWidget> = {
  [RequestTypes.OccurrenceList]: {
    request: {
      isAnalysisMode: true,
      filter: {
        details: [
          {
            type: DetailType.STATUS,
            ids: ongoingStatusesOccurrence,
          },
        ],
        base: {
          content: {
            type: ContentType.OCCURRENCE,
            occurrenceTypeIds: occurrenceTypesAll,
            includeReported: true,
            personCategories: personCategoriesAll,
          },
        },
      },
      list: {
        load: [
          DetailType.ACTION_TITLE,
          DetailType.ACTION_COUNT,
          DetailType.RISK_BEFORE_ACTION,
          DetailType.RISK_AFTER_ACTION,
          DetailType.RISK_REDUCTION_PERCENTAGE,
          DetailType.INVESTIGATION_PARTICIPANTS,
        ],
        sortColumn: DetailType.TIME_OCCURRED,
        sortDirection: SortingDirection.Desc,
        numberToShow: LIST_TAKE_LIMIT + 1,
      },
    },
  },
  [RequestTypes.RiskManagementList]: {
    request: {
      isAnalysisMode: false,
      filter: {
        details: [
          {
            type: DetailType.STATUS_RISKMANAGEMENT,
            ids: ongoingStatusesRiskManagement,
          },
        ],
        base: {
          content: {
            type: ContentType.RISKMANAGEMENT,
            riskManagementTypeIds: RISKMANAGEMENT_TYPES_ALL,
          },
        },
      },
      list: {
        load: [
          DetailType.NR,
          DetailType.ACTION_TITLE,
          DetailType.ACTION_DESCRIPTION,
          DetailType.ACTION_COUNT,
          DetailType.RISK_BEFORE_ACTION,
          DetailType.RISK_AFTER_ACTION,
          DetailType.RISK_REDUCTION_PERCENTAGE,
          DetailType.OBJECT,
          DetailType.OK_TRUE,
          DetailType.OK_FALSE,
          DetailType.PARTICIPANTS,
        ],
        sortColumn: DetailType.TIME_OCCURRED,
        sortDirection: SortingDirection.Desc,
        numberToShow: LIST_TAKE_LIMIT + 1,
      },
    },
  },
  [RequestTypes.OpenRisksOccurrence]: {
    request: {
      filter: {
        base: {
          content: {
            type: ContentType.OCCURRENCE,
            occurrenceTypeIds: occurrenceTypesAll,
            includeReported: true,
            personCategories: personCategoriesAll,
            mandatoryParts: [],
          },
        },
        details: [
          {
            type: DetailType.STATUS,
            ids: ongoingStatusesOccurrence,
          },
        ],
      },
      details: {
        load: [DetailType.RISK_BEFORE_ACTION],
      },
      mainChart: {
        load: [DetailType.RISK_BEFORE_ACTION],
      },
    },
  },
  [RequestTypes.OpenRisksRiskManagement]: {
    request: {
      filter: {
        base: {
          content: {
            type: ContentType.RISKMANAGEMENT,
            riskManagementTypeIds: RISKMANAGEMENT_TYPES_ALL,
          },
        },
        details: [
          {
            type: DetailType.STATUS_RISKMANAGEMENT,
            ids: ongoingStatusesRiskManagement,
          },
        ],
      },
      details: {
        load: [DetailType.RISK_BEFORE_ACTION],
      },
      mainChart: {
        load: [DetailType.STATUS_RISKMANAGEMENT],
      },
      list: {
        load: [],
      },
    },
  },
  [RequestTypes.OpenActionsOccurrence]: {
    request: {
      filter: {
        base: {
          content: {
            includeReported: true,
            occurrenceTypeIds: [1, 4, 7], // This should be replaced by
            personCategories: personCategoriesAll,
            type: ContentType.OCCURRENCE,
          },
        },

        details: [
          {
            type: DetailType.ACTION_STATUS,
            ids: [STATUS.ACTION.PLANNED, STATUS.ACTION.OVERDUE],
          },
        ],
      },
      details: {
        load: [DetailType.ACTION_STATUS],
      },
      mainChart: {
        load: [DetailType.STATUS],
      },
    },
  },
  [RequestTypes.OpenActionsRiskManagement]: {
    request: {
      filter: {
        base: {
          content: {
            includeReported: true,
            riskManagementTypeIds: RISKMANAGEMENT_TYPES_ALL,
            personCategories: personCategoriesAll,
            type: ContentType.RISKMANAGEMENT,
          },
        },

        details: [
          {
            type: DetailType.ACTION_STATUS,
            ids: [STATUS.ACTION.PLANNED, STATUS.ACTION.OVERDUE],
          },
        ],
      },
      details: {
        load: [DetailType.ACTION_STATUS],
      },
      mainChart: {
        load: [DetailType.STATUS_RISKMANAGEMENT],
      },
      list: { load: [] },
    },
  },
  [RequestTypes.OngoingOccurrences]: {
    request: {
      filter: {
        base: {
          content: {
            includeReported: true,
            occurrenceTypeIds: occurrenceTypesAll,
            personCategories: personCategoriesAll,
            type: ContentType.OCCURRENCE,
          },
        },

        details: [
          {
            type: DetailType.STATUS,
            ids: ongoingStatusesOccurrence,
          },
        ],
      },
    },
  },
  [RequestTypes.RiskmanagementsOngoing]: {
    request: {
      filter: {
        base: {
          content: {
            includeReported: true,
            riskManagementTypeIds: RISKMANAGEMENT_TYPES_ALL,
            personCategories: personCategoriesAll,
            type: ContentType.RISKMANAGEMENT,
          },
        },

        details: [
          {
            type: DetailType.STATUS_RISKMANAGEMENT,
            ids: [STATUS.RISKMANAGEMENT.PLANNED, STATUS.RISKMANAGEMENT.PERFORMED],
          },
        ],
      },
      details: {
        load: [],
      },
      mainChart: {
        load: [],
      },
      list: {
        load: [],
      },
    },
  },
  [RequestTypes.CompletedActionsOccurrence]: {
    request: {
      filter: {
        base: {
          content: {
            includeReported: true,
            occurrenceTypeIds: occurrenceTypesAll,
            personCategories: personCategoriesAll,
            type: ContentType.OCCURRENCE,
          },
        },

        details: [
          {
            type: DetailType.ACTION_STATUS,
            ids: [STATUS.ACTION.COMPLETED],
          },
        ],
      },
      details: {
        load: [DetailType.ACTION_STATUS],
      },
      mainChart: {
        load: [DetailType.STATUS],
      },
    },
    settings: {
      usePeriod: true,
      periodType: PeriodType.ActionsCompleted,
    },
  },
  [RequestTypes.CompletedActionsRiskManagement]: {
    request: {
      filter: {
        base: {
          content: {
            includeReported: true,
            riskManagementTypeIds: RISKMANAGEMENT_TYPES_ALL,
            personCategories: personCategoriesAll,
            type: ContentType.RISKMANAGEMENT,
          },
        },

        details: [
          {
            type: DetailType.ACTION_STATUS,
            ids: [STATUS.ACTION.COMPLETED],
          },
        ],
      },
      details: {
        load: [DetailType.ACTION_STATUS],
      },
      mainChart: {
        load: [DetailType.STATUS_RISKMANAGEMENT],
      },
      list: { load: [] },
    },
    settings: {
      usePeriod: true,
    },
  },
  [RequestTypes.CompletedOccurrences]: {
    request: {
      filter: {
        base: {
          content: {
            includeReported: true,
            occurrenceTypeIds: occurrenceTypesAll,
            personCategories: personCategoriesAll,
            type: ContentType.OCCURRENCE,
          },
        },

        details: [
          {
            type: DetailType.STATUS,
            ids: [STATUS.OCCURRENCE.COMPLETED],
          },
        ],
      },
    },
    settings: {
      usePeriod: true,
      periodType: PeriodType.Completed,
    },
  },
  [RequestTypes.RiskmanagementsCompleted]: {
    request: {
      filter: {
        base: {
          content: {
            includeReported: true,
            riskManagementTypeIds: RISKMANAGEMENT_TYPES_ALL,
            personCategories: personCategoriesAll,
            type: ContentType.RISKMANAGEMENT,
          },
        },

        details: [
          {
            type: DetailType.STATUS_RISKMANAGEMENT,
            ids: [STATUS.RISKMANAGEMENT.COMPLETED, STATUS.RISKMANAGEMENT.PERFORMED],
          },
        ],
      },
      details: {
        load: [],
      },
      mainChart: {
        load: [],
      },
      list: {
        load: [],
      },
    },
    settings: {
      usePeriod: true,
    },
  },
  [RequestTypes.ReportedOccurrences]: {
    request: {
      filter: {
        base: {
          content: {
            includeReported: true,
            occurrenceTypeIds: occurrenceTypesAll,
            personCategories: personCategoriesAll,
            type: ContentType.OCCURRENCE,
          },
        },
        details: [],
      },
      details: {
        load: [DetailType.OCCURRENCE_TYPE],
      },
      list: { load: [] },
      mainChart: { load: [DetailType.OCCURRENCE_TYPE] },
    },
    settings: {
      usePeriod: true,
    },
  },
  [RequestTypes.PerformedRiskManagements]: {
    request: {
      filter: {
        base: {
          content: {
            includeReported: true,
            riskManagementTypeIds: RISKMANAGEMENT_TYPES_ALL,
            personCategories: personCategoriesAll,
            type: ContentType.RISKMANAGEMENT,
          },
        },
        details: [
          {
            type: DetailType.STATUS_RISKMANAGEMENT,
            ids: [STATUS.RISKMANAGEMENT.COMPLETED, STATUS.RISKMANAGEMENT.PERFORMED],
          },
        ],
      },
      details: {
        load: [DetailType.RISKMANAGEMENT_TYPE],
      },
      list: { load: [] },
      mainChart: { load: [DetailType.RISKMANAGEMENT_TYPE] },
    },
    settings: {
      usePeriod: true,
    },
  },
  [RequestTypes.DaysSinceLastAccident]: {
    request: {
      filter: {
        base: {
          content: {
            type: ContentType.OCCURRENCE,
            occurrenceTypeIds: [OccurrenceTypes.ACCIDENT],
            includeReported: true,
            personCategories: personCategoriesAll,
          },
        },
        details: [{ type: DetailType.SICK_LEAVE, ids: [1] }],
      },
      list: {
        load: [DetailType.PERFORMANCE_OCCURRED_TODAY],
        sortColumn: DetailType.PERFORMANCE_OCCURRED_TODAY,
        sortDirection: SortingDirection.Asc,
        numberToShow: 1,
      },
    },
  },
  [RequestTypes.RiskReductionOccurrences]: {
    request: {
      details: {
        load: [DetailType.RISK_REDUCTION_PERCENTAGE],
      },
      filter: {
        base: {
          content: {
            occurrenceTypeIds: occurrenceTypesAll,
            type: ContentType.OCCURRENCE,
            personCategories: personCategoriesAll,
          },
        },
        details: [],
      },
      list: { load: [] },
      mainChart: { load: [DetailType.OCCURRENCE_TYPE] },
    },
    settings: {
      usePeriod: true,
      periodType: PeriodType.Completed,
    },
  },
  [RequestTypes.RiskReductionRiskManagement]: {
    request: {
      details: {
        load: [DetailType.RISK_REDUCTION_PERCENTAGE],
      },
      filter: {
        base: {
          content: {
            riskManagementTypeIds: RISKMANAGEMENT_TYPES_ALL,
            type: ContentType.RISKMANAGEMENT,
          },
        },
        details: [],
      },
      list: { load: [] },
      mainChart: { load: [DetailType.RISKMANAGEMENT_TYPE] },
    },
    settings: {
      usePeriod: true,
      periodType: PeriodType.Completed,
    },
  },
  [RequestTypes.AccidentsWithAbsence]: {
    request: {
      filter: {
        base: {
          content: {
            includeReported: true,
            occurrenceTypeIds: [OccurrenceTypes.ACCIDENT],
            personCategories: personCategoriesAll,
            type: ContentType.OCCURRENCE,
          },
        },

        details: [
          {
            type: DetailType.SICK_LEAVE,
            ids: [WithWithout.WITH],
          },
        ],
      },
    },
    settings: {
      usePeriod: true,
    },
  },
  [RequestTypes.AccidentsWithAbsenceLTI]: {
    request: {
      filter: {
        base: {
          content: {
            includeReported: true,
            occurrenceTypeIds: [OccurrenceTypes.ACCIDENT],
            personCategories: personCategoriesAll,
            type: ContentType.OCCURRENCE,
          },
        },

        details: [
          {
            type: DetailType.SICK_LEAVE,
            ids: [WithWithout.WITH],
          },
          {
            type: DetailType.LTI,
            ids: [WithWithout.WITH],
          },
        ],
      },
    },
    settings: {
      usePeriod: true,
    },
  },
  [RequestTypes.CustomChart]: {
    request: {
      filter: {
        base: {
          content: {
            includeReported: true,
            occurrenceTypeIds: occurrenceTypesAll,
            personCategories: personCategoriesAll,
            type: ContentType.OCCURRENCE,
          },
        },
        details: [],
      },
      details: {
        load: [DetailType.STATUS],
      },
      list: { load: [] },
      mainChart: { load: [DetailType.OCCURRENCE_TYPE] },
    },
    settings: {
      usePeriod: true,
    },
  },
  [RequestTypes.CustomChartList]: {
    request: {
      filter: {
        base: {
          content: {
            includeReported: true,
            occurrenceTypeIds: occurrenceTypesAll,
            personCategories: personCategoriesAll,
            type: ContentType.OCCURRENCE,
          },
        },
        details: [],
      },
      details: {
        load: [DetailType.STATUS],
      },
      list: { load: [] },
      mainChart: { load: [DetailType.OCCURRENCE_TYPE] },
    },
    settings: {
      usePeriod: true,
    },
  },
  [RequestTypes.CustomTotalCount]: {
    request: {
      filter: {
        base: {
          content: {
            includeReported: true,
            occurrenceTypeIds: occurrenceTypesAll,
            personCategories: personCategoriesAll,
            type: ContentType.OCCURRENCE,
          },
        },
        details: [],
      },
    },
  },
};

export type ReturnType<T> = T extends RequestTypes.OccurrenceList
  ? IStartpageListResponseItem[]
  : T extends RequestTypes.RiskManagementList
  ? IStartpageListResponseItem[]
  : T extends RequestTypes.OpenRisksOccurrence
  ? IMyReportsDetailsResponseItem[]
  : T extends RequestTypes.OpenRisksRiskManagement
  ? IMyReportsDetailsResponseItem[]
  : T extends RequestTypes.OngoingOccurrences
  ? IMyReportsTotalCountResponseItem
  : T extends RequestTypes.RiskmanagementsOngoing
  ? IMyReportsTotalCountResponseItem
  : T extends RequestTypes.OpenActionsOccurrence
  ? IMyReportsDetailsResponseItem[]
  : T extends RequestTypes.OpenActionsRiskManagement
  ? IMyReportsDetailsResponseItem[]
  : T extends RequestTypes.CompletedOccurrences
  ? IMyReportsTotalCountResponseItem
  : T extends RequestTypes.RiskmanagementsCompleted
  ? IMyReportsTotalCountResponseItem
  : T extends RequestTypes.CompletedActionsOccurrence
  ? IMyReportsDetailsResponseItem[]
  : T extends RequestTypes.CompletedActionsRiskManagement
  ? IMyReportsDetailsResponseItem[]
  : T extends RequestTypes.ReportedOccurrences
  ? IMyReportsDetailsResponseItem[]
  : T extends RequestTypes.PerformedRiskManagements
  ? IMyReportsDetailsResponseItem[]
  : T extends RequestTypes.DaysSinceLastAccident
  ? IStartpageListResponseItem[]
  : T extends RequestTypes.RiskReductionOccurrences
  ? IMyReportsDetailsResponseItem[]
  : T extends RequestTypes.RiskReductionRiskManagement
  ? IMyReportsDetailsResponseItem[]
  : T extends RequestTypes.CustomChart
  ? IMyReportsDetailsResponseItem[]
  : T extends RequestTypes.AccidentsWithAbsence
  ? IMyReportsTotalCountResponseItem
  : T extends RequestTypes.AccidentsWithAbsenceLTI
  ? IMyReportsTotalCountResponseItem
  : T extends RequestTypes.CustomChartList
  ? IStartpageListResponseItem[]
  : T extends RequestTypes.CustomTotalCount
  ? IMyReportsTotalCountResponseItem
  : never;
