import { Divider, Link, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import Favorites from './Favorites';
import NewsMenuItem from './NewsMenuItem';
import { useTranslations } from '../../../shared/hooks/useTranslations';
import { useGetMainMenu } from './navbarQueries';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PersonIcon from '@mui/icons-material/Person';
import { useQueryClient } from '@tanstack/react-query';

const StyledTopBar = styled('div')`
  height: 1.75rem;
  background-color: ${({ theme }) => theme.customComponents?.iaAppBar.defaultFill}; /* ia-appbar/secondaryFill */
  display: flex;
  padding-right: 1rem;
  align-items: center;
  color: ${({ theme }) => theme.palette.common.white};
`;

const StyledTypography = styled(Typography)`
  margin-right: auto;
  padding: 1rem;
`;

const StyledStack = styled(Stack)`
  margin-left: auto;
  align-items: center;
`;

export const StyledTopBarItem = styled(Typography)`
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.25rem;
  }
`;

export const TopBarItems = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslations();
  return (
    <>
      <NewsMenuItem />
      <Favorites />
      <Link href="/PreventionIA/IA/Pages/Start/KontaktIntern.aspx" color={'inherit'} underline="none">
        <StyledTopBarItem variant="body2">
          <ChatBubbleOutlineIcon />
          {t('General_AskSupport', 'Fråga supporten')}
        </StyledTopBarItem>
      </Link>
      <Link href="/PreventionIA/IA/Pages/InfoPortal/Dokument.aspx" underline="none" color="inherit">
        <StyledTopBarItem variant="body2">
          <MenuBookIcon />
          {t('Startpage_Header.Help')}
        </StyledTopBarItem>
      </Link>
      <Divider orientation="vertical" sx={{ backgroundColor: 'common.white' }} flexItem />
      <Link color="inherit" underline="none" href="/PreventionIA/IA/Pages/Admin/AdminAnvFlerInstallningar.aspx">
        <StyledTopBarItem variant="body2">
          <PersonIcon />
          {t('General_Settings')}
        </StyledTopBarItem>
      </Link>
      <Link
        underline="none"
        color="inherit"
        href="/PreventionIA/SystemNavigering.aspx?Utloggning=true"
        onClick={() => {
          void queryClient.invalidateQueries({ queryKey: ['navbar'] });
        }}
      >
        <StyledTopBarItem variant="body2">
          <LogoutIcon />
          {t('Startpage_Header.SignOut')}
        </StyledTopBarItem>
      </Link>
    </>
  );
};

const TopBar = () => {
  const { data: navbarData } = useGetMainMenu();
  const { t } = useTranslations();
  return (
    <StyledTopBar>
      {navbarData?.HasCompanyLogotype && <StyledTypography variant="body2">{t('General_IA-System')}</StyledTypography>}
      <StyledStack direction={'row'} spacing={2}>
        <TopBarItems />
      </StyledStack>
    </StyledTopBar>
  );
};

export default TopBar;
