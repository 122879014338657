import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { StyledTopBarItem } from './TopBar';
import { useHasUnreadNews } from '../News/newsQueries';
import Snackbar from '@mui/material/Snackbar';
import UnreadDot from '../News/UnreadDot';
import { IconButton, Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { useTranslations } from 'shared/hooks/useTranslations';

const NewsMenuItem = () => {
  const { t, tFormat } = useTranslations();
  const { hasUnread, numberOfUnread, setAllRead } = useHasUnreadNews();

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setAllRead();
    setOpen(false);
  };
  useEffect(() => {
    setOpen(Boolean(hasUnread));
  }, [hasUnread]);

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <>
      <Link href="/PreventionIA/IA/Pages/Admin/NyheterLista.aspx" color={'inherit'} underline="none">
        <StyledTopBarItem variant="body2">
          {hasUnread && <UnreadDot />}
          <NotificationsNoneIcon />
          {t('News_LinkText')}
        </StyledTopBarItem>
      </Link>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        message={tFormat('News_UnreadCount', [String(numberOfUnread ?? '')])}
        action={action}
      />
    </>
  );
};

export default NewsMenuItem;
