import { TodoType } from 'shared/types/enums';
import { SortType } from './utils';
import { useTranslations } from 'shared/hooks/useTranslations';

export const useLabels = () => {
  const { t } = useTranslations();
  const todoListLabels: Record<SortType, string> = {
    [SortType.Title]: t('Selections_Detail_Title'),
    [SortType.Todo]: t('Startpage_Task'),
    [SortType.Type]: t('Selections_Detail_Type'),
    [SortType.Number]: t('Selections_Detail_Nr'),
    [SortType.Date]: t('Selections_Detail_TimeOccurred'),
    [SortType.DateType]: t('Selections_DateType'),
    [SortType.Overdue]: t('Selections_StatusOverdue'),
    [SortType.RiskValue]: t('Selections_Detail_RiskBeforeAction'),
  };
  const todoTypesLabels: Record<TodoType, string> = {
    [TodoType.ALL]: t('General_All'),
    [TodoType.COMPLETE]: t('Startpage_MyIA.ToDo'),
    [TodoType.CONFIRM]: t('Startpage_MyIA.ToConfirm'),
    [TodoType.INVESTIGATE]: t('Startpage_MyIA.ToInvestigate'),
    [TodoType.TAKE_ACTION]: t('Startpage_MyIA.ToCorrect'),
    [TodoType.FOLLOW_UP]: t('Startpage_MyIA.ToFollowUp'),
    [TodoType.REDECIDE]: t('Startpage_MyIA.DisapprovedByOHS'),
    [TodoType.REMIND]: t('Startpage_MyIA.ToRemind'),
    [TodoType.MONITOR]: t('Startpage_MyIA.ToMonitor'),
    [TodoType.REPORTER]: t('Startpage_MyIA.ToReport'),
  };

  return {
    todoListLabels,
    todoTypesLabels,
  };
};
