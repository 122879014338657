export const functionTitles: Record<string, string> = {
  responsible: 'Selections_Detail_Responsible',
  responsibleOccurrence: 'Selections_Detail_Responsible',
  ohsContacted: 'Selections_Detail_ResponsibleOhs',
  investigator: 'Selections_Detail_Investigator',
  actionOwner: 'Selections_Detail_ActionOwner',
  followUpResponsible: 'Selections_Detail_FollowUpResponsible',
  followUpOtherResponsible: 'Selections_Detail_FollowUpOtherResponsible',
  participant: 'Selections_Detail_Participants',
};
