import { Grid } from '@mui/material';
import News from 'sections/startpage/News';
import { NewsLevel } from 'shared/types/enums';

interface Props {
  xs: number;
}

const IANews = ({ xs }: Props) => {
  return (
    <Grid item xs={xs}>
      {/* <SectionTitle title={sectionTitle} /> */}
      <News level={NewsLevel.Afa} />
    </Grid>
  );
};

export default IANews;
