export const iafetch = <T>(input: RequestInfo | URL, init?: RequestInit): Promise<T> => {
  // @ts-expect-error @ts-
  const url = (input?.url ?? input) as string;
  // eslint-disable-next-line no-restricted-syntax
  return fetch(input, { headers: { 'content-type': 'application/json', ...init?.headers }, ...init })
    .then((d) => {
      if (!d.ok) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw { message: `Error calling ${url} (status: ${d.status})`, cause: d.status }; // new Error({`Error calling ${url} (status: ${d.status})`});
      }
      return d;
    })
    .then((d) => d.json());
};

export const iapost = (input: RequestInfo | URL, init?: RequestInit): Promise<boolean> => {
  // @ts-expect-error @ts-
  const url = (input?.url ?? input) as string;
  // eslint-disable-next-line no-restricted-syntax
  return fetch(input, { headers: { 'content-type': 'application/json', ...init?.headers }, ...init })
    .then((d) => {
      if (!d.ok) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw { message: `Error calling ${url} (status: ${d.status})`, cause: d.status }; // new Error({`Error calling ${url} (status: ${d.status})`});
      }
      return d;
    })
    .then((d) => true);
};
