import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import SortSelect from './SortSelect';
import { Grid, Stack } from '@mui/material';
import { useTranslations } from 'shared/hooks/useTranslations';
import RiskAssessment from '../DetailView/RiskAssessment';
import { TodoItem } from 'shared/types/types';
import { SortType, SortOption } from '../utils';
import { TodoType } from 'shared/types/enums';
import DateWithIcon from '../DetailView/DateWithIcon';
import { useFiltersAndSorting } from '../useFiltersAndSorting';
import { useLabels } from '../useLabels';

export const SecondaryText = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: normal;
`;

interface Props {
  todoItems: TodoItem[];
  openDetailView: (id: number) => void;
  currentItemId?: number;
}

const TodoList = ({ todoItems, openDetailView, currentItemId }: Props) => {
  const { t } = useTranslations();
  const { sortType } = useFiltersAndSorting();
  const { todoListLabels, todoTypesLabels } = useLabels();

  const col1Width = 4.5;
  const col2Width = 2.5;
  const col3Width = 2.5;
  const col4Width = 2.5;

  const col1options = [
    { type: SortType.Todo, label: todoListLabels[SortType.Todo] },
    { type: SortType.Title, label: todoListLabels[SortType.Title] },
  ];

  const col2options = [
    { type: SortType.Date, label: todoListLabels[SortType.Date] },
    { type: SortType.Overdue, label: todoListLabels[SortType.Overdue] },
  ];

  const col3options = [
    { type: SortType.Number, label: todoListLabels[SortType.Number] },
    { type: SortType.Type, label: todoListLabels[SortType.Type] },
    { type: SortType.RiskValue, label: todoListLabels[SortType.RiskValue] },
  ];

  const getCurrentLabel = (options: SortOption[]) => {
    return options.map((a) => a.type).includes(sortType) ? todoListLabels[sortType] : options[0].label;
  };

  return (
    <>
      <Stack>
        <Grid spacing={0} container sx={{ borderBottom: 1, borderColor: '#ccc', alignmentContent: 'baseline' }}>
          <Grid item xs={col1Width}>
            <SortSelect options={col1options} text={getCurrentLabel(col1options)} />
          </Grid>
          <Grid item xs={col2Width}>
            <SortSelect options={col2options} text={getCurrentLabel(col2options)} />
          </Grid>
          <Grid item xs={col3Width}>
            <SortSelect options={col3options} text={getCurrentLabel(col3options)} />
          </Grid>
          <Grid item xs={col4Width}></Grid>
        </Grid>
        {todoItems?.map((item) => (
          <Grid
            container
            spacing={0}
            key={String(item.itemNumber) + String(item.todoType) + String(item.actionId) + String(item.date)}
            sx={{
              p: 1,
              borderBottom: 1,
              borderColor: '#ccc',
              backgroundColor: item.id === currentItemId ? '#eee' : '#fff',
            }}
          >
            <Grid item xs={col1Width} onClick={() => openDetailView(item.id)}>
              <Stack>
                <Typography variant="subtitle2">{`${todoTypesLabels[item.todoType]} ${
                  item.toRemindTodoType ? `: ${todoTypesLabels[item.toRemindTodoType]}` : ''
                }`}</Typography>
                <Typography variant="body2">{item.title}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={col2Width}>
              <Typography variant="body2">{t(item.dateTitle)}</Typography>
              <DateWithIcon date={item.date} isOverdue={item.isOverdue || item.todoType === TodoType.REMIND} />
            </Grid>
            <Grid item xs={col3Width}>
              <Stack direction="row" spacing={2}>
                <Stack>
                  <Typography variant="body2">{item.itemType}</Typography>

                  <Typography variant="body2">{item.itemNumber}</Typography>
                </Stack>
                {item.riskAssessment && <RiskAssessment riskAssessment={item.riskAssessment} />}
              </Stack>
            </Grid>

            <Grid item xs={col4Width}>
              <Stack direction="row" spacing={1} justifyContent="flex-end">
                <Button variant="text" onClick={() => openDetailView(item.id)}>
                  {t('Startpage_MyIA.Header.Details')}
                </Button>
                <Button variant="outlined" href={item.link}>
                  {t('General_Edit')}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        ))}
      </Stack>
    </>
  );
};

export default TodoList;
