import { IMyReportsDetailsResponseItem } from 'shared/types/types';
import { getTotalPercentage, getTotalRiskReductionPercentage } from '../chartDataHandling';
import { DetailType } from 'shared/types/enums';

export const getGaugeOptions = (
  item: IMyReportsDetailsResponseItem | undefined,
  color: string,
  totalCount?: number
): Highcharts.Options => {
  const total =
    item?.type === DetailType.RISK_REDUCTION_PERCENTAGE
      ? getTotalRiskReductionPercentage(item)
      : getTotalPercentage(item, totalCount);

  return {
    accessibility: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    chart: {
      type: 'solidgauge',
    },
    title: {
      text: '',
      useHTML: true,
      align: 'left',
      style: {
        fontFamily: 'arial',
        fontSize: '14px',
        color: '#333',
        fontWeight: 'bold',
      },
    },
    subtitle: {
      text: '', // getDetailSubtitle(i, type, detail, detailSettings),
      useHTML: true,
      align: 'left',
      style: {
        fontFamily: 'arial',
        fontSize: '12px',
        color: '#333',
        zIndex: 1,
      },
    },
    pane: {
      center: ['50%', '85%'],
      size: '140%',
      startAngle: -90,
      endAngle: 90,
      background: [
        {
          // backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
          innerRadius: '60%',
          outerRadius: '100%',
          shape: 'arc',
        },
      ],
    },

    exporting: {
      enabled: false,
    },

    tooltip: {
      useHTML: true,
      distance: 100,
      backgroundColor: 'rgba(255,255,255,1)',
      pointFormat: '',
      // headerFormat: `<span class="weaker">${  &&
      //   type === DETAILTYPE.RISK_REDUCTION_PERCENTAGE ? resources.Report_InfoMode_ClickInChartsText : ''}</span>`,
    },

    yAxis: {
      stops: [
        [0.0, color],
        [1.0, color],
      ],
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: undefined,
      tickAmount: 2,
      title: {
        y: -70,
      },
      labels: {
        y: 16,
      },
      min: 0,
      max: 100,
    },

    series: [
      {
        type: 'solidgauge',
        name: 'RPM',
        data: [total],
        dataLabels: {
          format: '<div style="text-align:center"><span style="font-size:25px">{y} %</span><br/></div>',
        },
      },
    ],

    plotOptions: {
      solidgauge: {
        // innerRadius: '75%',//75% for thinner gauge
        dataLabels: {
          y: -50,
          borderWidth: 0,
          useHTML: true,
        },
        cursor: 'pointer',
      },
    },
  };
};
