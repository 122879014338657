import { useGlobalContext } from 'globalContext';
import { getTodoModelOccurrences, getTodoModelRiskmanagements } from './model';
import { RequestTypes, ReturnType } from '../common/requestTypes';
import { IMyReportsWidget } from '../common/types';
import { UseQueryOptions } from '@tanstack/react-query';
import { iafetch } from 'shared/utils/iafetch';
import { getCounts } from './getCounts';
import { useGetFilterObject } from '../common/useGetFilterObject';
import { baseUrl } from 'shared/constants';
import { ContentLevel } from 'shared/types/enums';
import { useLabels } from 'sections/startpage/MyIA/useLabels';
import { useQuery } from 'shared/queries/useQueryWithError';

export const useGetTodoListFromServer = <T extends RequestTypes>(
  type: T,
  widgetSettings?: IMyReportsWidget,
  level?: ContentLevel,
  options?: UseQueryOptions<ReturnType<T>>
) => {
  const request = useGetFilterObject(type, widgetSettings, level);
  const { userId } = useGlobalContext();

  const queryFn = (): Promise<ReturnType<T>> =>
    iafetch(`${baseUrl}/PreventionIA/IA/api/Web/Startpage/List`, {
      method: 'POST',
      body: JSON.stringify(request),
      headers: new Headers({ 'content-type': 'application/json; charset=UTF-8' }),
    });
  return useQuery<ReturnType<T>>({
    queryKey: ['todo-list', type, level, request, userId],
    queryFn,
    ...options,
  });
};

export const useGetTodoList = () => {
  const { userId } = useGlobalContext();
  const { todoTypesLabels } = useLabels();

  const { data: occurrenceListData, isLoading: isLoadingOccurrence } = useGetTodoListFromServer(
    RequestTypes.OccurrenceList
  );
  const hasRiskAssessmentOccurrence = occurrenceListData?.some((a) => (a.riskBeforeAction ?? 0) > 0);
  const { items: occurrenceItems } = getTodoModelOccurrences(Number(userId), occurrenceListData) ?? {};
  const { data: riskManagementListData, isLoading: isLoadingRiskManagements } = useGetTodoListFromServer(
    RequestTypes.RiskManagementList
  );
  const hasRiskAssessmentRiskManagement = riskManagementListData
    ?.map((a) => a.riskBeforeActionRM)
    .some((a) => (a?.length ?? 0) > 1);

  const { items: riskManagementItems } = getTodoModelRiskmanagements(Number(userId), riskManagementListData) ?? {};
  const items = [...(occurrenceItems ?? []), ...(riskManagementItems ?? [])].sort((a, b) => b.id - a.id);

  return {
    items,
    counts: getCounts([], todoTypesLabels),
    hasRiskManagement: (riskManagementListData?.length ?? 0) > 0,
    hasOccurence: (occurrenceListData?.length ?? 0) > 0,
    hasRiskAssessmentOccurrence,
    hasRiskAssessmentRiskManagement,
    isLoading: isLoadingOccurrence || isLoadingRiskManagements,
  };
};
