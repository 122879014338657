import { useGlobalContext } from '../../../globalContext';
import { iafetch } from '../../../shared/utils';
import { useGetApiUrl } from '../../../shared/hooks/useGetApiUrl';
import { useQuery } from 'shared/queries/useQueryWithError';

export interface IMenuItem {
  Text: string;
  Url?: string;
  SubMenuItemList: IMenuItem[];
}

export interface AppBarResponse {
  CompanyLogoByteArray: any;
  CompanyName: string;
  HasCompanyLogotype: boolean;
  MenuItemList: IMenuItem[];
  SystemName: string;
  UserName: string;
}

const fetchAppBar = (url: string) => (): Promise<AppBarResponse> => {
  return iafetch(url);
};

export const useGetMainMenu = () => {
  const { userGuid, companyGuid, userCulture, industryId } = useGlobalContext();
  const apiValidationKey = '00000000-0000-0000-0000-000000000000';
  const url = useGetApiUrl(
    'IaHeader',
    [userGuid, userCulture, companyGuid, industryId?.toString(), apiValidationKey],
    'Global'
  );

  return useQuery<AppBarResponse>({
    queryKey: ['navbar', companyGuid, userCulture, userGuid],
    queryFn: fetchAppBar(url ?? ''),
    staleTime: 60 * 60 * 1000,
  });
};
