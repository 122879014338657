import { useQueryClient } from '@tanstack/react-query';
import { IWidgetConfig } from './types';
import { useGlobalContext } from 'globalContext';
import { useCallback } from 'react';
import { ContentLevel, TodoType } from 'shared/types/enums';
import { SortOrder, SortType } from 'sections/startpage/MyIA/utils';
import { useQuery } from 'shared/queries/useQueryWithError';

const CONFIG_VERSION = '2';
const configKey = `widgetConfig-${CONFIG_VERSION}`;

const defaultConfig: IWidgetConfig = {
  activeWidgets: [],
  todoList: {
    show: true,
    width: 6,
    numberToShow: 10,
    sortType: SortType.Date,
    sortOrder: SortOrder.DESC,
    showOnlyOneRowPerId: false,
    currentFilter: TodoType.ALL,
    mainFilterSettings: {
      riskManagements: false,
      occurrences: false,
    },
  },
  hasReadBanner: false,
  contentLevel: ContentLevel.User,
  period: 365,
};

const getConfigFromServer = (): Promise<IWidgetConfig> => {
  const savedConfigString = localStorage.getItem(configKey);
  if (savedConfigString && savedConfigString !== 'undefined') {
    return Promise.resolve({ ...defaultConfig, ...JSON.parse(savedConfigString) });
  }
  return Promise.resolve(defaultConfig);
};

const saveConfigToServer = (config: IWidgetConfig) => {
  const configString = JSON.stringify(config);
  localStorage.setItem(configKey, configString);
};

export const useGetConfig = () => {
  const { userId } = useGlobalContext();
  const query = useQuery<IWidgetConfig>({
    queryKey: ['config', configKey, userId],
    queryFn: () => getConfigFromServer(),
  });
  return {
    ...query,
    config: query.data,
  };
};

export const useSaveConfig = () => {
  const { data } = useGetConfig();
  const queryClient = useQueryClient();
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const saveConfig = useCallback(
    <T extends IWidgetConfig, U extends keyof T>(key: U, value: T[U]) => {
      if (data) {
        saveConfigToServer({
          ...data,
          [key]: value,
        });
      }
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({ queryKey: ['config'] });
    },
    [data, queryClient]
  );
  return saveConfig;
};
