import { Typography } from '@mui/material';
import { useTranslations } from 'shared/hooks/useTranslations';

interface Props {
  title: string;
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline'
    | 'inherit'
    | undefined;
}

const ReportTitleTranslated = ({ title, variant = 'body2' }: Props) => {
  const { t } = useTranslations();

  return <Typography variant={variant}>{title.startsWith('___') ? t(`ReportTitle_${title}`) : title}</Typography>;
};

export default ReportTitleTranslated;
