// import styled from '@emotion/styled';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import Stack from '@mui/material/Stack';
import { useFiltersAndSorting } from '../useFiltersAndSorting';
import { IconButton } from '@mui/material';
import { SortOption, SortOrder } from '../utils';

export const SecondaryText = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: normal;
`;

interface Props {
  options: SortOption[];
  text: string;
}
const SortSelect = ({ options, text }: Props) => {
  const { sortType, sortOrder, toggleSortOrder, setSortType } = useFiltersAndSorting();
  const [open, setOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isActive) {
      toggleSortOrder();
    } else {
      openSortMenu(event);
    }
  };

  const openSortMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    anchorEl.current = event.currentTarget;
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isActive = options.some((option) => option.type === sortType);

  const anchorEl = useRef<HTMLElement | null>(null);
  return (
    <>
      <Stack spacing={1} direction="row" alignItems="center">
        <SecondaryText
          onClick={openSortMenu}
          display="inline"
          sx={{ fontWeight: isActive ? 700 : 400, cursor: 'pointer' }}
        >
          {text}
        </SecondaryText>
        <Stack direction="column" spacing={-4}>
          <IconButton onClick={handleClick}>
            <ExpandLessIcon sx={{ p: 0.2, color: isActive && sortOrder === SortOrder.ASC ? '#000' : '#999' }} />
          </IconButton>
          <IconButton onClick={handleClick}>
            <ExpandMoreIcon sx={{ p: 0.2, color: isActive && sortOrder === SortOrder.DESC ? '#000' : '#999' }} />
          </IconButton>
        </Stack>
      </Stack>

      <Popper
        open={open}
        anchorEl={anchorEl.current}
        role={undefined}
        placement="bottom-start"
        transition
        style={{ zIndex: 500 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button">
                  {options.map((option) => (
                    <MenuItem
                      key={option.type}
                      onClick={() => {
                        setSortType(option.type);
                      }}
                    >
                      {option.type === sortType && <CheckIcon sx={{ mr: 1 }} />}
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default SortSelect;
