import { Button, Dialog, Stack, ThemeProvider, Typography } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import themeJson from '@ia/theme/theme.json';
// import themeJson from '../../theme.json';
import IaAppBar from './IaAppBar';
import WidgetGrid from './WidgetGrid';
import SystemStatus from './SystemStatus';
import { useEffect } from 'react';
import BetaBanner from './BetaBanner';
import Footer from './Footer/Footer';
import { LoggingErrorBoundary } from 'shared/error/LoggingErrorBoundary';
import { useGlobalContext } from 'globalContext';

const globalStyles = {
  '*': {},
  body: {
    backgroundColor: '#f5f5f5',
    padding: 0,
    margin: 0,
  },
};

const theme = createTheme({
  ...themeJson,
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: '1rem',
          },
        },
      },
    },
  },
});
const Container = styled('div')`
  min-height: 100vh;
  position: relative;
  padding-bottom: 3rem;
  box-sizing: border-box;
`;

const NotLoggedIn = () => {
  const { showLoggedOutDialog } = useGlobalContext();
  return (
    <Dialog open={Boolean(showLoggedOutDialog)}>
      <Stack direction="column" justifyContent="center" alignItems="center" padding={'1.5rem'} gap={'1rem'}>
        <Typography variant="body1">Du har blivit utloggad</Typography>
        <Button variant="contained" color="primary" size="large" onClick={() => window.location.reload()}>
          Logga in
        </Button>
      </Stack>
    </Dialog>
  );
};
const Startpage = () => {
  useEffect(() => {
    if (!window.localStorage.getItem('beta_startpage_tester')) {
      window.localStorage.setItem('beta_startpage_tester', 'true');
    }
  }, []);
  return (
    <LoggingErrorBoundary>
      <Container>
        <IaAppBar />
        <WidgetGrid />
        <SystemStatus />
        <BetaBanner />
        <NotLoggedIn />
        <Footer />
      </Container>
    </LoggingErrorBoundary>
  );
};

export default () => (
  <ThemeProvider theme={theme}>
    <GlobalStyles styles={globalStyles} />
    <Startpage />
  </ThemeProvider>
);
