import { baseUrl } from 'shared/constants';
import { iafetch } from 'shared/utils';
import { IDistribution, ILinkContent, IMyReportRequest, IReport, IReportSettings } from '../common/types';
import { Systempart } from './enums';
import { ContentLevel, DetailType, NewsLevel } from 'shared/types/enums';
import { useGetNewsForLevel } from 'sections/startpage/News/newsQueries';
import { useQuery } from 'shared/queries/useQueryWithError';

export const useGetAllStartpageLinkContentFromServer = () => {
  const queryFn = (): Promise<ILinkContent[]> =>
    iafetch(
      `${baseUrl}/PreventionIA/IA/api/Web/Global/LinkContent/${Systempart.START_PAGE_MY},${Systempart.START_PAGE_ORG},${Systempart.START_PAGE_STANDARD},${Systempart.START_PAGE_INSPIRATION},${Systempart.START_PAGE_SECTOR}`,
      {
        method: 'GET',
        headers: new Headers({ 'content-type': 'application/json; charset=UTF-8' }),
      }
    );

  return useQuery<ILinkContent[]>({ queryKey: ['links-query'], queryFn });
};

const getContentCount = (hasNews: boolean | undefined, count: number) => {
  return hasNews ? count + 1 : count;
};

export const useGetLinkContentDataFromserver = () => {
  const { data: allAvailableReports } = useGetAllStartpageLinkContentFromServer();
  const { hasNews: hasNewsSector } = useGetNewsForLevel(NewsLevel.Sector);
  const { hasNews: hasNewsOrg } = useGetNewsForLevel(NewsLevel.Organization);

  const AvailableSystemPartsForSector = [Systempart.START_PAGE_STANDARD];
  const AvailableSystemPartsForOrg = [Systempart.START_PAGE_INSPIRATION, Systempart.START_PAGE_STANDARD];
  const AvailableSystemPartsForUser = [Systempart.START_PAGE_ORG];

  const getAvailableSystemparts = (level: ContentLevel) => {
    if (level === ContentLevel.Sector) {
      return AvailableSystemPartsForSector;
    }
    if (level === ContentLevel.Organization) {
      return AvailableSystemPartsForOrg;
    }
    if (level === ContentLevel.User) {
      return AvailableSystemPartsForUser;
    } else return [];
  };

  const getAvailableReports = (level: ContentLevel) => {
    if (!allAvailableReports) return [];
    return allAvailableReports
      .filter((a) => getAvailableSystemparts(level).includes(a.SystemPartId))
      .sort((b, c) => b.SystemPartId - c.SystemPartId || b.SortOrder - c.SortOrder);
  };

  const getSelectedReports = (level: ContentLevel) => {
    if (!allAvailableReports) return [];
    if (level === ContentLevel.Sector) {
      return allAvailableReports.filter((a) => a.SystemPartId === Systempart.START_PAGE_SECTOR);
    }
    if (level === ContentLevel.Organization) {
      return allAvailableReports.filter((a) => a.SystemPartId === Systempart.START_PAGE_ORG);
    }
    if (level === ContentLevel.User) {
      return allAvailableReports.filter((a) => a.SystemPartId === Systempart.START_PAGE_MY);
    } else return [];
  };

  const getOwnReports = (systempart: Systempart, level: ContentLevel) => {
    const availableStdReports = getAvailableReports(level)?.map((a) => a.Text) ?? [];
    const availableStandardReports =
      level === ContentLevel.Organization
        ? availableStdReports?.concat(getAvailableReports(ContentLevel.Sector)?.map((a) => a.Text) ?? [])
        : availableStdReports;
    return (
      allAvailableReports
        ?.filter((a) => a.SystemPartId === systempart)
        .filter((a) => !availableStandardReports?.includes(a.Text)) ?? []
    );
  };

  const standardContentOrg = getAvailableReports(ContentLevel.Organization);
  const standardContentUser = getAvailableReports(ContentLevel.User);
  const standardContentSector = getAvailableReports(ContentLevel.Sector);
  const selectedContentOrg = getSelectedReports(ContentLevel.Organization);
  const selectedContentUser = getSelectedReports(ContentLevel.User);
  const selectedContentSector = getSelectedReports(ContentLevel.Sector);
  const ownContentOrg = getOwnReports(Systempart.START_PAGE_ORG, ContentLevel.Organization);
  const ownContentUser = getOwnReports(Systempart.START_PAGE_MY, ContentLevel.User);

  const selectedContentOrgCount = selectedContentOrg.length;
  const selectedContentSectorCount = selectedContentSector.length;
  const selectedContentUserCount = selectedContentUser.length;

  const countOrgContentInclNews = getContentCount(hasNewsOrg, selectedContentOrgCount);
  const countSectorContentInclNews = getContentCount(hasNewsSector, selectedContentSectorCount);
  const hasOrgContent = countOrgContentInclNews > 0;
  const hasSectorContent = countSectorContentInclNews > 0;
  const hasUserContent = selectedContentUser.length > 0;
  const hasOwnContentUser = ownContentUser.length > 0;
  const hasOwnContentOrg = ownContentOrg.length > 0;

  return {
    standardContentSector,
    standardContentOrg,
    standardContentUser,
    selectedContentOrg,
    selectedContentSector,
    selectedContentUser,
    ownContentOrg,
    ownContentUser,
    hasOrgContent,
    hasSectorContent,
    hasUserContent,
    hasOwnContentOrg,
    hasOwnContentUser,
    selectedContentOrgCount,
    selectedContentSectorCount,
    selectedContentUserCount,
    countOrgContentInclNews,
    countSectorContentInclNews,
  };
};

export const useGetReportFromServer = (id?: number) => {
  const fetchUrl: string = `${baseUrl}/PreventionIA/IA/api/Web/Reports/MyReports/Settings?id=${id}`;

  const queryFn = (): Promise<IReport> =>
    iafetch(fetchUrl, {
      method: 'GET',
      headers: new Headers({ 'content-type': 'application/json; charset=UTF-8' }),
    });

  return useQuery<IReport>({ queryKey: ['chart-query', id], queryFn, enabled: typeof id === 'number' });
};

export const useGetLinkContentIds = (systempart: Systempart, ids?: number[]) => {
  const { data: links } = useGetAllStartpageLinkContentFromServer();

  return links?.filter((link) => link.SystemPartId === systempart).map((link) => link.Id) ?? [];
};

export const useGetReportIds = (systempart: Systempart) => {
  const { data: links } = useGetAllStartpageLinkContentFromServer();
  const linksForSystempart = links?.filter((link) => link.SystemPartId === systempart);
  return linksForSystempart?.map((a) => Number(a.Link.split('=')[1])) ?? [];
};

interface ChartByLinkId {
  link: ILinkContent | undefined;
  isLoading: boolean;
  isFetching: boolean;
  reportSettings: IReportSettings;
  settings: IMyReportRequest;
  serie: DetailType;
  distribution: IDistribution | undefined;
  reportTitle: string;
}

export const useGetCustomChartByLinkId = (linkId: number): ChartByLinkId => {
  const { data: allLinks, isLoading: isLoadingLinks } = useGetAllStartpageLinkContentFromServer();
  const link = allLinks?.find((link) => link.Id === linkId);
  const reportId = link?.Link.split('=')[1];
  const { data, isLoading: isLoadingChartSettings, isFetching } = useGetReportFromServer(Number(reportId));

  const reportSettings = JSON.parse(data?.Settings ?? 'false') as IReportSettings;
  const section = reportSettings?.sections?.[0];
  const distributions = section?.settings.details?.distributions ?? [];
  const settings = section?.settings;
  const serie = section?.settings.mainChart?.load?.[0] ?? DetailType.STATUS;
  const distribution = distributions?.[0];

  const reportTitle = link?.Text ?? '';

  return {
    link,
    isLoading: isLoadingLinks || isLoadingChartSettings,
    isFetching,
    reportSettings,
    settings,
    serie,
    distribution,
    reportTitle,
  };
};

export const useGetCustomCharts = () => {};
