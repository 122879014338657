import { useCallback } from 'react';
import { useGetConfig, useSaveConfig } from 'shared/startpageService/settings';
import { LevelContentItem } from '../backendQueries/useGetLevelContent';
import { PeriodContentItem } from '../backendQueries/useGetPeriodContent';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useUserSettings = () => {
  const { config } = useGetConfig();
  const saveConfig = useSaveConfig();
  const defaultTodoListWidth = 6;

  const toggleShowTodoList = useCallback(() => {
    saveConfig('todoList', {
      ...config?.todoList,
      show: !config?.todoList?.show,
    });
  }, [config, saveConfig]);

  const toggleTodoListWidth = useCallback(() => {
    const widgetWidths = [3, 4, 6, 8, 9, 12];
    const currentWidth = config?.todoList?.width ?? defaultTodoListWidth;
    const width = currentWidth === 12 ? 3 : widgetWidths[widgetWidths.findIndex((a) => a === currentWidth) + 1];
    saveConfig('todoList', {
      ...config?.todoList,
      width,
    });
  }, [config, saveConfig]);

  const toggleWidget = useCallback(
    (id: number) => {
      if (config?.activeWidgets?.some((a) => a.linkId === id)) {
        saveConfig(
          'activeWidgets',
          config?.activeWidgets?.filter((a) => a.linkId !== id)
        );
      } else {
        const widget = { linkId: id, width: 6 };
        const widgets = config?.activeWidgets ?? [];
        widgets.push(widget);
        saveConfig('activeWidgets', widgets);
      }
    },
    [config, saveConfig]
  );

  const toggleWidgetWidth = useCallback(
    (id: number) => {
      const widgetWidths = [3, 4, 6, 9, 12];
      const widgetIndex = config?.activeWidgets?.findIndex((a) => a.linkId === id) ?? 0;
      const widget = config?.activeWidgets?.find((a) => a.linkId === id) ?? { linkId: id, width: 6 };
      const width = widget.width === 12 ? 3 : widgetWidths[widgetWidths.findIndex((a) => a === widget.width) + 1];
      widget.width = width;
      const widgets = config?.activeWidgets ?? [];
      widgets[widgetIndex] = widget;
      saveConfig('activeWidgets', widgets);
    },
    [config, saveConfig]
  );

  const toggleWidgetLevel = useCallback(
    (id: number, level: LevelContentItem) => {
      const widgetIndex = config?.activeWidgets?.findIndex((a) => a.linkId === id) ?? 0;
      const widget = config?.activeWidgets?.find((a) => a.linkId === id) ?? { linkId: id, width: 6 };
      widget.level = level;
      const widgets = config?.activeWidgets ?? [];
      widgets[widgetIndex] = widget;
      saveConfig('activeWidgets', widgets);
    },
    [config, saveConfig]
  );

  const toggleWidgetPeriod = useCallback(
    (id: number, period: PeriodContentItem) => {
      const widgetIndex = config?.activeWidgets?.findIndex((a) => a.linkId === id) ?? 0;
      const widget = config?.activeWidgets?.find((a) => a.linkId === id) ?? { linkId: id, width: 6 };
      widget.period = period;
      const widgets = config?.activeWidgets ?? [];
      widgets[widgetIndex] = widget;
      saveConfig('activeWidgets', widgets);
    },
    [config, saveConfig]
  );

  return {
    toggleShowTodoList,
    showTodoList: config?.todoList?.show ?? true,
    toggleWidget,
    activeWidgets: config?.activeWidgets,
    hasUserContent: config?.activeWidgets ? config?.activeWidgets?.length > 0 : false,
    toggleWidgetWidth,
    toggleWidgetLevel,
    toggleWidgetPeriod,
    todoListWidth: config?.todoList?.width ?? defaultTodoListWidth,
    toggleTodoListWidth,
  };
};
