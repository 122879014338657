import { Divider, Menu, MenuItem, styled, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { LevelContentItem, useGetLevelContent } from '../backendQueries/useGetLevelContent';
import { useUserSettings } from '../Settings/useUserSettings';
import PersonIcon from '@mui/icons-material/Person';
import FactoryIcon from '@mui/icons-material/Factory';
import GroupsIcon from '@mui/icons-material/Groups';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import StopIcon from '@mui/icons-material/Stop';
import ShareIcon from '@mui/icons-material/Share';
import { ContentLevel } from 'shared/types/enums';

export const DividerText = styled('div')`
  color: #999;
  font-size: 10px;
  font-family: 'Roboto';
`;

interface Props {
  linkId: number;
  level?: LevelContentItem;
}

const LevelSelect = ({ linkId, level }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { sectors, groupings, hasGroupings, organizations, units, responsibility, user } = useGetLevelContent();
  const { toggleWidgetLevel } = useUserSettings();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  let levelNameToShow = 'Mitt';
  if (level) {
    levelNameToShow = [ContentLevel.User, ContentLevel.Responsibility].includes(level.type)
      ? level.typeLabel ?? 'Mitt'
      : level.name ?? '';
  }

  return (
    <div>
      <Button onClick={(e) => setAnchorEl(e.currentTarget)} sx={{ padding: 0, color: '#999', fontWeight: '400' }}>
        {levelNameToShow} {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Divider textAlign="left">
          <DividerText>Mitt</DividerText>
        </Divider>
        <MenuItem onClick={() => toggleWidgetLevel(linkId, user)}>
          <PersonIcon fontSize="small" sx={{ color: '#999' }} />
          <Typography variant="body1" color="text.secondary" fontSize="0.875rem" ml={1}>
            {user.typeLabel} - {user.name}
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => toggleWidgetLevel(linkId, responsibility)}>
          <GroupsIcon fontSize="small" sx={{ color: '#999' }} />
          <Typography variant="body1" color="text.secondary" fontSize="0.875rem" ml={1}>
            {responsibility.typeLabel} - {responsibility.name}
          </Typography>
        </MenuItem>
        <Divider textAlign="left">
          <DividerText>Enhet</DividerText>
        </Divider>
        {units.map((item) => (
          <MenuItem key={item.type.toString() + item.id.toString()} onClick={() => toggleWidgetLevel(linkId, item)}>
            <StopIcon fontSize="small" sx={{ color: '#999' }} />
            <Typography variant="body1" color="text.secondary" fontSize="0.875rem" ml={1}>
              {item.name}
            </Typography>
          </MenuItem>
        ))}
        <Divider textAlign="left">
          <DividerText>Organisation</DividerText>
        </Divider>
        {organizations.map((item) => (
          <MenuItem key={item.type.toString() + item.id.toString()} onClick={() => toggleWidgetLevel(linkId, item)}>
            <FactoryIcon fontSize="small" sx={{ color: '#999' }} />
            <Typography variant="body1" color="text.secondary" fontSize="0.875rem" ml={1}>
              {item.name}
            </Typography>
          </MenuItem>
        ))}
        {hasGroupings && (
          <Divider textAlign="left">
            <DividerText>Gruppering</DividerText>
          </Divider>
        )}
        {groupings.map((item) => (
          <MenuItem key={item.type.toString() + item.id.toString()} onClick={() => toggleWidgetLevel(linkId, item)}>
            <AccountTreeIcon fontSize="small" sx={{ color: '#999' }} />
            <Typography variant="body1" color="text.secondary" fontSize="0.875rem" ml={1}>
              {item.name}
            </Typography>
          </MenuItem>
        ))}
        <Divider textAlign="left">
          <DividerText>Bransch</DividerText>
        </Divider>
        {sectors.map((item) => (
          <MenuItem key={item.type.toString() + item.id.toString()} onClick={() => toggleWidgetLevel(linkId, item)}>
            <ShareIcon fontSize="small" sx={{ color: '#999' }} />
            <Typography variant="body1" color="text.secondary" fontSize="0.875rem" ml={1}>
              {item.name}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default LevelSelect;
