import styled from '@emotion/styled';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import format from 'date-fns/format';
import { useState } from 'react';
import { useTranslations } from '../../../shared/hooks/useTranslations';
import Typography from '@mui/material/Typography';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useGetConfig, useSaveConfig } from 'shared/startpageService/settings';
import { Period } from 'shared/types/types';
import Menu from '@mui/material/Menu';
import { LoggingErrorBoundary } from 'shared/error/LoggingErrorBoundary';

const StyledPeriod = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const PeriodSelect = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { data: config } = useGetConfig();
  const saveConfig = useSaveConfig();

  const { t } = useTranslations();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const selectPeriod = (period: Period) => {
    saveConfig('period', period);
    handleClose();
  };
  const open = Boolean(anchorEl);

  return (
    <LoggingErrorBoundary>
      <StyledPeriod>
        <Stack direction="row" alignItems="center">
          {/* ordet period finns - "Period_Period" */}
          <Typography variant="body2">{t('Period_Period')}</Typography>
          <Button onClick={handleClick}>
            {config?.period !== 'during' && t(`Startpage_Period.${config?.period}.ShortText`)}
            {config?.period === 'during' && (
              <>
                {t(`Startpage_Period.During`)} {format(new Date(), 'yyyy')}
              </>
            )}
            <ChevronRightIcon />
          </Button>
        </Stack>
      </StyledPeriod>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button">
          <MenuItem
            onClick={() => {
              selectPeriod(7);
            }}
          >
            {t(`Startpage_Period.7.LongText`)}
          </MenuItem>
          <MenuItem
            onClick={() => {
              selectPeriod(30);
            }}
          >
            {t(`Startpage_Period.30.LongText`)}
          </MenuItem>
          <MenuItem
            onClick={() => {
              selectPeriod(365);
            }}
          >
            {t(`Startpage_Period.365.LongText`)}
          </MenuItem>
          <MenuItem
            onClick={() => {
              selectPeriod('during');
            }}
          >
            {t(`Startpage_Period.During`)} {format(new Date(), 'yyyy')}
          </MenuItem>
        </MenuList>
      </Menu>
    </LoggingErrorBoundary>
  );
};
export default PeriodSelect;
