import { Box, Button, Link, Stack, styled, Typography } from '@mui/material';

const SettingsInfo = styled(Box)`
  margin-top: 1rem;
  background-color: #eee;
  padding: 0.6rem;
  border-radius: 3px;
`;

const CreateNewsInfo = () => {
  return (
    <SettingsInfo>
      <Stack direction="row" justifyContent="space-between">
        <Stack spacing={0.5}>
          <Typography variant="h3">Nyheter</Typography>
          <Typography variant="body2">Olästa nyheter visas i en pop up på sidan</Typography>
        </Stack>
        <Link href="../Admin/NyheterNy.aspx" target="new" underline="none">
          <Button variant="outlined" color="primary" sx={{ background: '#fff', flex: 'flex-end' }}>
            Skapa nyhet
          </Button>
        </Link>
      </Stack>
    </SettingsInfo>
  );
};

export default CreateNewsInfo;
