import { Grid, Typography } from '@mui/material';
import { useGetLinkContentDataFromserver } from 'sections/newmyia/backendQueries/useGetData';
import { ContentLevel } from 'shared/types/enums';
import CustomChart from '../../CustomCharts/CustomChart';
import { useTranslations } from 'shared/hooks/useTranslations';
import { LoggingErrorBoundary } from 'shared/error/LoggingErrorBoundary';
import { useGlobalContext } from 'globalContext';

interface Props {
  containerWidth: number;
  width?: number;
}

const Organization = ({ containerWidth, width }: Props) => {
  const { t } = useTranslations();
  const { activeContentOrg, activeContentOrgCount } = useGetLinkContentDataFromserver();
  const { companyId, companyName } = useGlobalContext();

  const GetWidth = () => {
    if (width) return width;
    return 12 / activeContentOrgCount ?? 1;
  };

  const defaultLevelOrg = {
    type: ContentLevel.Organization,
    typeLabel: 'Organisation',
    id: Number(companyId),
    name: companyName,
  };

  return (
    <LoggingErrorBoundary fallback={<Typography>{t('General_ExceptionHeader')}</Typography>}>
      <Grid item container xs={containerWidth}>
        {/* <SectionTitle title={sectionTitle} /> */}
        <Grid item container spacing={2} xs={12}>
          {/*  {hasNews && (
            <Grid item xs={GetXsContent()}>
              <News level={NewsLevel.Organization} />
            </Grid>
          )} */}
          {activeContentOrg.map((content) => (
            <Grid item xs={GetWidth()} key={content.Id}>
              <CustomChart linkId={content.Id} level={defaultLevelOrg} forTopSection={true} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </LoggingErrorBoundary>
  );
};

export default Organization;
