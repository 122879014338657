import { Grid } from '@mui/material';
import { useTranslations } from 'shared/hooks/useTranslations';
import News from 'sections/startpage/News';
import { NewsLevel } from 'shared/types/enums';
import SectionTitle from './SectionTitle';

interface Props {
  xs: number;
}

const IANews = ({ xs }: Props) => {
  const { t } = useTranslations();
  const sectionTitle = t('Startpage_News.AFA.Headertext');
  return (
    <Grid item xs={xs}>
      <SectionTitle title={sectionTitle} />
      <News level={NewsLevel.Afa} />
    </Grid>
  );
};

export default IANews;
