import { Button } from '@mui/material';
import { LIST_TAKE_LIMIT } from 'shared/startpageService/common/constants';

interface Props {
  count: number;
  toggle: () => void;
  isSelected?: boolean;
  text: string;
  showCount: boolean;
}

const MainFilterButton = ({ count, toggle, isSelected, text, showCount }: Props) => {
  if (!count) return null;
  const displayCount = count > LIST_TAKE_LIMIT ? `( > ${LIST_TAKE_LIMIT} )` : `(${count})`;

  return (
    <>
      {count > 0 && (
        <Button
          variant={'text'}
          onClick={toggle}
          sx={{
            borderBottom: isSelected ? 5 : 0,
            color: isSelected ? 'primary' : 'inherit',
          }}
        >
          {`${text} ${showCount ? displayCount : ''}`}
        </Button>
      )}
    </>
  );
};

export default MainFilterButton;
