export const LIST_TAKE_LIMIT = 100;
export const STATUS = {
  OCCURRENCE: {
    REPORTED: 1,
    REGISTRATION: 2,
    INVESTIGATION: 3,
    INVESTIGATION_COMPLETED: 4,
    ACTION: 8,
    COMPLETED_NO_FOLLOW_UP: 5,
    COMPLETED_NOT_APPROVED: 7,
    COMPLETED: 6,
  },
  RISKMANAGEMENT: {
    PLANNED: 1,
    PERFORMED: 3,
    COMPLETED: 6,
  },
  ACTION: {
    PLANNED: 1,
    OVERDUE: 2,
    COMPLETED: 3,
  },
  INVESTIGATION: {
    PLANNED: 1,
    OVERDUE: 2,
    COMPLETED: 3,
  },
  FOLLOW_UP: {
    PLANNED: 1,
    OVERDUE: 2,
    COMPLETED: 3,
  },
};
