import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { StyledTopBarItem } from './TopBar';
import { useGetAllUnreadNews, useHasUnreadNews } from '../News/newsQueries';
import { useTranslations } from 'shared/hooks/useTranslations';
import NewsDialog from '../News/NewsDialog';
import { Link } from '@mui/material';

const NewsMenuItem = () => {
  const { t } = useTranslations();
  const { unreadNewsItems } = useGetAllUnreadNews();
  const { setIdAsRead } = useHasUnreadNews();

  const handleCloseNews = (id: number) => {
    setIdAsRead(id);
  };

  return (
    <>
      <Link href="/PreventionIA/IA/Pages/Admin/NyheterLista.aspx" color={'inherit'} underline="none">
        <StyledTopBarItem variant="body2">
          <NotificationsNoneIcon />
          {t('News_LinkText')}
        </StyledTopBarItem>
      </Link>
      {unreadNewsItems.map((item) => (
        <NewsDialog
          key={item.id}
          currentNewsItem={item}
          onClose={() => handleCloseNews(item.id)}
          newsLevel={item.level}
        />
      ))}
    </>
  );
};

export default NewsMenuItem;
