import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import { INewsItem, useHasUnreadNews } from './newsQueries';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTranslations } from '../../../shared/hooks/useTranslations';
import DialogActions from '@mui/material/DialogActions';
import { useDatefnsLocale } from '../../../shared/hooks/useDatefnsLocale';
import { useGlobalContext } from '../../../globalContext';
import { useFormatDate } from '../../../shared/utils/formatDateWithLocale';
import { NewsLevel } from 'shared/types/enums';
import { Stack } from '@mui/material';
import FlaggedOccurrenceLabel from './FlaggedOccurrenceLabel';

const StyledDialog = styled(Paper)`
  padding: 1.5rem;
  font-family: ${({ theme }) => theme.typography.fontFamily};
`;

const FlaggedOccurrencePanel = styled('div')`
  background-color: ${({ theme }) => theme.palette.grey['50']};
  padding: 1rem 0.5rem;
`;

interface Props {
  onClose: () => void;
  currentNewsItem?: INewsItem;
  newsLevel: NewsLevel;
}
const NewsDialog = ({ currentNewsItem, onClose, newsLevel }: Props) => {
  const dateLocale = useDatefnsLocale();
  const { t } = useTranslations();
  const { formatDate } = useFormatDate();
  const {
    title: Title,
    text: Text,
    publishedDate: PublishedDate,
    occurrenceId: OccurrenceId,
    id: Id,
    occurrenceNumber: OccurrenceNumber,
  } = currentNewsItem ?? {};
  const flaggedOccurrence = OccurrenceId !== 0;
  const { setIdAsRead } = useHasUnreadNews();
  const { companyId, companyName, industryId } = useGlobalContext();
  if (Id) {
    setIdAsRead(Id);
  }
  const companyNameParam = newsLevel !== NewsLevel.Sector ? companyName ?? '' : '-';
  return (
    <Dialog open={Boolean(currentNewsItem)} onClose={onClose}>
      <StyledDialog elevation={0}>
        <Typography variant="body2">
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            {flaggedOccurrence && <FlaggedOccurrenceLabel />}
            {formatDate(PublishedDate, dateLocale)}
          </Stack>
        </Typography>
        <Typography variant="h5">{Title}</Typography>
        <div dangerouslySetInnerHTML={{ __html: Text ?? '' }}></div>
        {flaggedOccurrence && (
          <FlaggedOccurrencePanel>
            <Typography variant="subtitle1">{t('News_ReadMore')}</Typography>
            <Typography variant="body2" sx={{ marginBottom: 1.5 }}>
              {t('News_InformationMightBeHidden')}
            </Typography>
            <Button
              color="primary"
              variant="contained"
              size="large"
              href={`/PreventionIA/IA/Pages/Reports/MyReports.aspx?Mode=News&id=${OccurrenceId ?? ''}&number=${
                OccurrenceNumber ?? ''
              }&level=${industryId ?? ''}&orgId=${companyId ?? ''}&orgName=${companyNameParam}`}
            >
              {t('News_ToOccurrence')}
            </Button>
          </FlaggedOccurrencePanel>
        )}
      </StyledDialog>
      <DialogActions>
        <Button onClick={onClose}>{t('General_Close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewsDialog;
