import { Grid } from '@mui/material';
import { ContentLevel } from 'shared/types/enums';
import CustomChart from '../../CustomCharts/CustomChart';
import { LoggingErrorBoundary } from 'shared/error/LoggingErrorBoundary';
import { useUserSettings } from 'sections/newmyia/Settings/useUserSettings';
import { useGlobalContext } from 'globalContext';
import { useGetMainMenu } from 'sections/newmyia/IaAppBar/navbarQueries';
import { useGetLinkContentDataFromserver } from 'sections/newmyia/backendQueries/useGetData';
import { PeriodUnit } from 'shared/startpageService/common/enums';

interface Props {
  xs: number;
}

const User = ({ xs }: Props) => {
  const { activeWidgets } = useUserSettings();
  const { allContentUser } = useGetLinkContentDataFromserver();
  const { userId } = useGlobalContext();
  const { data } = useGetMainMenu();
  const userName = data?.UserName;

  const contentToshow = activeWidgets?.filter((a) => allContentUser.map((b) => b.Id).includes(a.linkId));

  const defaultLevelUser = {
    type: ContentLevel.User,
    typeLabel: 'Mina',
    id: Number(userId),
    name: userName,
  };

  const defaultPeriodUser = {
    label: '14 dagar',
    unit: PeriodUnit.Day,
    quantity: 14,
  };

  return (
    <LoggingErrorBoundary>
      {contentToshow?.map((widget) => (
        <Grid key={widget.linkId} item xs={widget.width} sx={{ alignItems: 'flex-start' }}>
          <CustomChart
            linkId={widget.linkId}
            forTopSection={false}
            level={widget.level ?? defaultLevelUser}
            period={widget.period ?? defaultPeriodUser}
          />
        </Grid>
      ))}
    </LoggingErrorBoundary>
  );
};

export default User;
