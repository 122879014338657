import { useState } from 'react';
import { INewsItem, useGetNewsForLevel, useHasUnreadNews } from './newsQueries';
import { useTranslations } from '../../../shared/hooks/useTranslations';
import { Link, Skeleton, Typography } from '@mui/material';
import { useDatefnsLocale } from '../../../shared/hooks/useDatefnsLocale';
import WidgetBox from '../../../shared/UI/WidgetBox';
import { styled } from '@mui/material/styles';
import NewsDialog from './NewsDialog';
import { NewsLevel } from 'shared/types/enums';
import { useFormatDate } from 'shared/utils/formatDateWithLocale';
import FlaggedOccurrenceLabel from './FlaggedOccurrenceLabel';
import { LoggingErrorBoundary } from 'shared/error/LoggingErrorBoundary';

export const NewsItemSkeleton = () => {
  return (
    <>
      <Skeleton width="80%" />
      <Skeleton variant="rectangular" height={40} />
    </>
  );
};

const StyledNewsItem = styled('div')`
  margin-bottom: 0.75rem;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
  position: relative;
  .unread-dot {
    position: absolute;
    left: -0.5rem;
    top: 50%;
  }
`;

const StyledNewsText = styled(Typography)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const NewsTitle = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
`;

interface Props {
  level: NewsLevel;
}

const NewsSmall = ({ level }: Props) => {
  const { unreadIds } = useHasUnreadNews();
  const [currentNewsItem, setCurrentNewsItem] = useState<INewsItem>();
  const { t } = useTranslations();
  const { formatDate } = useFormatDate();
  const { data: newsItems, isLoading } = useGetNewsForLevel(level);

  const dateLocale = useDatefnsLocale();

  return (
    <LoggingErrorBoundary>
      <WidgetBox>
        {isLoading && (
          <>
            <NewsItemSkeleton />
            <NewsItemSkeleton />
            <NewsItemSkeleton />
          </>
        )}
        {newsItems?.map((item) => {
          const doc = new DOMParser().parseFromString(item?.text ?? '', 'text/html');
          const isUnread = unreadIds?.includes(item.id);
          return (
            <StyledNewsItem key={item.id} onClick={() => setCurrentNewsItem(item)} className="news-item">
              {/* {isUnread && <UnreadDot />} */}
              {isUnread && <></>}
              <NewsTitle variant="subtitle2">
                {item?.occurrenceId !== 0 && <FlaggedOccurrenceLabel />}
                <Typography variant="caption" sx={{ fontWeight: 400 }} display="inline">
                  {formatDate(item?.publishedDate, dateLocale)}{' '}
                </Typography>
                <Typography variant="caption" sx={{ ml: 0.5 }} display="inline">
                  {item?.title}
                </Typography>
              </NewsTitle>
              <StyledNewsText variant="body2">{doc.body.textContent}</StyledNewsText>
            </StyledNewsItem>
          );
        })}
        {!isLoading && newsItems?.length === 0 && (
          <Typography>
            {t('News.NoNewsToDisplay')}{' '}
            <Link href="/PreventionIA/IA/Pages/Admin/NyheterLista.aspx">{t('News.SeeOlderNews')}</Link>
          </Typography>
        )}
        {Boolean(currentNewsItem) && (
          <NewsDialog
            currentNewsItem={currentNewsItem}
            onClose={() => setCurrentNewsItem(undefined)}
            newsLevel={level}
          />
        )}
      </WidgetBox>
    </LoggingErrorBoundary>
  );
};

export default NewsSmall;
