import { Count, IActivityItem, TodoItem } from 'shared/types/types';
import { TodoType } from 'shared/types/enums';

export enum SortOrder {
  ASC = 1,
  DESC = -1,
}

export enum SortType {
  Title = 1,
  Todo,
  Type,
  Number,
  Date,
  DateType,
  RiskValue,
  Overdue,
}

export interface SortOption {
  type: SortType;
  label: string;
}

export const sortItems = (
  items: TodoItem[],
  todoTypesLabels: Record<TodoType, string>,
  sortType?: SortType,
  sortOrder?: SortOrder
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  const order = sortOrder !== undefined ? sortOrder : 1;

  const sortOverdue = (items: TodoItem[]): TodoItem[] => {
    const sortOnDate = (a: TodoItem, b: TodoItem) => {
      if (a.isOverdue < b.isOverdue || a.date < b.date) return -1 * order;
      if (a.isOverdue > b.isOverdue || a.date > b.date) return 1 * order;
      return 0;
    };
    const overdueItems = items.filter((item) => item.isOverdue).sort(sortOnDate);
    const otherItems = items.filter((item) => !item.isOverdue).sort(sortOnDate);

    return [...overdueItems, ...otherItems];
  };

  switch (sortType) {
    case SortType.Title:
      return items.sort((a, b) => {
        if (!a.title ?? !b.title) return 0;
        if (a.title < b.title) return -1 * order;
        if (a.title > b.title) return 1 * order;
        return 0;
      });
    case SortType.Todo:
      return items.sort((a, b) => {
        if (todoTypesLabels[a.todoType] < todoTypesLabels[b.todoType]) return -1 * order;
        if (todoTypesLabels[a.todoType] > todoTypesLabels[b.todoType]) return 1 * order;
        return 0;
      });
    case SortType.Date:
      return items.sort((a, b) => {
        if (a.date < b.date) return -1 * order;
        if (a.date > b.date) return 1 * order;
        return 0;
      });
    case SortType.Overdue:
      return sortOverdue(items);
    case SortType.Number:
      return items.sort((a, b) => {
        const yearPartA = Number(a.itemNumber.substring(0, 3));
        const yearPartB = Number(b.itemNumber.substring(0, 3));
        const nrPartA = Number(a.itemNumber.substring(5));
        const nrPartB = Number(b.itemNumber.substring(5));
        if (yearPartA - yearPartB || nrPartA - nrPartB) return -1 * order;
        if (yearPartB - yearPartA || nrPartB - nrPartA) return 1 * order;
        return 0;
      });
    case SortType.DateType:
      return items.sort((a, b) => {
        if (a.dateTitle < b.dateTitle) return -1 * order;
        if (a.dateTitle > b.dateTitle) return 1 * order;
        return 0;
      });
    case SortType.RiskValue:
      return items.sort((a, b) => {
        const riskValueA =
          a.riskAssessment &&
          !a.riskAssessment.isAfter &&
          a.riskAssessment.riskAssessments &&
          a.riskAssessment.riskAssessments.length > 0
            ? a.riskAssessment.riskAssessments[0].value
            : 0;
        const riskValueB =
          b.riskAssessment &&
          !b.riskAssessment.isAfter &&
          b.riskAssessment.riskAssessments &&
          b.riskAssessment.riskAssessments.length > 0
            ? b.riskAssessment.riskAssessments[0].value
            : 0;
        if (riskValueA < riskValueB) return -1 * order;
        if (riskValueA > riskValueB) return 1 * order;
        return 0;
      });
    case SortType.Type:
      return items.sort((a, b) => {
        if (a.itemType < b.itemType) return -1 * order;
        if (a.itemType > b.itemType) return 1 * order;
        return 0;
      });
    default:
      return items;
  }
};

export const filterOnlyMine = (onlyMine: boolean) => (activity: IActivityItem) => {
  if (onlyMine) {
    return (
      activity.actors?.some((actor) => actor.isMe) ??
      activity.subActivites?.some((item) => item.actors?.some((actor) => actor.isMe))
    );
  }
  return true;
};

export type Counts = Partial<Record<TodoType, Count>>;
export const getCounts = (items: TodoItem[], todoTypesLabels: Record<TodoType, string>) => {
  const types = items.reduce((acc: Counts, i) => {
    if (!acc[i.todoType]) {
      acc[i.todoType] = {
        count: 0,
        labelKey: todoTypesLabels[i.todoType],
      };
    }
    return {
      ...acc,
      [i.todoType]: {
        count: Number(acc[i.todoType]?.count ?? 0) + 1,
        labelKey: todoTypesLabels[i.todoType],
      },
    };
  }, {});

  return {
    ...types,
    [TodoType.ALL]: {
      count: items.length,
      labelKey: 'Alla',
    },
  };
};
