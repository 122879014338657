import User from './Sections/User';
import { Grid, Stack, styled, useMediaQuery, useTheme } from '@mui/material';
import Organization from './Sections/Organization';
import Settings from '../Settings';
import { useGetLinkContentDataFromserver } from 'shared/startpageService/charts';
import Sector from './Sections/Sector';
import IANews from './Sections/IANews';
import MyIA from '../MyIA/MyIA';

const PageContent = styled('div')`
  padding: 0 5.55%;
`;

const WidgetGrid = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { hasOrgContent, hasSectorContent, hasUserContent, countOrgContentInclNews } =
    useGetLinkContentDataFromserver();
  const hasTopSection = hasOrgContent;
  const maxGridCount = 12;
  const maxVisibleOrgContentCount = 4;
  const maxVisibleSectorContentCount = 1;
  const maxVisibleIANewsContentCount = 1;
  const rightSectionXsCount = hasOrgContent && !hasUserContent ? 0 : 3;
  const visibleOrgContentCount: number =
    countOrgContentInclNews > maxVisibleOrgContentCount ? maxVisibleOrgContentCount : countOrgContentInclNews;
  const totalCountColumnsSectionTop =
    visibleOrgContentCount + maxVisibleIANewsContentCount + (hasSectorContent ? maxVisibleSectorContentCount : 0);

  const GetContainerXsOrg = () => {
    return (visibleOrgContentCount / totalCountColumnsSectionTop) * maxGridCount;
  };

  const GetContainerXsSector = () => {
    return maxGridCount / totalCountColumnsSectionTop;
  };

  const GetContainerXsIANews = () => {
    return maxGridCount / totalCountColumnsSectionTop;
  };

  const GetContainerXsMyIA = () => {
    return maxGridCount - rightSectionXsCount;
  };

  return (
    <PageContent>
      {isDesktop && (
        <>
          <Settings />
          {hasTopSection && (
            <>
              <Grid container spacing={2} sx={{ mt: 0.5 }}>
                <Organization xs={GetContainerXsOrg()} />
                {hasSectorContent && <Sector xs={GetContainerXsSector()} />}
                <IANews xs={GetContainerXsIANews()} />
              </Grid>
              <Grid container spacing={2} sx={{ mt: 6 }}>
                <MyIA xs={GetContainerXsMyIA()} />
                {hasUserContent && <User xs={rightSectionXsCount} />}
              </Grid>
            </>
          )}
          {!hasTopSection && (
            <Grid container spacing={2}>
              <MyIA xs={GetContainerXsMyIA()} />
              <Grid item xs={rightSectionXsCount}>
                <Stack spacing={4}>
                  <Grid container spacing={2}>
                    {hasSectorContent && <Sector xs={12} />}
                    {<IANews xs={12} />}
                  </Grid>
                  {hasUserContent && <User xs={12} />}
                </Stack>
              </Grid>
            </Grid>
          )}
        </>
      )}
      {!isDesktop && (
        <>
          <Settings />
          <Grid container spacing={6} sx={{ mt: 0.5 }}>
            <MyIA xs={12} />
            {hasUserContent && <User xs={12} />}
            {hasOrgContent && <Organization xs={12} xsContent={12} />}
            {hasSectorContent && <Sector xs={12} />}
            <IANews xs={12} />
          </Grid>
        </>
      )}
    </PageContent>
  );
};

export default WidgetGrid;
