import { format } from 'date-fns';
import { PeriodType, UnitType } from './enums';
import { RequestTypes, requestTypes } from './requestTypes';
import { IDetailsViewModel, IMyReportRequest, IMyReportsWidget } from './types';
import { useGetPeriod } from 'shared/hooks/useGetPeriod';
import { useGlobalContext } from 'globalContext';
import { useGetConfig } from '../settings';
import { ContentLevel, DetailType } from 'shared/types/enums';

const getFilterDetails = (filterDetails: IDetailsViewModel[]) => {
  const USER_DEFINED = 'userDefined-';

  // Set type of user defined fields to enum to match backend
  for (const item of filterDetails) {
    if (!item.fieldId) {
      item.fieldId = item.type.includes(USER_DEFINED) ? parseInt(item.type.split(USER_DEFINED)[1], 10) : 0;
    }
    item.type = item.type.includes(USER_DEFINED) ? DetailType.USER_DEFINED : item.type;
  }
  return filterDetails;
};

const getLevel = (selectedFilter: IMyReportsWidget, level?: ContentLevel) => {
  if (!level) return ContentLevel.User;
  return level === ContentLevel.Organization ? selectedFilter.request.filter?.base.level?.type ?? level : level;
};

export const useGetFilterObject = (
  filterType: RequestTypes = RequestTypes.CustomChart,
  customChartSettings?: IMyReportsWidget,
  level?: ContentLevel
) => {
  const { startDate: startDateFromSettings, endDate: endDateFromSettings } = useGetPeriod();
  const { config } = useGetConfig();
  const { userId, companyId, industryId, unitId } = useGlobalContext();

  const selectedFilter = customChartSettings ?? requestTypes[filterType];

  const startDate = selectedFilter.settings?.usePeriod ? startDateFromSettings : '1970-01-01';
  const endDate = selectedFilter.settings?.usePeriod ? endDateFromSettings : '2099-12-31';

  const getLevelId = (level?: ContentLevel) => {
    switch (level) {
      case ContentLevel.Sector:
        return Number(industryId);
      case ContentLevel.Organization:
        return selectedFilter.request.filter?.base.level?.id ?? Number(companyId);
      case ContentLevel.Unit:
        return Number(unitId);
      default:
        return Number(userId);
    }
  };

  const filterObject: IMyReportRequest = {
    isAnalysisMode: selectedFilter.request.isAnalysisMode ?? false,
    filter: {
      base: {
        content: selectedFilter.request.filter?.base.content,
        level: {
          type: getLevel(selectedFilter, level ?? config?.contentLevel),
          id: getLevelId(level ?? config?.contentLevel),
          unitTypes: [UnitType.Occurrence, UnitType.Employment],
          includeSubunits: selectedFilter.request.filter?.base.level?.includeSubunits,
          orgId: selectedFilter.request.filter?.base.level?.orgId,
        },
        period: {
          customMode: true,
          fixedMode: false,
          startDate: format(new Date(startDate), 'yyyy-MM-dd'),
          endDate: format(new Date(endDate), 'yyyy-MM-dd'),
          reportCulture: 'sv-SE',
          type: selectedFilter.request.filter?.base.period?.type ?? PeriodType.Occurred,
        },
      },
      details: getFilterDetails(selectedFilter.request.filter?.details ?? []),
    },
    details: selectedFilter.request.details,
    list: selectedFilter.request.list,
    mainChart: selectedFilter.request.mainChart,
  };

  return filterObject;
};
