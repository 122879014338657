import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartMore from 'highcharts/highcharts-more';
import HighchartsAccesibillity from 'highcharts/modules/accessibility';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import { getGaugeOptions } from './gaugeChartOptions';
import { getCustomRequest } from '../customRequest';
import { DetailType } from 'shared/types/enums';
import { RequestTypes } from 'shared/startpageService/common/requestTypes';
import { useGetMyReportsData } from 'sections/newmyia/backendQueries/myReportsQuery';
import { IDistribution, IMyReportRequest, IMyReportsWidget } from 'shared/startpageService/common/types';

HighchartMore(Highcharts);
HighchartsAccesibillity(Highcharts);
HighchartsSolidGauge(Highcharts);
interface Props {
  settings: IMyReportRequest;
  distribution: IDistribution;
  serieDetailType: DetailType;
}

export const GaugeChart = ({ settings, distribution, serieDetailType }: Props) => {
  const widget = getCustomRequest(settings.filter, distribution.type, serieDetailType);
  const widgetTotal: IMyReportsWidget = {
    request: {
      filter: {
        base: settings.filter.base,
        details: [],
      },
    },
    settings: {
      usePeriod: true,
    },
  };

  const { data: dataArray } = useGetMyReportsData(RequestTypes.CustomChart, widget, {
    enabled: Boolean(widget),
  });
  const { data: dataTotal } = useGetMyReportsData(RequestTypes.CustomTotalCount, widgetTotal, {
    enabled: Boolean(widget),
  });

  if (!distribution) return null;

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={getGaugeOptions(dataArray?.[0], distribution.chartColor, dataTotal?.totalCount)}
      containerProps={{ style: { height: '200px' } }}
    />
  );
};

export default GaugeChart;
