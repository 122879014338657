import { Avatar } from '@mui/material';
import { useGetMainMenu } from '../../IaAppBar/navbarQueries';
import { styled } from '@mui/material/styles';

export const InitialsAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.palette.warning.light};
  width: 1.5rem;
  height: 1.5rem;
  color: ${({ theme }) => theme.palette.common.black};
  font-size: 0.5rem;
  margin-right: 0.5rem;
`;

const CurrentUserAvatar = () => {
  const { data } = useGetMainMenu();
  const userName = data?.UserName;
  const userInitials = userName ? userName.match(/\b\w/g)?.join('') : '';
  return <InitialsAvatar>{userInitials}</InitialsAvatar>;
};

export default CurrentUserAvatar;
