import { formatDate, getDateFollowUpOther, isOverdue, isRiskManagement } from '../common/utils';
import { Activity, Func, State } from 'shared/types/enums';
import { Actor, IActivityItem, IRiskAssessmentItem } from 'shared/types/types';
import { IStartpageListResponseItem } from '../common/responseTypes';
import { getLink } from '../common/links';
import { STATUS } from '../common/constants';

interface DetailViewItem {
  itemType: string;
  nr: string;
  link: string;
  timeOccurred: string;
  activities: IActivityItem[];
  organization: string;
}

const getState = (itemStatus: number | null, compareStatus: number[]) => {
  if (itemStatus === null) return State.NOT_DONE;

  if (itemStatus > compareStatus[0]) {
    return State.DONE;
  } else if (compareStatus.includes(itemStatus)) {
    return State.CURRENT;
  }
  return State.NOT_DONE;
};

const getStateFromExactStatus = (itemStatus: number | null, doneStatuses: number[], currentStatuses?: number[]) => {
  if (itemStatus === null) return State.NOT_DONE;

  if (doneStatuses.includes(itemStatus)) {
    return State.DONE;
  } else if (currentStatuses?.includes(itemStatus)) {
    return State.CURRENT;
  }
  return State.NOT_DONE;
};

const getActors = (actors: Actor[]): Actor[] => {
  return actors.filter((actor) => actor.name !== undefined && actor.name !== null);
};

const getActivitiesOccurrence = (item: IStartpageListResponseItem, userId: number): IActivityItem[] => {
  return [
    {
      id: `${Activity.REPORTING}`,
      activity: Activity.REPORTING,
      state: getState(item.status.id, [STATUS.OCCURRENCE.REPORTED]),
      date: formatDate(item.timeReported),
      isOverdue: isOverdue(item.timeReported, getState(item.status.id, [STATUS.OCCURRENCE.REPORTED])),
      actors: getActors([
        {
          name: item.responsible.name,
          function: Func.RESPONSIBLE_OCCURRENCE,
          isMe: userId === item.responsible.id,
        },
        {
          name: item.responsibleOhs?.name ?? null,
          function: Func.OHS_CONTACTED,
          isMe: userId === item.responsibleOhs?.id,
        },
      ]),
      riskAssessment: {
        riskAssessments:
          item.riskBeforeLevel && item.riskBeforeAction
            ? [
                {
                  level: item.riskBeforeLevel,
                  count: item.riskBeforeLevel !== null ? 1 : 0,
                  value: item.riskBeforeAction,
                },
              ]
            : [],
        isAfter: false,
      },

      title: item.occurrenceTitle,
      text: item.occurrenceDescription,
    },
    {
      id: `${Activity.INVESTIGATION}`,
      activity: Activity.INVESTIGATION,
      state: getState(item.status.id, [STATUS.OCCURRENCE.INVESTIGATION]),
      date: formatDate(item.investigationTimeCompleted),
      isOverdue: isOverdue(
        item.investigationTimeCompleted,
        getState(item.status.id, [STATUS.OCCURRENCE.INVESTIGATION])
      ),
      actors: getActors([
        {
          name: item.investigator?.name ?? null,
          function: Func.INVESTIGATOR,
          isMe: userId === item.investigator?.id,
        },
        ...(item.investigationParticipants?.map(({ name, id }) => ({
          name,
          function: Func.PARTICIPANT,
          isMe: userId === id,
        })) ?? []),
      ]),
      title: item.investigationTitle,
      text: item.investigationDescription,
    },
    {
      id: `${Activity.ACTION_HANDLING}`,
      activity: Activity.ACTION_HANDLING,
      state: getState(item.status.id, [STATUS.OCCURRENCE.INVESTIGATION_COMPLETED, STATUS.OCCURRENCE.ACTION]),
      totalCount: item.actions?.length,
      completedCount: item.actions?.filter((action) => action.actionStatus.id === STATUS.ACTION.COMPLETED).length,
      subActivites: item.actions?.map((action) => ({
        id: `${Activity.ACTION}-${action.actionId}`,
        activity: Activity.ACTION,
        date: formatDate(action.actionTimeCompleted),
        state: getStateFromExactStatus(action.actionStatus.id, [STATUS.ACTION.COMPLETED], [STATUS.ACTION.PLANNED]),
        isOverdue: isOverdue(
          action.actionTimeCompleted,
          getStateFromExactStatus(action.actionStatus.id, [STATUS.ACTION.COMPLETED], [STATUS.ACTION.PLANNED])
        ),
        actors: getActors([
          {
            name: action.actionOwner.name,
            function: Func.ACTON_OWNER,
            isMe: userId === action.actionOwner.id,
          },
        ]),
        title: action.actionTitle,
        text: action.actionDescription,
      })),
    },
    {
      id: `${Activity.FOLLOW_UP}`,
      activity: Activity.FOLLOW_UP,
      state: getStateFromExactStatus(
        item.status.id,
        [STATUS.OCCURRENCE.COMPLETED_NOT_APPROVED, STATUS.OCCURRENCE.COMPLETED],
        [STATUS.OCCURRENCE.COMPLETED_NO_FOLLOW_UP]
      ),
      date: formatDate(item.followUpTimeCompleted),
      isOverdue: isOverdue(
        item.followUpTimeCompleted,
        getStateFromExactStatus(
          item.status.id,
          [STATUS.OCCURRENCE.COMPLETED_NOT_APPROVED, STATUS.OCCURRENCE.COMPLETED],
          [STATUS.OCCURRENCE.COMPLETED_NO_FOLLOW_UP]
        )
      ),
      actors: getActors([
        {
          name: item.followUpResponsible?.name,
          function: Func.FOLLOW_UP_RESPONSIBLE,
          isMe: userId === item.followUpResponsible?.id,
        },
      ]),
      riskAssessment: {
        riskAssessments:
          item.riskAfterLevel && item.riskAfterAction
            ? [
                {
                  level: item.riskAfterLevel,
                  count: item.riskAfterLevel !== null ? 1 : 0,
                  value: item.riskAfterAction,
                },
              ]
            : [],
        isAfter: true,
      },
    },
    {
      id: `${Activity.FOLLOW_UP_OTHER}`,
      activity: Activity.FOLLOW_UP_OTHER,
      state: getStateFromExactStatus(
        item.status.id,
        [STATUS.OCCURRENCE.COMPLETED],
        [STATUS.OCCURRENCE.COMPLETED_NOT_APPROVED]
      ),
      date: formatDate(getDateFollowUpOther(item)),
      isOverdue: isOverdue(
        getDateFollowUpOther(item),
        getStateFromExactStatus(
          item.status.id,
          [STATUS.OCCURRENCE.COMPLETED],
          [STATUS.OCCURRENCE.COMPLETED_NOT_APPROVED]
        )
      ),
      actors: getActors([
        {
          name: item.followUpOtherResponsible?.name ?? null,
          function: Func.FOLLOW_UP_OTHER_RESPONSIBLE,
          isMe: userId === item.followUpOtherResponsible?.id,
        },
      ]),
    },
    {
      id: `${Activity.COMPLETE}`,
      activity: Activity.COMPLETE,
      state: State.NOT_DONE,
    },
  ];
};

export const sortRisks = (a: IRiskAssessmentItem, b: IRiskAssessmentItem) => b.level - a.level;

const getActivitiesRiskManagement = (item: IStartpageListResponseItem, userId: number): IActivityItem[] => {
  return [
    {
      id: `${Activity.PLANNING}`,
      activity: Activity.PLANNING,
      state: State.DONE,
      date: formatDate(item.timeOccurred),
      isOverdue: isOverdue(
        item.timeOccurred,
        getStateFromExactStatus(item.status.id, [STATUS.RISKMANAGEMENT.PERFORMED])
      ),
      actors: getActors([
        {
          name: item.responsible.name,
          function: Func.RESPONSIBLE,
          isMe: userId === item.responsible.id,
        },
      ]),
      title: item.title?.name,
      object: item.object?.name ?? undefined,
      template: item.template?.name ?? undefined,
    },
    {
      id: `${Activity.IMPLEMENT}`,
      activity: Activity.IMPLEMENT,
      state: getStateFromExactStatus(
        item.status.id,
        [STATUS.RISKMANAGEMENT.PERFORMED],
        [STATUS.RISKMANAGEMENT.PLANNED]
      ),
      actors: getActors(
        item.participants?.map(({ name, id }) => ({
          name,
          function: Func.PARTICIPANT,
          isMe: userId === id,
        })) ?? []
      ),
      // completedCount: item.okFalse?.[0]?.count + item.okTrue?.[0]?.count, // Also add N/A-count when available
      totalCount: item.riskAfterActionRM?.reduce((acc, curr) => Number(acc) + Number(curr.count), 0),
      rowsNoCount: item.okFalse?.[0]?.count,
      rowsYesCount: item.okTrue?.[0]?.count,
      riskAssessment: {
        riskAssessments: item.riskBeforeActionRM
          ?.filter((risk) => risk.id !== null)
          .map(({ value, count, id }) => ({
            value,
            count,
            level: id as number, // id has been checked for null already
          }))
          .sort(sortRisks),
        isAfter: false,
      },
      isPerformed: item.status.id !== STATUS.RISKMANAGEMENT.PLANNED,
    },
    {
      id: `${Activity.ACTION_HANDLING}`,
      activity: Activity.ACTION_HANDLING,
      state: State.NOT_DONE,
      totalCount: item.actions?.length,
      completedCount: item.actions?.filter((action) => action.actionStatus.id === STATUS.ACTION.COMPLETED).length,
      subActivites: item.actions?.map((action) => ({
        id: `${Activity.ACTION}-${action.actionId}`,
        activity: Activity.ACTION,
        date: formatDate(action.actionTimeCompleted),
        state: getStateFromExactStatus(action.actionStatus.id, [STATUS.ACTION.COMPLETED], [STATUS.ACTION.PLANNED]),
        isOverdue: isOverdue(
          action.actionTimeCompleted,
          getStateFromExactStatus(action.actionStatus.id, [STATUS.ACTION.COMPLETED], [STATUS.ACTION.PLANNED])
        ),
        actors: getActors([
          {
            name: action.actionOwner.name,
            function: Func.ACTON_OWNER,
            isMe: userId === action.actionOwner.id,
          },
        ]),
        title: action.actionTitle,
        text: action.actionDescription,
      })),
    },
    {
      id: `${Activity.FOLLOW_UP}`,
      activity: Activity.FOLLOW_UP,
      state: State.NOT_DONE,
      date: formatDate(item.followUpTimeCompleted),
      isOverdue: isOverdue(item.followUpTimeCompleted),
      actors: getActors([
        {
          name: item.followUpResponsible?.name,
          function: Func.FOLLOW_UP_RESPONSIBLE,
          isMe: userId === item.followUpResponsible?.id,
        },
      ]),
      riskAssessment: {
        riskAssessments: item.riskAfterActionRM
          ?.filter((risk) => risk.id !== null)
          .map(({ value, count, id }) => ({
            value,
            count,
            level: id as number, // id has been checked for null already
          }))
          .sort(sortRisks),
        isAfter: true,
      },
    },
    {
      id: `${Activity.COMPLETE}`,
      activity: Activity.COMPLETE,
      state: State.NOT_DONE,
    },
  ];
};

const getActivities = (item: IStartpageListResponseItem, userId: number): IActivityItem[] => {
  if (isRiskManagement(item)) {
    return getActivitiesRiskManagement(item, userId);
  }
  return getActivitiesOccurrence(item, userId);
};

export const getAllDetailViewInfo = (userId?: number, item?: IStartpageListResponseItem): DetailViewItem | null => {
  if (!item || !userId) return null;
  return {
    itemType: item.itemType.name ?? '',
    nr: item.nr ?? '',
    link: getLink(item.id, isRiskManagement(item)),
    timeOccurred: item.timeOccurred,
    activities: getActivities(item, userId),
    organization: item.organization.name ?? '',
  };
};
