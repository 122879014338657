import { baseUrl } from 'shared/constants';
import { iafetch } from 'shared/utils';

export const useErrorLogging = () => {
  return {
    logError: (error: Error) => {
      void iafetch(`${baseUrl}/PreventionIA/IA/api/Web/Global/Log/JavascriptError`, {
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(error, Object.getOwnPropertyNames(error)),
        method: 'POST',
      });
    },
  };
};
