import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { TodoItem } from 'shared/types/types';
import { useLabels } from '../useLabels';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import IconButton from '@mui/material/IconButton';
import DateWithIcon from '../DetailView/DateWithIcon';
import { TodoType } from 'shared/types/enums';
import RiskAssessment from '../DetailView/RiskAssessment';

const StyledStack = styled(Stack)`
  padding: 0.5rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

interface Props {
  todoItems: TodoItem[];
  openDetailView: (id: number) => void;
  currentItemId?: number;
}
const TodoMobileList = ({ todoItems, openDetailView, currentItemId }: Props) => {
  const { todoTypesLabels } = useLabels();
  return (
    <>
      {todoItems.map(({ id, todoType, title, itemType, itemNumber, dateTitle, date, ...row }, idx) => (
        <StyledStack key={idx}>
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <Stack direction="row">
              <Typography variant="h3" fontSize="1rem" sx={{ mr: 2 }}>
                {todoTypesLabels[todoType]}
              </Typography>
              {date && <DateWithIcon date={date} isOverdue={row.isOverdue || todoType === TodoType.REMIND} />}
            </Stack>
            <IconButton onClick={() => openDetailView(id)} sx={{ whiteSpace: 'nowrap', p: 0 }}>
              <ExpandCircleDownIcon
                sx={{ color: currentItemId === id ? '#333' : '#ccc', transform: 'rotate(270deg)' }}
              />
            </IconButton>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Typography variant="body2" sx={{ color: '#003CD2' }}>
              {itemType}
            </Typography>
            <Typography variant="body2" sx={{ ml: 2 }}>
              {itemNumber}
            </Typography>
            {row.riskAssessment && <RiskAssessment riskAssessment={row.riskAssessment} />}
          </Stack>
          <Typography variant="body2">{title}</Typography>
        </StyledStack>
      ))}
    </>
  );
};

export default TodoMobileList;
