import User from './Sections/User';
import { Grid, Stack, styled, useMediaQuery, useTheme } from '@mui/material';
import Organization from './Sections/Organization';
import OrgSettings from '../Settings/OrgSettings';
import { useGetLinkContentDataFromserver } from 'shared/startpageService/charts';
import IANews from './Sections/IANews';
import MyIA from '../MyIA/MyIA';
import UserSettings from '../Settings/UserSettings';
import { useUserSettings } from '../Settings/useUserSettings';
import { useGlobalContext } from 'globalContext';

const PageContent = styled('div')`
  padding: 0 5.55%;
`;

// const SideButton = styled(IconButton)`
//   padding: 10;
//   font-size: 40px;
// `;

const WidgetGrid = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { hasOrgContent, countOrgContentInclNews } = useGetLinkContentDataFromserver();
  const { showTodoList, todoListWidth, hasUserContent } = useUserSettings();
  const { userIsAdmin } = useGlobalContext();

  const hasTopSection = hasOrgContent;
  const maxGridWidth = 12;
  const maxVisibleOrgContentCount = 4;
  const maxVisibleIANewsContentCount = 0;
  // const rightSectionXsCount = hasOrgContent && !hasUserContent ? 0 : 3;
  const rightSectionWidth = showTodoList ? maxGridWidth - todoListWidth : 12;
  const visibleOrgContentCount: number =
    countOrgContentInclNews > maxVisibleOrgContentCount ? maxVisibleOrgContentCount : countOrgContentInclNews;
  const totalCountColumnsSectionTop = visibleOrgContentCount + maxVisibleIANewsContentCount;

  const topSectionContentWidth = (visibleOrgContentCount / totalCountColumnsSectionTop) * maxGridWidth;
  const showAdminOrgParts = userIsAdmin === 'True';

  return (
    <>
      <PageContent>
        {isDesktop && (
          <>
            <Stack direction="row" spacing={2} justifyContent={'flex-end'} sx={{ mt: 1, mb: 2 }}>
              {showAdminOrgParts && <OrgSettings />}
              <UserSettings />
            </Stack>

            {hasTopSection && (
              <>
                <Grid container spacing={2} /* sx={{ mt: 0.5 }} */>
                  <Organization containerWidth={topSectionContentWidth} />
                  {/*  {showNews && <IANews xs={3} />} */}

                  {/*  <Stack spacing={2} sx={{ mt: 5, mr: -5 }}>
                    <SideButton>
                      <ListAltIcon />
                    </SideButton>
                  </Stack> */}
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  {showTodoList && <MyIA width={todoListWidth} />}
                  <Grid item xs={rightSectionWidth}>
                    <Grid container spacing={2}>
                      {hasUserContent && <User xs={rightSectionWidth} />}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {!hasTopSection && (
              <Grid container spacing={2}>
                {showTodoList && <MyIA width={todoListWidth} />}
                <Grid item xs={rightSectionWidth}>
                  <Grid container spacing={2}>
                    {hasUserContent && <User xs={12} />}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        )}
        {!isDesktop && (
          <>
            <OrgSettings />
            <Grid container spacing={6} sx={{ mt: 0.5 }}>
              {showTodoList && <MyIA width={12} />}
              {hasUserContent && <User xs={12} />}
              {hasOrgContent && <Organization containerWidth={12} width={12} />}
              <IANews xs={12} />
            </Grid>
          </>
        )}
      </PageContent>
    </>
  );
};

export default WidgetGrid;
