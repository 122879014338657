import { TodoItem } from 'shared/types/types';
import {
  getConfirmUrl,
  formatDate,
  getDateFollowUpOther,
  getFollowUpUrl,
  getInvestigateUrl,
  getRedecideUrl,
  getRemindOrWatchUrl,
  getTakeActionUrl,
  isOverdue,
  getCompleteUrl,
} from '../common/utils';
import { Action, IStartpageListResponseItem, IdNameObject } from '../common/responseTypes';
import { TodoType } from 'shared/types/enums';
import { STATUS } from '../common/constants';

export interface Responsibility {
  firstName: string;
  lastName: string;
  fullName: string;
  userId: number;
  initials: string;
  title: string;
  type: number;
}

export interface HandlingPhase {
  id?: number | string;
  status: number;
  completedDate?: string;
  description?: string;
  title: string;
  responsible: IdNameObject;
  reportedDate?: string;
  startedDate?: string;
}

export interface TodoResponseDesired {
  items: TodoItem[];
}

export const isRiskManagement = (item: IStartpageListResponseItem) => {
  return item?.unitRiskManagement !== null;
};

const EstimatedCompletedResource = 'Startpage_MyIA.Header.EstimatedCompletionDate';

export const getDefaultItemOccurrence = (item: IStartpageListResponseItem): TodoItem => {
  return {
    id: item.id,
    isRiskManagement: false,
    itemType: item.itemType.name ?? '',
    itemNumber: item.nr ?? '',
    isOverdue: false,
    riskAssessment: {
      riskAssessments:
        item.riskBeforeLevel && item.riskBeforeAction
          ? [
              {
                level: item.riskBeforeLevel,
                count: item.riskBeforeLevel !== null ? 1 : 0,
                value: item.riskBeforeAction,
              },
            ]
          : [],
      isAfter: false,
    },
    title: item.occurrenceTitle ?? '',
    dateTitle: 'Selections_Detail_TimeOccurred',
    date: formatDate(item.timeOccurred),
    actionId: 0,
    todoType: TodoType.CONFIRM,
  };
};

const isConfirm = (item: IStartpageListResponseItem, userId: number) =>
  userId === item.responsible.id && item.status.id === STATUS.OCCURRENCE.REPORTED;
const confirmData = (item: IStartpageListResponseItem) => {
  const todoItem = getDefaultItemOccurrence(item);
  const date = formatDate(item.timeReported);
  return {
    ...todoItem,
    date,
    dateTitle: 'Selections_Detail_TimeReported',
    isOverdue: isOverdue(date),
    todoType: TodoType.CONFIRM,
    link: getConfirmUrl(item.id),
  };
};

const isToRemindInvestigate = (item: IStartpageListResponseItem, userId: number) =>
  userId === item.responsible?.id && item.investigationStatus?.id === STATUS.INVESTIGATION.OVERDUE;

const toRemindInvestigateData = (item: IStartpageListResponseItem) => {
  const todoItem = getDefaultItemOccurrence(item);
  const date = formatDate(item.investigationTimeCompleted);

  return {
    ...todoItem,
    todoType: TodoType.REMIND,
    toRemindTodoType: TodoType.INVESTIGATE,
    title: item.investigationTitle ?? '',
    date,
    isOverdue: true,
    dateTitle: EstimatedCompletedResource,
    link: getInvestigateUrl(item.id),
  };
};

const isToRemindFollowUp = (item: IStartpageListResponseItem, userId: number) =>
  userId === item.responsible?.id && item.followUpStatus?.id === STATUS.FOLLOW_UP.OVERDUE;
const toRemindFollowUpData = (item: IStartpageListResponseItem) => {
  const todoItem = isRiskManagement(item) ? getDefaultItemRiskManagement(item) : getDefaultItemOccurrence(item);
  const date = formatDate(item.followUpTimeCompleted);
  return {
    ...todoItem,
    todoType: TodoType.REMIND,
    toRemindTodoType: TodoType.FOLLOW_UP,
    date,
    isOverdue: true,
    dateTitle: EstimatedCompletedResource,
    link: getFollowUpUrl(item.id, isRiskManagement(item)),
  };
};

const isToRemindFollowUpOther = (item: IStartpageListResponseItem, userId: number) => {
  return userId === item.responsible?.id && item.followUpOtherOk === null && isOverdue(getDateFollowUpOther(item));
};
const toRemindFollowUpOtherData = (item: IStartpageListResponseItem) => {
  const todoItem = getDefaultItemOccurrence(item);
  const date = formatDate(getDateFollowUpOther(item));
  return {
    ...todoItem,
    todoType: TodoType.REMIND,
    toRemindTodoType: TodoType.FOLLOW_UP,
    date,
    isOverdue: true,
    dateTitle: EstimatedCompletedResource,
    link: getRemindOrWatchUrl(item.id, item.status.id),
  };
};

const isToRedecide = (item: IStartpageListResponseItem, userId: number) =>
  userId === item.responsible?.id && item.followUpOtherOk === 0;
const toRedecideData = (item: IStartpageListResponseItem) => {
  const todoItem = getDefaultItemOccurrence(item);
  return {
    ...todoItem,
    todoType: TodoType.REDECIDE,
    link: getRedecideUrl(item.id),
  };
};

const isToMonitor = (item: IStartpageListResponseItem, userId: number, items: TodoItem[]) => {
  return (
    (userId === item.responsible?.id &&
      item.status.id &&
      item.status.id > STATUS.OCCURRENCE.REPORTED &&
      !items.some((a) => a.id === item.id)) ||
    userId === item.responsibleOhs?.id
  );
};
const toMonitorData = (item: IStartpageListResponseItem) => {
  const todoItem = getDefaultItemOccurrence(item);
  return {
    ...todoItem,
    todoType: TodoType.MONITOR,
    link: getRemindOrWatchUrl(item.id),
  };
};

const isToInvestigate = (item: IStartpageListResponseItem, userId: number) => {
  return (
    (userId === item.investigator?.id || item.investigationParticipants?.map((a) => a.id).includes(userId)) &&
    item.investigationStatus?.id !== STATUS.INVESTIGATION.COMPLETED
  );
};
const toInvestigateData = (item: IStartpageListResponseItem) => {
  const todoItem = getDefaultItemOccurrence(item);
  const date = formatDate(item.investigationTimeCompleted);

  return {
    ...todoItem,
    todoType: TodoType.INVESTIGATE,
    title: item.investigationTitle ?? '',
    date,
    isOverdue: isOverdue(date),
    dateTitle: EstimatedCompletedResource,
    link: getInvestigateUrl(item.id),
  };
};

const isToTakeAction = (action: Action, userId: number) =>
  userId === action.actionOwner.id && action.actionStatus.id !== STATUS.ACTION.COMPLETED;

const toTakeActionData = (action: Action, item: IStartpageListResponseItem) => {
  const todoItem = isRiskManagement(item) ? getDefaultItemRiskManagement(item) : getDefaultItemOccurrence(item);
  const date = formatDate(action.actionTimeCompleted);
  return {
    ...todoItem,
    todoType: TodoType.TAKE_ACTION,
    title: action.actionTitle ?? '',
    date,
    isOverdue: isOverdue(date),
    dateTitle: EstimatedCompletedResource,
    actionId: action.actionId,
    link: getTakeActionUrl(item.id, action.actionId, isRiskManagement(item)),
  };
};

const isToRemindTakeAction = (action: Action, item: IStartpageListResponseItem, userId: number) =>
  userId === item.responsible?.id && action.actionStatus.id === STATUS.ACTION.OVERDUE;

const toRemindTakeActionData = (action: Action, item: IStartpageListResponseItem) => {
  const todoItem = isRiskManagement(item) ? getDefaultItemRiskManagement(item) : getDefaultItemOccurrence(item);
  const date = formatDate(action.actionTimeCompleted);
  return {
    ...todoItem,
    todoType: TodoType.REMIND,
    toRemindTodoType: TodoType.TAKE_ACTION,
    title: action.actionTitle ?? '',
    date,
    isOverdue: true,
    dateTitle: EstimatedCompletedResource,
    actionId: action.actionId,
    link: getTakeActionUrl(item.id, action.actionId, isRiskManagement(item)),
  };
};

const isToFollowUp = (item: IStartpageListResponseItem, userId: number) =>
  userId === item.followUpResponsible?.id && item.followUpStatus.id !== STATUS.FOLLOW_UP.COMPLETED;

const toFollowUpData = (item: IStartpageListResponseItem) => {
  const todoItem = isRiskManagement(item) ? getDefaultItemRiskManagement(item) : getDefaultItemOccurrence(item);
  const date = formatDate(item.followUpTimeCompleted);

  return {
    ...todoItem,
    todoType: TodoType.FOLLOW_UP,
    date,
    isOverdue: isOverdue(date),
    dateTitle: EstimatedCompletedResource,
    link: getFollowUpUrl(item.id, isRiskManagement(item)),
  };
};

const isToFollowUpOther = (item: IStartpageListResponseItem, userId: number) => {
  return userId === item.followUpOtherResponsible?.id && item.followUpOtherOk === null && item.followUpTimeCompleted;
};
const toFollowUpOtherData = (item: IStartpageListResponseItem) => {
  const todoItem = getDefaultItemOccurrence(item);
  const date = formatDate(getDateFollowUpOther(item));

  return {
    ...todoItem,
    todoType: TodoType.FOLLOW_UP,
    date,
    isOverdue: isOverdue(date),
    dateTitle: EstimatedCompletedResource,
    link: getFollowUpUrl(item.id, isRiskManagement(item)),
  };
};

const getActions = (actions: Action[], userId: number, item: IStartpageListResponseItem) => {
  const actionsArray = [];
  for (const action of actions) {
    if (isToTakeAction(action, userId)) {
      actionsArray.push(toTakeActionData(action, item));
    }
    if (isToRemindTakeAction(action, item, userId)) {
      actionsArray.push(toRemindTakeActionData(action, item));
    }
  }

  return actionsArray;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const getTodoModelOccurrences = (userId?: number, myReportsResponse?: IStartpageListResponseItem[]) => {
  if (!myReportsResponse || !userId) return null;

  const items: TodoItem[] = [];

  for (const item of myReportsResponse) {
    if (isConfirm(item, userId)) {
      items.push(confirmData(item));
    }
    if (isToRemindInvestigate(item, userId)) {
      items.push(toRemindInvestigateData(item));
    }
    if (isToRemindFollowUp(item, userId)) {
      items.push(toRemindFollowUpData(item));
    }
    if (isToRemindFollowUpOther(item, userId)) {
      items.push(toRemindFollowUpOtherData(item));
    }
    if (isToRedecide(item, userId)) {
      items.push(toRedecideData(item));
    }
    if (isToMonitor(item, userId, items)) {
      items.push(toMonitorData(item));
    }
    if (isToInvestigate(item, userId)) {
      items.push(toInvestigateData(item));
    }
    items.push(...getActions(item.actions ?? [], userId, item));
    if (isToFollowUp(item, userId)) {
      items.push(toFollowUpData(item));
    }
    if (isToFollowUpOther(item, userId)) {
      items.push(toFollowUpOtherData(item));
    }
  }
  const response: TodoResponseDesired = {
    items,
  };
  return response;
};

const getDefaultItemRiskManagement = (item: IStartpageListResponseItem): TodoItem => {
  return {
    id: item.id,
    isRiskManagement: true,
    itemType: item.itemType.name ?? '',
    itemNumber: item.nr ?? '',
    isOverdue: false,
    title: item.title?.name ?? '',
    dateTitle: 'Selections_Detail_TimeOccurred',
    date: formatDate(item.timeOccurred),
    actionId: 0,
    todoType: TodoType.COMPLETE,
    link: getCompleteUrl(item.id),
  };
};

const isToComplete = (item: IStartpageListResponseItem, userId: number) => {
  return (
    (userId === item.responsible?.id || item.participants?.map((a) => a.id).includes(userId)) &&
    item.status?.id === STATUS.RISKMANAGEMENT.PLANNED
  );
};
const toCompleteData = (item: IStartpageListResponseItem) => {
  const date = formatDate(item.timeOccurred);
  const todoItem = getDefaultItemRiskManagement(item);
  return {
    ...todoItem,
    todoType: TodoType.COMPLETE,
    title: item.title?.name ?? '',
    date,
    isOverdue: isOverdue(date),
  };
};

export const getTodoModelRiskmanagements = (userId?: number, myReportsResponse?: IStartpageListResponseItem[]) => {
  if (!myReportsResponse || !userId) return null;

  const items: TodoItem[] = [];
  for (const item of myReportsResponse) {
    if (isToComplete(item, userId)) {
      items.push(toCompleteData(item));
    }
    if (isToRemindFollowUp(item, userId)) {
      items.push(toRemindFollowUpData(item));
    }
    items.push(...getActions(item.actions ?? [], userId, item));
    if (isToFollowUp(item, userId)) {
      items.push(toFollowUpData(item));
    }
  }
  const response: TodoResponseDesired = {
    items,
  };
  return response;
};
