import { Button, Divider, Drawer, IconButton, styled, Switch, Tooltip, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import ReportTitleTranslated from './ReportTitle';
import { useTranslations } from 'shared/hooks/useTranslations';
import { useGetLinkContentDataFromserver } from 'shared/startpageService/charts';
import { ContentLevel } from 'shared/types/enums';
import { Systempart } from 'shared/startpageService/charts/enums';
import { baseUrl } from 'shared/constants';
import { useGlobalContext } from 'globalContext';
import PeriodSelect from './PeriodSelect';
import { useQueryClient } from '@tanstack/react-query';
import { useGetMainMenu } from '../IaAppBar/navbarQueries';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoggingErrorBoundary } from 'shared/error/LoggingErrorBoundary';
import { iafetch, iapost } from 'shared/utils';
import { IReport, ISection } from 'shared/startpageService/common/types';

const Container = styled('div')`
  margin-left: 1.25rem;
  margin-right: 1.25rem;
`;

const DrawerHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
`;

const SettingsWidget = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
`;

export const StyledSwitchRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.3rem;
`;

const StyledDeleteRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1rem;
  height: 2.3rem;
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  font-size: 27px;
  color: #003cd2;
`;

const StyledIconButton = styled(IconButton)`
  padding: 0;
`;

const Settings = () => {
  const queryClient = useQueryClient();
  const ajaxContentType = 'application/json; charset=utf-8';
  const { t } = useTranslations();
  const { userId, companyId, userCulture, companyName, userIsAdmin, industryId, sectorName } = useGlobalContext();
  // TODO lägga till username i globalcontext
  const { data: userData } = useGetMainMenu();

  const {
    standardContentSector,
    standardContentOrg,
    standardContentUser,
    selectedContentOrg,
    selectedContentSector,
    selectedContentUser,
    ownContentOrg,
    ownContentUser,
    hasOwnContentOrg,
    hasOwnContentUser,
  } = useGetLinkContentDataFromserver();
  const showAdminOrgParts = userIsAdmin === 'True';
  const [drawerOpen, setDrawerOpen] = useState(false);
  const onClose = () => {
    setDrawerOpen(false);
  };
  const onClick = () => {
    setDrawerOpen(true);
  };

  const getSelectedReports = (level: ContentLevel) => {
    if (level === ContentLevel.Sector) {
      return selectedContentSector;
    }
    if (level === ContentLevel.Organization) {
      return selectedContentOrg;
    }
    if (level === ContentLevel.User) {
      return selectedContentUser;
    } else return [];
  };

  const getStandardReports = (level: ContentLevel) => {
    if (level === ContentLevel.Sector) {
      return standardContentSector;
    }
    if (level === ContentLevel.Organization) {
      return standardContentOrg;
    }
    if (level === ContentLevel.User) {
      return standardContentUser;
    } else return [];
  };

  const setSpecificData = (sections: ISection[], level: ContentLevel) => {
    for (const section of sections) {
      if (section.settings.filter.base.level) {
        section.settings.filter.base.level.type = level;
        if (level === ContentLevel.Organization) {
          section.settings.filter.base.level.id = Number(companyId);
          section.settings.filter.base.level.name = companyName;
        }
        if (level === ContentLevel.Sector) {
          section.settings.filter.base.level.id = Number(industryId);
          section.settings.filter.base.level.name = sectorName;
        }
        if (level === ContentLevel.User) {
          section.settings.filter.base.level.id = Number(userId);
          section.settings.filter.base.level.name = userData?.UserName;
        }
      }
    }
    return sections;
  };

  const SaveNewReport = (title: string, level: ContentLevel, systempart: Systempart) => {
    const link = getStandardReports(level)?.find((a) => a.Text === title);
    const reportId = Number(link?.Link.split('=')[1]);
    const urlMyReportsStartStr = '/PreventionIA/IA/Pages/Reports/MyReports.aspx?';

    if (getSelectedReports(level).some((a) => a.Text === title)) return;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    iafetch<IReport>(`${baseUrl}/PreventionIA/IA/api/Web/Reports/MyReports/Settings?id=${reportId}`, {
      method: 'GET',
      headers: new Headers({ 'content-type': ajaxContentType }),
    }).then((data) => {
      const sections = setSpecificData(JSON.parse(data?.Settings).sections, level);

      const reportSettingsObj = {
        reportCulture: userCulture,
        title,
        sections,
      };
      const myReportSettings = JSON.stringify({
        OrganizationId: companyId,
        Settings: JSON.stringify(reportSettingsObj),
      });
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      iafetch(`${baseUrl}/PreventionIA/IA/api/Web/Reports/MyReports/Settings`, {
        method: 'POST',
        body: myReportSettings,
        headers: new Headers({ 'content-type': ajaxContentType }),
      }).then((reportId) => {
        const contentUrl = `${urlMyReportsStartStr}SettingsId=${reportId}`;

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        iapost(`${baseUrl}/PreventionIA/IA/api/Web/Global/LinkContent/`, {
          method: 'POST',
          body: JSON.stringify({
            SystemPartId: systempart,
            OrgId: companyId,
            Text: title,
            Link: contentUrl,
            CreatedById: userId,
          }),
          headers: new Headers({ 'content-type': ajaxContentType }),
        }).then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          queryClient.invalidateQueries({ queryKey: ['links-query'] });
        });
      });
    });
  };

  const DeleteReport = (title: string, level: ContentLevel, systempart: Systempart) => {
    const reportsSelected = getSelectedReports(level);
    const Id = reportsSelected?.find((a) => a.Text === title)?.Id;
    const link = reportsSelected?.find((a) => a.Text === title);
    const reportId = Number(link?.Link.split('=')[1]);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    iapost(`${baseUrl}/PreventionIA/IA/api/Web/Global/LinkContent/`, {
      method: 'DELETE',
      body: JSON.stringify({
        SystemPartId: systempart,
        OrgId: companyId,
        Id,
        ParentId: 0,
        CreatedById: userId,
      }),
      headers: new Headers({ 'content-type': ajaxContentType }),
    }).then(() => {
      void iapost(`${baseUrl}/PreventionIA/IA/api/Web/Reports/MyReports/Settings?id=${reportId}`, {
        method: 'DELETE',
        headers: new Headers({ 'content-type': ajaxContentType }),
      }).then(() => {
        void queryClient.invalidateQueries({ queryKey: ['links-query'] });
      });
    });
  };

  const toggleReport =
    (label: string, level: ContentLevel, systempart: Systempart) =>
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (
        getSelectedReports(level)
          ?.map((a) => a.Text)
          .includes(label)
      ) {
        DeleteReport(label, level, systempart);
      } else {
        SaveNewReport(label, level, systempart);
      }
    };

  const isActive = (label: string, level: ContentLevel) => {
    return getSelectedReports(level)
      ?.map((a) => a.Text)
      .includes(label);
  };

  return (
    <LoggingErrorBoundary>
      <SettingsWidget>
        <Button variant="outlined" color="primary" onClick={onClick}>
          <SettingsIcon sx={{ mr: 1 }} />
          {t('Startpage_Customize')}
        </Button>
      </SettingsWidget>
      <Drawer anchor="right" open={drawerOpen} onClose={onClose}>
        <Container>
          <DrawerHeader>
            <Typography variant="h5">{t('Startpage_Customize')}</Typography>
            <Button onClick={onClose}>
              <CloseIcon />
            </Button>
          </DrawerHeader>
          <PeriodSelect />
          {showAdminOrgParts && hasOwnContentOrg && (
            <>
              <Typography variant="subtitle2" mt={2}>
                {t('Startpage_Information')} {companyName}
              </Typography>
              <Divider />
              {ownContentOrg.map(({ Id, Text }) => {
                return (
                  <StyledDeleteRow key={Id}>
                    <ReportTitleTranslated title={Text} />
                    <Tooltip title={t('Startpage_DeleteReport')}>
                      <StyledIconButton
                        aria-label={t('Startpage_DeleteReport')}
                        onClick={() => {
                          DeleteReport(Text, ContentLevel.Organization, Systempart.START_PAGE_ORG);
                        }}
                      >
                        <StyledDeleteIcon />
                      </StyledIconButton>
                    </Tooltip>
                  </StyledDeleteRow>
                );
              })}
            </>
          )}
          {showAdminOrgParts && (
            <>
              <Typography variant="subtitle2" mt={3}>
                {t('Startpage_StandardInformation')}
              </Typography>
              <Divider />
              {standardContentOrg?.map(({ Id, Text }) => {
                return (
                  <StyledSwitchRow key={Id}>
                    <ReportTitleTranslated title={Text} />
                    <Switch
                      checked={isActive(Text, ContentLevel.Organization)}
                      onChange={toggleReport(Text, ContentLevel.Organization, Systempart.START_PAGE_ORG)}
                    />
                  </StyledSwitchRow>
                );
              })}
              <Typography variant="subtitle2" mt={3}>
                {t('Startpage_SectorInformation')}
              </Typography>
              <Divider />
              {standardContentSector?.map(({ Id, Text }) => {
                return (
                  <StyledSwitchRow key={Id}>
                    <ReportTitleTranslated title={Text} />
                    <Switch
                      checked={isActive(Text, ContentLevel.Sector)}
                      onChange={toggleReport(Text, ContentLevel.Sector, Systempart.START_PAGE_SECTOR)}
                    />
                  </StyledSwitchRow>
                );
              })}
            </>
          )}
          <Typography variant="subtitle2" mt={3}>
            {t('Startpage_MyInformation')}
          </Typography>
          <Divider />
          {hasOwnContentUser && (
            <>
              {ownContentUser.map(({ Id, Text }) => (
                <StyledDeleteRow key={Id}>
                  <ReportTitleTranslated title={Text} />
                  <Tooltip title={t('Startpage_DeleteReport')}>
                    <StyledIconButton
                      aria-label={t('Startpage_DeleteReport')}
                      onClick={() => {
                        DeleteReport(Text, ContentLevel.User, Systempart.START_PAGE_MY);
                      }}
                    >
                      <StyledDeleteIcon />
                    </StyledIconButton>
                  </Tooltip>
                </StyledDeleteRow>
              ))}
            </>
          )}
          {standardContentUser.map(({ Id, Text }) => (
            <StyledSwitchRow key={Id}>
              <ReportTitleTranslated title={Text} />
              <Switch
                checked={isActive(Text, ContentLevel.User)}
                onChange={toggleReport(Text, ContentLevel.User, Systempart.START_PAGE_MY)}
              />
            </StyledSwitchRow>
          ))}
        </Container>
      </Drawer>
    </LoggingErrorBoundary>
  );
};
export default Settings;
