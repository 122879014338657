import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartMore from 'highcharts/highcharts-more';
import HighchartsAccesibillity from 'highcharts/modules/accessibility';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';

import { getCustomRequest } from '../customRequest';
import { getHighchartOptions } from './highcharts';
import { useGetMyReportsData } from 'shared/startpageService/common/myReportsQuery';
import { IDistribution, IMyReportRequest } from 'shared/startpageService/common/types';
import { ContentLevel, DetailType } from 'shared/types/enums';
import { RequestTypes } from 'shared/startpageService/common/requestTypes';

HighchartMore(Highcharts);
HighchartsAccesibillity(Highcharts);
HighchartsSolidGauge(Highcharts);

interface DefaultChartProps {
  settings: IMyReportRequest;
  distribution: IDistribution;
  serie: DetailType;
  level: ContentLevel;
  title: string;
}
const DefaultChart = ({ settings, distribution, serie, level, title }: DefaultChartProps) => {
  const widget = getCustomRequest(settings?.filter, distribution?.type, serie);
  const { data: dataArray } = useGetMyReportsData(RequestTypes.CustomChart, widget, level, {
    enabled: Boolean(widget),
  });

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={getHighchartOptions(distribution, dataArray?.[0], settings, title)}
      containerProps={{ style: { height: '205px' } }}
    />
  );
};

export default DefaultChart;
