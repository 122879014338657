import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import differenceInDays from 'date-fns/differenceInDays';
import { useTranslations } from 'shared/hooks/useTranslations';
import { ContentLevel } from 'shared/types/enums';
import { IReportSettings } from 'shared/startpageService/common/types';
import { useGetMyReportsData } from 'sections/newmyia/backendQueries/myReportsQuery';
import { RequestTypes } from 'shared/startpageService/common/requestTypes';

interface Props {
  reportSettings: IReportSettings;
}

const DaysSinceLastAccident = ({ reportSettings }: Props) => {
  const { data } = useGetMyReportsData(RequestTypes.DaysSinceLastAccident, undefined);
  const { t } = useTranslations();

  let interval: number = -1;

  if (data && data.length > 0) {
    interval = data[0].timeOccurred ? differenceInDays(new Date(), new Date(data[0].timeOccurred)) : -1;
  }
  return (
    <Stack direction="column" justifyContent="center" alignItems="center" sx={{ height: '80%' }}>
      {interval !== -1 && (
        <>
          <Stack direction="row" alignItems="baseline" sx={{ m: 1 }}>
            <Typography sx={{ fontSize: 40, fontWeight: 700, mt: '0.5rem' }}>{interval}</Typography>
            <Typography sx={{ fontSize: 20, ml: 1, mt: 1 }}>{t('Period_Days')}</Typography>
          </Stack>
        </>
      )}
      {interval === -1 && <Typography>{t('Startpage_DaysInjury.NoResult')}</Typography>}
    </Stack>
  );
};

export default DaysSinceLastAccident;
