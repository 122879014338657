import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { StyledTopBarItem } from '../TopBar';
import Button from '@mui/material/Button';
import Popper from '@mui/material/Popper';
import { useRef, useState } from 'react';
import { ClickAwayListener, IconButton, Link, List, Typography } from '@mui/material';
import { useDeleteFavorite, useGetFavorites } from './favoritesQueries';
import { styled } from '@mui/material/styles';
import WidgetBox from 'shared/UI/WidgetBox';
import DeleteIcon from '@mui/icons-material/Delete';
import EmailIcon from '@mui/icons-material/Email';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslations } from 'shared/hooks/useTranslations';

const FavoriteRow = styled('li')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Actions = styled('div')`
  display: flex;
  align-items: center;
`;
const StyledFavoritesBox = styled('div')`
  max-width: 450px;
`;

const Favorites = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslations();
  const { data: favorites } = useGetFavorites(open);
  const { mutate } = useDeleteFavorite();
  const queryClient = useQueryClient();
  const anchorEl = useRef<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(true);
    anchorEl.current = event.currentTarget;
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = (id: string) => {
    mutate(id, {
      onSuccess: () => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        queryClient.invalidateQueries({ queryKey: ['favorites'] });
      },
    });
  };
  const createEmailLink = (name: string, url: string) => {
    const subject = `${t('Favorites_EmailSubject')} ${name}`;

    const hostname = window.location.hostname;
    const link = `https://${hostname}${url}`;
    const body = `${t('Favorites_EmailIntro')} \n\n ${t('Favorites_EmailText1')} \n\n ${t(
      'Favorites_EmailText2'
    )} \n\n ${link}`;

    return `mailto:?subject=${subject}&body=${body}`;
  };
  return (
    <StyledTopBarItem variant="body2">
      <FavoriteBorderIcon />
      <Button onClick={handleOpen} sx={{ color: 'inherit', padding: 0, fontWeight: 400 }}>
        {t('Favorites_Entry')}
      </Button>
      <Popper anchorEl={anchorEl.current} open={open}>
        <WidgetBox>
          <ClickAwayListener onClickAway={handleClose}>
            <StyledFavoritesBox>
              <Typography marginTop={1}>{t('Favorites_Header')}</Typography>
              {favorites && favorites.length > 0 && (
                <List>
                  {favorites?.map(({ id, name, url }) => {
                    return (
                      <FavoriteRow key={id}>
                        <Link href={url}>
                          <Typography>{name}</Typography>
                        </Link>
                        <Actions>
                          <IconButton onClick={() => handleDelete(id)}>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                          <Link href={createEmailLink(name, url)} color="#000" sx={{ mt: '0.2rem' }}>
                            <EmailIcon fontSize="small" />
                          </Link>
                        </Actions>
                      </FavoriteRow>
                    );
                  })}
                </List>
              )}
              {!favorites && <Typography variant="body1">{t('Favorites_ListEmpty')}</Typography>}
            </StyledFavoritesBox>
          </ClickAwayListener>
        </WidgetBox>
      </Popper>
    </StyledTopBarItem>
  );
};

export default Favorites;
