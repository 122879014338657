import { Link, List, ListItem, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { IMenuItem } from './navbarQueries';

const StyledSubMenu = styled('div')`
  display: none;
  z-index: 100;
  position: absolute;
  top: 100%;
`;
const StyledMenuItem = styled('div')`
  height: 100%;
  cursor: pointer;
  position: relative;
  &:hover {
    .submenu {
      display: block;
    }
  }
  display: flex;
  align-items: center;
`;

interface MenuItemProps {
  item: IMenuItem;
}
export const MenuItem = ({ item }: MenuItemProps) => {
  return (
    <StyledMenuItem>
      <Typography variant="subtitle2" color="inherit" data-testid="menuitem">
        {item.Text}
      </Typography>
      {item.SubMenuItemList?.length > 0 && (
        <StyledSubMenu className="submenu">
          <Paper>
            <List>
              {item.SubMenuItemList.map(({ Text, Url }) => {
                return (
                  <ListItem key={Url}>
                    <Link href={Url} underline="none">
                      <Typography variant="subtitle2" color="primary.dark" noWrap>
                        {Text}
                      </Typography>
                    </Link>
                  </ListItem>
                );
              })}
            </List>
          </Paper>
        </StyledSubMenu>
      )}
    </StyledMenuItem>
  );
};
