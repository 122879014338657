import { PeriodType, PeriodUnit } from 'shared/startpageService/common/enums';

export interface PeriodContentItem {
  type?: PeriodType;
  label?: string;
  unit?: PeriodUnit;
  quantity?: number;
  offsetBackwards?: number;
  startDate?: string;
  endDate?: string;
  customMode?: boolean;
  fixedMode?: boolean;
  reportCulture?: string;
}

export const useGetPeriodContent = () => {
  const periods: PeriodContentItem[] = [
    {
      label: '7 dagar',
      unit: PeriodUnit.Day,
      quantity: 7,
    },
    {
      label: '30 dagar',
      unit: PeriodUnit.Day,
      quantity: 30,
    },
    {
      label: '4 veckor',
      unit: PeriodUnit.Week,
      quantity: 4,
    },
    {
      label: '12 månader',
      unit: PeriodUnit.Month,
      quantity: 12,
    },
    {
      label: '4 kvartal',
      unit: PeriodUnit.Quarter,
      quantity: 4,
    },
    {
      label: '3 år',
      unit: PeriodUnit.Year,
      quantity: 3,
    },
  ];

  return {
    periods,
  };
};
