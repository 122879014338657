import { Grid, Stack, Typography } from '@mui/material';
import { useTranslations } from 'shared/hooks/useTranslations';
import { useGetLinkContentDataFromserver } from 'shared/startpageService/charts';
import { ContentLevel } from 'shared/types/enums';
import CustomChart from 'sections/startpage/CustomCharts/CustomChart';
import { LoggingErrorBoundary } from 'shared/error/LoggingErrorBoundary';

interface Props {
  xs: number;
}

const User = ({ xs }: Props) => {
  const { t } = useTranslations();
  const { selectedContentUser } = useGetLinkContentDataFromserver();

  const sectionTitle = t('Startpage_MyInformation');

  return (
    <LoggingErrorBoundary>
      <Grid item xs={xs} sx={{ alignItems: 'flex-start' }}>
        <Typography variant="h6">{sectionTitle}</Typography>
        <Stack spacing={2}>
          {selectedContentUser?.map((content) => (
            <CustomChart key={content.Id} linkId={content.Id} level={ContentLevel.User} />
          ))}
        </Stack>
      </Grid>
    </LoggingErrorBoundary>
  );
};

export default User;
