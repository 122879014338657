import { styled } from '@mui/material/styles';
import { ReactComponent as LogoSvg } from '../../assets/ia-logo.svg';

const StyledLogo = styled(LogoSvg)`
  path {
    fill: ${({ color }) => color};
  }
`;
interface Props extends React.ComponentPropsWithoutRef<'svg'> {
  color?: string;
}
const IALogo = ({ color, ...rest }: Props) => {
  return <StyledLogo color={color ?? 'white'} {...rest} />;
};

export default IALogo;
