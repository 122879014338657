import { useGlobalContext } from 'globalContext';
import { ContentLevel } from 'shared/types/enums';
import { useGetMainMenu } from '../IaAppBar/navbarQueries';

export interface LevelContentItem {
  type: ContentLevel;
  typeLabel: string;
  id: number;
  name?: string;
  orgId?: number;
  orgName?: string;
  icon?: string;
}

export interface UnitItem {
  OrgId: number;
  OrgName: string;
  UnitId: string;
  UnitName: string;
  UnitType: number;
}

export interface OrgItem {
  OrgId: number;
  OrgName: string;
}

export interface IdNameItem {
  Id: number;
  Name: string;
}

export interface LastSelectedLevels {
  sectorList: IdNameItem[];
  groupingList: IdNameItem[];
  orgList: OrgItem[];
  unitList: UnitItem[];
}

export const emptyLastSelectedLevels = {
  sectorList: [],
  groupingList: [],
  orgList: [],
  unitList: [],
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useGetLevelContent = () => {
  const { userGuid, userId, companyId, industryId, unitId, sectorName, companyName, unitName } = useGlobalContext();
  const { data } = useGetMainMenu();
  const userName = data?.UserName;

  const lastSelectedLevels: LastSelectedLevels = localStorage.getItem(`MyReport_v2_LastSelections_Level_${userGuid}`)
    ? JSON.parse(localStorage.getItem(`MyReport_v2_LastSelections_Level_${userGuid}`) ?? '')
    : emptyLastSelectedLevels;

  const getLevelId = (level?: ContentLevel) => {
    switch (level) {
      case ContentLevel.Sector:
        return Number(industryId);
      case ContentLevel.Organization:
        return Number(companyId);
      case ContentLevel.Unit:
        return Number(unitId);
      default:
        return Number(userId);
    }
  };

  const getLevelName = (level?: ContentLevel) => {
    switch (level) {
      case ContentLevel.Sector:
        return sectorName;
      case ContentLevel.Organization:
        return companyName;
      case ContentLevel.Unit:
        return unitName;
      default:
        return '';
    }
  };

  const sectors: LevelContentItem[] = [
    {
      type: ContentLevel.Sector,
      typeLabel: 'Bransch',
      id: getLevelId(ContentLevel.Sector),
      name: getLevelName(ContentLevel.Sector),
    },
  ];

  for (const item of lastSelectedLevels.sectorList) {
    if (!sectors.some((a) => a.id === item.Id)) {
      sectors.push({
        type: ContentLevel.Sector,
        typeLabel: 'Bransch',
        id: item.Id,
        name: item.Name,
      });
    }
  }

  const groupings: LevelContentItem[] = [];
  for (const item of lastSelectedLevels.groupingList) {
    groupings.push({
      type: ContentLevel.Grouping,
      typeLabel: 'Gruppering',
      id: item.Id,
      name: item.Name,
    });
  }

  const organizations: LevelContentItem[] = [
    {
      type: ContentLevel.Organization,
      typeLabel: 'Organisation',
      id: Number(companyId),
      name: companyName,
      icon: 'FactoryIcon',
    },
  ];

  for (const item of lastSelectedLevels.orgList) {
    if (!organizations.some((a) => a.id === item.OrgId && a.type === ContentLevel.Organization)) {
      organizations.push({
        type: ContentLevel.Organization,
        typeLabel: 'Organisation',
        id: item.OrgId,
        name: item.OrgName,
      });
    }
  }

  const units: LevelContentItem[] = [
    {
      type: ContentLevel.Unit,
      typeLabel: 'Enhet',
      id: Number(unitId),
      name: unitName,
      orgId: Number(companyId),
      orgName: companyName,
    },
  ];

  for (const item of lastSelectedLevels.unitList) {
    if (!units.some((a) => a.id === Number(item.UnitId) && a.type === ContentLevel.Unit)) {
      units.push({
        type: ContentLevel.Unit,
        typeLabel: 'Enhet',
        id: Number(item.UnitId),
        name: item.UnitName,
        orgId: item.OrgId,
        orgName: item.OrgName,
      });
    }
  }

  const responsibility: LevelContentItem = {
    type: ContentLevel.Responsibility,
    typeLabel: `Mitt ansvarsområde`,
    id: Number(userId),
    name: userName ?? '',
  };

  const user: LevelContentItem = {
    type: ContentLevel.User,
    typeLabel: `Mina`,
    id: Number(userId),
    name: userName ?? '',
  };

  const hasGroupings = groupings.length > 0;

  return {
    sectors,
    groupings,
    hasGroupings,
    organizations,
    units,
    responsibility,
    user,
  };
};
