import React from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import { ReactComponent as UserIcon } from '../../../assets/userIcon.svg';
import OrgSelect from './OrgSelect';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  userName?: string;
}
const UserMenu = ({ userName, ...rest }: Props) => {
  return (
    <div {...rest}>
      <Stack alignItems={'center'} direction="row" spacing={1}>
        <IconButton href="/PreventionIA/IA/Pages/Admin/AdminAnvFlerInstallningar.aspx">
          <UserIcon width={40} height={40} />
        </IconButton>
        <div>
          <Stack>
            <Typography variant="subtitle2" color="text.primary">
              {userName}
            </Typography>
            <OrgSelect />
          </Stack>
        </div>
      </Stack>
    </div>
  );
};

export default UserMenu;
