import { chartColorPalettes, ColorSets, OK, RISK_LEVEL } from './colors';
import { IDistribution, IMyReportRequest } from 'shared/startpageService/common/types';
import { DetailType } from 'shared/types/enums';
import { IMyReportsDetailsResponseItem, Total } from 'shared/types/types';
// import { detailTitles } from './resources';

export const MISSING_VALUE = 999999;

// export const getTitle = function (detail: DetailType) {
//   return detailTitles[detail] ?? detail;
// };

export const getDetailSeries = (
  showSerie: boolean,
  settings: IMyReportRequest,
  distribution: IDistribution,
  item?: IMyReportsDetailsResponseItem
) => {
  const type: DetailType = settings.mainChart?.load[0] ?? DetailType.STATUS;
  let series: any = [];
  if (showSerie) {
    series = serieItems(settings, item);
  } else {
    const serie = getSerieByType(type, distribution, item);
    series.push(serie);
  }

  if (distribution && distribution.numberToShow !== 'all') {
    series = getSeriesWithCorrectNumberOfItems(series, parseInt(distribution.numberToShow, 10));
  }

  if (type === DetailType.LTI || type === DetailType.FIRE) {
    const categories = getCategoryItems(settings, item);
    const index = categories?.findIndex((a) => a.id === 1);
    series = getSeriesForBooleanSingleValue(series, index);
  }
  return series;
};

export const getCategoryItems = (settings: IMyReportRequest, item?: IMyReportsDetailsResponseItem) => {
  const categories = item?.total?.map((a) => {
    return {
      name: a.name,
      id: a.id,
      hasAccess: a.hasAccess,
      y: a.data,
      color: getItemColorById(settings, a.id),
    };
  });

  const objIndex = categories?.findIndex((obj) => obj.id === MISSING_VALUE);
  if (objIndex !== -1) {
    // categories?[objIndex].name = 'Saknas'; // resources.General_Missing;
  }
  return categories;
};

export const getPieItems = (settings: IMyReportRequest, item?: IMyReportsDetailsResponseItem) => {
  return item?.total?.map((a) => {
    return {
      name: a.name,
      y: a.data,
    };
  });
};

export const getSeriesWithCorrectNumberOfItems = function (series?: any, numberToShow?: number) {
  if (series && numberToShow)
    for (const item of series) {
      item.data = item.data?.slice(0, numberToShow);
    }
  return series;
};

export const getSeriesForBooleanSingleValue = function (series: any[], index?: number) {
  for (const item of series) {
    item.data = item.data?.slice(index, index ?? 0 + 1);
  }
  return series;
};

export const getSerieByType = function (
  type: DetailType,
  distribution: IDistribution,
  item?: IMyReportsDetailsResponseItem
) {
  const mainContent: string = 'Händelser';

  if (type === DetailType.RISK_BEFORE_ACTION || type === DetailType.RISK_AFTER_ACTION) {
    return getSerieForRiskAssessment(mainContent, item?.total);
  } else if (type === DetailType.OK) {
    return getSerieForOk(item?.total);
  } else {
    return {
      name: mainContent,
      data: item?.total?.map((a) => a.data),
      color: distribution?.chartColor,
    };
  }
};

export const getSerieForRiskAssessment = function (mainContent: string, riskArr?: Total[] | null) {
  const dataArr = riskArr?.map((item) => ({
    y: item.data,
    color: RISK_LEVEL.find((a) => a.level === item.id)?.color,
  }));

  return {
    name: mainContent,
    data: dataArr ?? [],
    color: '#ffffff',
  };
};

export const getSerieForOk = function (arr?: Total[] | null) {
  const dataArr = arr?.map((item) => ({
    y: item.data,
    color: OK.find((a) => a.id === item.id)?.color,
  }));

  return {
    name: 'Riskhanteringsrader', // resources.Report_RiskManagementRows,
    data: dataArr ?? [],
    color: '#ffffff',
  };
};

export const serieItems = function (settings: IMyReportRequest, item?: IMyReportsDetailsResponseItem) {
  return item?.series?.map((a) => {
    return {
      name: a.name,
      id: a.id,
      data: a.data ?? [],
      hasAccess: a.hasAccess,
      color: getItemColorById(settings, a.id),
    };
  });
};

export const getItemColorById = function (settings: IMyReportRequest, id: number) {
  const type: DetailType = settings.mainChart?.load[0] ?? DetailType.STATUS;
  const currentColorPalette: ColorSets = settings.mainChart?.colorPalette ?? ColorSets.Default;
  const NUMBER_OF_DIFFERENT_COLORS = Object.keys(chartColorPalettes[currentColorPalette]).length;
  if (type && id !== null) {
    // If id is bigger then we have colors we have to get a color that exists
    if (id === MISSING_VALUE) {
      return chartColorPalettes[currentColorPalette][MISSING_VALUE];
    } else if (
      type === DetailType.RISK_BEFORE_ACTION ||
      (type === DetailType.RISK_AFTER_ACTION && RISK_LEVEL.some((a) => a.level === id))
    ) {
      return RISK_LEVEL.find((a) => a.level === id)?.color;
    } else if (type === DetailType.OK && OK.some((a) => a.id === id)) {
      return OK.find((a) => a.id === id)?.color;
    } else {
      const colorId = id % NUMBER_OF_DIFFERENT_COLORS;
      return chartColorPalettes[currentColorPalette][colorId];
    }
  } else {
    return chartColorPalettes[currentColorPalette][MISSING_VALUE];
  }
};

export const getPieColors = function (settings: IMyReportRequest, categories?: Total[] | null) {
  const colorArr: any[] = [];
  if (categories)
    for (const item of categories) {
      const color = getItemColorById(settings, item.id);
      colorArr.push(color);
    }
  return colorArr;
};

const INT_TO_MULTIPLY_TO_GET_PERCENTAGE = 100;
export const getTotalRiskReductionPercentage = (detail: IMyReportsDetailsResponseItem) => {
  if (detail?.series) {
    const sumBefore = detail.series.length > 0 ? detail.series.map((a) => a.data?.[0] ?? 0).reduce((a, b) => a + b) : 0;
    const sumAfter = detail.series.length > 0 ? detail.series.map((a) => a.data?.[1] ?? 0).reduce((a, b) => a + b) : 0;
    const riskReductionTotalPercentage =
      sumBefore > 0 ? ((sumBefore - sumAfter) * INT_TO_MULTIPLY_TO_GET_PERCENTAGE) / sumBefore : 0;
    return Math.round(riskReductionTotalPercentage);
  } else {
    return 0;
  }
};

export const getDetailItemCountWithoutMissing = function (detail: IMyReportsDetailsResponseItem): number {
  if (detail.total && detail.total.map((item) => item.data).length > 0) {
    return detail.total.map((item) => item.data).reduce((a, b) => a + b);
  }
  return 0;
};

export const getTotalPercentage = (detail?: IMyReportsDetailsResponseItem, totalCount?: number) => {
  if (detail && totalCount) {
    const itemCount = getDetailItemCountWithoutMissing(detail);
    if (totalCount > 0) {
      const totalPercentage = (itemCount * INT_TO_MULTIPLY_TO_GET_PERCENTAGE) / totalCount;
      return Math.round(totalPercentage);
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};
