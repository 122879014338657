export enum Systempart {
  REPORT_GENERAL = 1,
  REPORT_ORG = 2,
  REPORT_INSPIRATION = 3,
  SYSADMIN = 4,
  REPORT_MY = 6,
  START_PAGE_MY = 7,
  START_PAGE_ORG = 8,
  START_PAGE_STANDARD = 9,
  START_PAGE_INSPIRATION = 10,
  START_PAGE_SECTOR = 11,
  REPORT_LATEST = 99,
}

export enum StandardReportTitle {
  OccurrencesCompleted = '___10_occurrences_completed',
  ActionsCompleted = '___10_actions_completed',
  OpenRisks = '___10_open_risks',
  RiskReduction = '___10_risk_reduction',
  DaysSinceLastAccident = '___10_days_since_last_accident_with_sick-leave',
  ActionsOngoing = '___10_actions_ongoing',
}

export enum ReportActivation {
  Active = 'a',
  Selectable = 's',
  Default = 'd',
}
