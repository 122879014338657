import { useTheme } from '@mui/material/styles';
import { RiskLevel } from 'shared/types/enums';

export const useGetRiskColor = () => {
  const theme = useTheme();
  const colors = {
    [RiskLevel.HIGH]: theme.palette.iaColors.red[500],
    [RiskLevel.MEDIUM]: theme.palette.iaColors.yellow[500],
    [RiskLevel.LOW]: theme.palette.success.main,
    [RiskLevel.ELIMINATED]: '#fff',
  };
  return (riskLevel?: RiskLevel) => {
    return riskLevel ? colors[riskLevel] : '#fff';
  };
};
