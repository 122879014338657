import { Box, Stack, Typography, styled } from '@mui/material';
import { useGetRiskColor } from '../../../../shared/hooks/useGetRiskColor';
import { RiskLevel } from 'shared/types/enums';

export const CountAndLevel = styled('div')`
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
`;

export const getRiskColorText = (riskLevel?: RiskLevel) => {
  return riskLevel === 2 ? '#000' : '#fff';
};

interface Props {
  level: RiskLevel;
  count?: number;
  value?: number;
}
const RiskLevelWithCount = ({ level, count, value }: Props) => {
  const getRiskColor = useGetRiskColor();
  return (
    <Stack direction="row" spacing={1.5} alignItems="center">
      <CountAndLevel key={level}>
        <Box
          aria-label={`riskvalue ${level}`}
          sx={{
            textAlign: 'center',
            borderRadius: 2,
            color: getRiskColorText(level),
            backgroundColor: getRiskColor(level),
            px: 1,
            py: 0.1,
          }}
        >
          {value && <Typography variant="body2">{value}</Typography>}
        </Box>
      </CountAndLevel>
    </Stack>
  );
};

export default RiskLevelWithCount;
