import { useGetMyReportsData } from 'sections/newmyia/backendQueries/myReportsQuery';
import { RequestTypes } from 'shared/startpageService/common/requestTypes';
import { getRequestForSection } from '../customRequest';
import BigNumber from './BigNumber';
import { IReportSettings } from 'shared/startpageService/common/types';

interface Props {
  reportSettings: IReportSettings;
}

const RiskReduction = ({ reportSettings }: Props) => {
  const request1 = getRequestForSection(reportSettings.sections[0]);
  const { data: dataArray1 } = useGetMyReportsData(RequestTypes.CustomChart, request1, {
    enabled: Boolean(request1),
  });

  const request2 = getRequestForSection(reportSettings.sections[1]);
  const { data: dataArray2 } = useGetMyReportsData(RequestTypes.CustomChart, request2, {
    enabled: Boolean(request2),
  });

  const riskBefore1 = dataArray1?.[0].series?.[0]?.data?.[0] ? Number(dataArray1[0].series[0]?.data?.[0]) : 0;
  const riskBefore2 = dataArray2?.[0].series?.[0]?.data?.[0] ? Number(dataArray2[0].series[0]?.data?.[0]) : 0;
  const riskAfter1 = dataArray1?.[0].series?.[0]?.data?.[1] ? Number(dataArray1[0].series[0]?.data?.[1]) : 0;
  const riskAfter2 = dataArray2?.[0].series?.[0]?.data?.[1] ? Number(dataArray2[0].series[0]?.data?.[1]) : 0;

  const riskBefore = riskBefore1 + riskBefore2;
  const riskAfter = riskAfter1 + riskAfter2;

  const riskReduction = riskBefore !== 0 ? Math.round(((riskBefore - riskAfter) * 100) / riskBefore) : 0;
  return <BigNumber count={riskReduction} extra={'%'} />;
};

export default RiskReduction;
