import { addDays, format, startOfDay } from 'date-fns';
import { STATUS } from './constants';
import { IRiskAssessmentObject, IStartpageListResponseItem } from './responseTypes';
import { State } from 'shared/types/enums';
import { getLink } from './links';

const baseUrl = `${window.location.origin}/PreventionIA/IA/Pages`;
export function getConfirmUrl(itemId: number): string {
  return `${baseUrl}/Occurrences/HandelseVisa.aspx?HandelseID=${itemId}`;
}

export function getCompleteUrl(itemId: number): string {
  return `${baseUrl}/RiskManagement/Checklista.aspx?HandelseID=${itemId}&VisaRader=True&VisaAtgarder=True`;
}

export function getInvestigateUrl(itemId: number): string {
  return `${baseUrl}/Occurrences/HandelseUtredning.aspx?HandelseID=${itemId}#Utredning`;
}

export function getTakeActionUrl(itemId: number, actionId?: number | null, isRiskManagement?: boolean): string {
  if (isRiskManagement) {
    return `${baseUrl}/RiskManagement/Checklista.aspx?HandelseID=${itemId}&VisaRader=True&VisaAtgarder=True&DoljKlara=True&EndastAtgarder=False&AtgardID=${actionId}`;
  }

  return `${baseUrl}/Occurrences/HandelseUppfoljning.aspx?HandelseID=${itemId}#Atgarder`;
}

export function getFollowUpUrl(itemId: number, isRiskManagement?: boolean): string {
  if (isRiskManagement) {
    return `${baseUrl}/RiskManagement/Riskhantering.aspx?HandelseID=${itemId}&VisaUppfoljning=True`;
  }

  return `${baseUrl}/Occurrences/HandelseUppfoljning.aspx?HandelseID=${itemId}#Uppfoljning`;
}

export function getRedecideUrl(itemId: number): string {
  return `${baseUrl}/Occurrences/HandelseUppfoljning.aspx?HandelseID=${itemId}#Uppfoljning`;
}

export function getRemindOrWatchUrl(itemId: number, status?: number | null) {
  if (status === STATUS.OCCURRENCE.INVESTIGATION) {
    return `${baseUrl}/Occurrences/HandelseUtredning.aspx?HandelseID=${itemId}#Utredning`;
  }

  if (status === STATUS.OCCURRENCE.INVESTIGATION_COMPLETED) {
    return `${baseUrl}/Occurrences/HandelseUppfoljning.aspx?HandelseID=${itemId}#Atgarder`;
  }

  if (status === STATUS.OCCURRENCE.COMPLETED_NOT_APPROVED) {
    return `${baseUrl}/Occurrences/HandelseUppfoljning.aspx?HandelseID=${itemId}#Uppfoljning`;
  }

  return getLink(itemId, false);
}

export const formatDate = (date: string | null) => {
  return date ? format(new Date(date), 'yyyy-LL-dd') : '';
};

export const getDateFollowUpOther = (item: IStartpageListResponseItem) => {
  return item.followUpTimeCompleted ? addDays(new Date(item.followUpTimeCompleted), 7).toDateString() : '';
};
export const sortRisks = (a: IRiskAssessmentObject, b: IRiskAssessmentObject) => b.id - a.id;

export const isRiskManagement = (item: IStartpageListResponseItem) => {
  return item?.unitRiskManagement !== null;
};
export const isOverdue = (date?: string | null, state?: State, currentDate?: Date) => {
  if (state === State.DONE) return false;
  return date ? startOfDay(new Date(date)) < startOfDay(currentDate ?? new Date()) : false;
};
