import { styled, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import { AppBarResponse } from './navbarQueries';
import Stack from '@mui/material/Stack';
import MobileMenuItem from './MobileMenuItem';
import CloseIcon from '@mui/icons-material/Close';
import MobileUserMenu from './MobileUserMenu';
import { Typography } from '@mui/material';
import { useTranslations } from 'shared/hooks/useTranslations';
import { TopBarItems } from './TopBar';
import Logo from './Logo';

const StyledMobileAppBar = styled('div')`
  background-color: #fff;
  border-top: 8px solid ${({ theme }) => theme.customComponents?.iaAppBar.defaultFill};
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;
const UserAndMenuIcon = styled('div')`
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
  }
  h6 {
    cursor: pointer;
  }
`;
const StyledMenu = styled('div')`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  z-index: 100;
  border-top: 1px solid #ddd;
`;
const StyledStack = styled(Stack)`
  margin-top: 2rem;
  padding-left: 1rem;
`;
interface Props {
  navbarData?: AppBarResponse;
}
const MobileAppBar = ({ navbarData }: Props) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const { t } = useTranslations();
  return (
    <StyledMobileAppBar>
      <Logo />
      {navbarData?.HasCompanyLogotype && (
        <Typography variant="body2" fontWeight={500} color={theme.palette.primary.main}>
          {t('General_IA-System')}
        </Typography>
      )}
      <UserAndMenuIcon>
        <MobileUserMenu userName={navbarData?.UserName} />
        {open && <CloseIcon onClick={() => setOpen(false)} />}
        {!open && <MenuIcon onClick={() => setOpen(!open)} />}
      </UserAndMenuIcon>
      {open && (
        <StyledMenu>
          <Stack>
            {navbarData?.MenuItemList.map((item) => {
              return <MobileMenuItem item={item} key={item.Text} />;
            })}
          </Stack>
          <StyledStack spacing={2}>
            <TopBarItems />
          </StyledStack>
        </StyledMenu>
      )}
    </StyledMobileAppBar>
  );
};

export default MobileAppBar;
