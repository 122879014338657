import { Autocomplete, Button, Stack, TextField, Typography } from '@mui/material';
import { useGlobalContext } from 'globalContext';
import { ajaxContentType, baseUrl, linksQuery } from 'shared/constants';
import { useTranslations } from 'shared/hooks/useTranslations';
import { Systempart } from 'shared/startpageService/charts/enums';
import { ContentLevel } from 'shared/types/enums';
import { useGetMainMenu } from '../IaAppBar/navbarQueries';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { IReport, ISection } from 'shared/startpageService/common/types';
import { iafetch, iapost } from 'shared/utils';
import { useGetLinkContentDataFromserver } from '../backendQueries/useGetData';

const CreateNewReportFromStandard = () => {
  const { t } = useTranslations();
  const { notAlreadyAddedStandardContent } = useGetLinkContentDataFromserver();
  const { userId, companyId, userCulture, companyName, industryId, sectorName } = useGlobalContext();
  const { data: userData } = useGetMainMenu();
  const queryClient = useQueryClient();
  const [selectedValue, setSelectedValue] = useState<DropDownItem | null>(null);

  interface DropDownItem {
    text: string;
    label: string;
  }

  const reports: DropDownItem[] = notAlreadyAddedStandardContent.map((x) => {
    return {
      text: x.Text,
      label: x.Text.startsWith('___') ? t(`ReportTitle_${x.Text}`) : x.Text,
    };
  });

  const setSpecificData = (sections: ISection[], level: ContentLevel) => {
    for (const section of sections) {
      if (section.settings.filter.base.level) {
        section.settings.filter.base.level.type = level;
        if (level === ContentLevel.Organization) {
          section.settings.filter.base.level.id = Number(companyId);
          section.settings.filter.base.level.name = companyName;
        }
        if (level === ContentLevel.Sector) {
          section.settings.filter.base.level.id = Number(industryId);
          section.settings.filter.base.level.name = sectorName;
        }
        if (level === ContentLevel.User) {
          section.settings.filter.base.level.id = Number(userId);
          section.settings.filter.base.level.name = userData?.UserName;
        }
      }
    }
    return sections;
  };

  const SaveNewReport =
    // (title: string, level: ContentLevel, systempart: Systempart, action: string) =>
    () => {
      const title = selectedValue?.text;
      const level = ContentLevel.Organization;
      const systempart = Systempart.START_PAGE_ORG;
      const action = '';

      const link = notAlreadyAddedStandardContent?.find((a) => a.Text === title);
      const reportId = Number(link?.Link.split('=')[1]);
      const urlMyReportsStartStr = '/PreventionIA/IA/Pages/Reports/MyReports.aspx?';

      setSelectedValue(null);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      iafetch<IReport>(`${baseUrl}/PreventionIA/IA/api/Web/Reports/MyReports/Settings?id=${reportId}`, {
        method: 'GET',
        headers: new Headers({ 'content-type': ajaxContentType }),
      }).then((data) => {
        const sections = setSpecificData(JSON.parse(data?.Settings).sections, level);

        const reportSettingsObj = {
          reportCulture: userCulture,
          title,
          sections,
        };
        const myReportSettings = JSON.stringify({
          OrganizationId: companyId,
          Settings: JSON.stringify(reportSettingsObj),
        });
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        iafetch(`${baseUrl}/PreventionIA/IA/api/Web/Reports/MyReports/Settings`, {
          method: 'POST',
          body: myReportSettings,
          headers: new Headers({ 'content-type': ajaxContentType }),
        }).then((reportId) => {
          const contentUrl = `${urlMyReportsStartStr}SettingsId=${reportId}`;

          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          iapost(`${baseUrl}/PreventionIA/IA/api/Web/Global/LinkContent/`, {
            method: 'POST',
            body: JSON.stringify({
              SystemPartId: systempart,
              OrgId: companyId,
              Text: title,
              Link: contentUrl,
              CreatedById: userId,
              SystemPart: action,
            }),
            headers: new Headers({ 'content-type': ajaxContentType }),
          }).then(() => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            queryClient.invalidateQueries({ queryKey: [linksQuery] });
          });
        });
      });
    };

  return (
    <>
      <Stack spacing={1}>
        <Typography variant="h3">Standardmoduler</Typography>
        <Typography variant="body2">Du kan också välja modul att visa från våra standardmoduler.</Typography>
        <Stack direction="row" justifyContent={'space-between'}>
          <Autocomplete
            size="small"
            disablePortal
            id="combo-box-demo"
            options={reports}
            sx={{ width: 400, background: 'white' }}
            renderInput={(params) => <TextField {...params} label="Välj modul" />}
            isOptionEqualToValue={(option, value) => option.text === value.text}
            value={selectedValue}
            onChange={(e, newValue) => {
              setSelectedValue(newValue);
            }}
          />
          <Button variant="outlined" color="primary" onClick={SaveNewReport} sx={{ background: '#fff' }}>
            Välj modul
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default CreateNewReportFromStandard;
