// eslint-disable-next-line no-restricted-imports
import { UseQueryOptions, useQuery as _useQuery } from '@tanstack/react-query';
import { useGlobalContext } from 'globalContext';
import { useEffect } from 'react';

export const useQuery = <T>(options: UseQueryOptions<T>) => {
  const { setShowLoggedOutDialog } = useGlobalContext();
  const query = _useQuery<T>(options);
  const { error } = query;

  useEffect(() => {
    if (error?.cause === 401) {
      setShowLoggedOutDialog?.(true);
    }
  }, [error, setShowLoggedOutDialog]);

  return query;
};
