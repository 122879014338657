import { getCustomRequest } from '../customRequest';
import { useGetMyReportsData } from 'shared/startpageService/common/myReportsQuery';
import { ContentLevel, DetailType } from 'shared/types/enums';
import { RequestTypes } from 'shared/startpageService/common/requestTypes';
import BigNumber from './BigNumber';
import { IDistribution, IMyReportRequest } from 'shared/startpageService/common/types';

interface Props {
  settings: IMyReportRequest;
  distribution: IDistribution;
  serie: DetailType;
  level: ContentLevel;
  showGoldStar: boolean;
}

const SumAsBigNumber = ({ settings, distribution, level, serie, showGoldStar }: Props) => {
  const widget = getCustomRequest(settings.filter, distribution.type, serie);
  const { data: dataArray } = useGetMyReportsData(RequestTypes.CustomChart, widget, level, {
    enabled: Boolean(widget),
  });

  const item = dataArray?.[0];

  const sum =
    item?.total && item.total.map((x) => x.data).length > 0
      ? item?.total?.map((x) => x.data)?.reduce((a, b) => Number(a) + Number(b))
      : 0;

  return <BigNumber count={sum} showGoldStar={showGoldStar} />;
};

export default SumAsBigNumber;
