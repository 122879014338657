import { useGlobalContext } from '../../globalContext';

type UrlParam = string | undefined;
export const useGetApiUrl = (endpoint: string, urlParams: UrlParam[], region = 'Dashboard') => {
  const { baseUrl } = useGlobalContext();
  if (urlParams.every(Boolean)) {
    return `${baseUrl ?? ''}/PreventionIA/IA/api/Web/${region}/${endpoint}/${urlParams.join('/')}`;
  }
  return '';
};
export const useGetApiUrlFavorites = (endpoint: string, urlParams: UrlParam[]) => {
  const { baseUrl } = useGlobalContext();
  if (urlParams.every(Boolean)) {
    return `${baseUrl ?? ''}/${endpoint}/${urlParams.join('/')}`;
  }
  return '';
};
