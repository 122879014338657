import { useGlobalContext } from '../../globalContext';
import sv from 'date-fns/locale/sv';
import en from 'date-fns/locale/en-GB';

const cultureToLocale: Record<string, Locale> = {
  'sv-SE': sv,
  'en-GB': en,
};

export const useDatefnsLocale = (): Locale => {
  const { userCulture } = useGlobalContext();
  return cultureToLocale[userCulture ?? ''];
};
