import { useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { useGlobalContext } from '../../../globalContext';
import { iafetch } from '../../../shared/utils';
import { useCallback, useEffect, useState } from 'react';
import { baseUrl } from 'shared/constants';
import { NewsLevel } from 'shared/types/enums';
import { useQuery } from 'shared/queries/useQueryWithError';

export interface INewsSection {
  title: string;
  items: INewsItem[];
}
export interface INewsItem {
  id: number;
  title: string;
  text: string;
  publishedDate: string;
  organizationName: string;
  occurrenceId?: number;
  occurrenceNumber?: string;
  level?: NewsLevel;
}

export type NewsResponse = Record<NewsLevel, INewsSection>;

export const useGetFrontpageNews = (options?: UseQueryOptions<NewsResponse>) => {
  const fetchNews = (url: string): Promise<NewsResponse> =>
    iafetch<NewsResponse>(url, {
      method: 'POST',
      body: JSON.stringify({}),
      headers: new Headers({ 'content-type': 'application/json; charset=UTF-8' }),
    }).then((data) => {
      return data;
    });

  const newsUrl = `${baseUrl ?? ''}/PreventionIA/IA/api/Web/Startpage/News`;
  return useQuery<NewsResponse>({
    ...options,
    queryKey: [`news`],
    queryFn: () => fetchNews(newsUrl),
    retry: false,
  });
};

export const useGetNewsForLevel = (level: NewsLevel) => {
  const { data: newsData, ...query } = useGetFrontpageNews();
  const newsItems = newsData?.[level]?.items;

  return {
    ...query,
    data: newsItems,
    hasNews: (newsItems && newsItems?.length > 0) ?? false,
  };
};

export const useGetUnreadNewsForLevel = (level: NewsLevel) => {
  const { data: newsData, ...query } = useGetFrontpageNews();
  const { unreadIds } = useHasUnreadNews();

  const unreadNewsItems = newsData?.[level]?.items.filter((a) => unreadIds.includes(a.id));

  return {
    ...query,
    unreadNewsItems,
    hasUnreadNews: (unreadNewsItems && unreadNewsItems?.length > 0) ?? false,
  };
};

export const useGetAllUnreadNews = () => {
  const { data: newsData, ...query } = useGetFrontpageNews();
  const { unreadIds } = useHasUnreadNews();

  const unreadNewsItemsOrg =
    newsData?.[NewsLevel.Organization]?.items
      .filter((a) => unreadIds.includes(a.id))
      .map((item) => ({
        ...item,
        level: NewsLevel.Organization,
      })) ?? [];

  const unreadNewsItemsSector =
    newsData?.[NewsLevel.Sector]?.items
      .filter((a) => unreadIds.includes(a.id))
      .map((item) => ({
        ...item,
        level: NewsLevel.Sector,
      })) ?? [];

  const unreadNewsItemsAfa =
    newsData?.[NewsLevel.Afa]?.items
      .filter((a) => unreadIds.includes(a.id))
      .map((item) => ({
        ...item,
        level: NewsLevel.Afa,
      })) ?? [];

  const unreadNewsItems = unreadNewsItemsOrg?.concat(unreadNewsItemsSector, unreadNewsItemsAfa);

  return {
    ...query,
    unreadNewsItems,
    hasUnreadNews: (unreadNewsItems && unreadNewsItems?.length > 0) ?? false,
  };
};

const getReadNewsIds = (storageKeyPrefix: string): number[] => {
  const readNewsIdsStored = localStorage.getItem(`${storageKeyPrefix}_readNewsIds`);
  if (readNewsIdsStored) {
    try {
      return JSON.parse(readNewsIdsStored);
    } catch {
      return [];
    }
  }
  return [];
};

export const useHasUnreadNews = () => {
  const { data: news } = useGetFrontpageNews();
  const { userId, companyId } = useGlobalContext();
  const storageKeyPrefix = `${userId}_${companyId}`;
  const { data: readIds } = useQuery<number[]>({
    queryKey: ['news-unread', userId, companyId],
    queryFn: () => Promise.resolve(getReadNewsIds(storageKeyPrefix)),
    gcTime: 0,
  });

  const queryClient = useQueryClient();
  const [currentIds, setCurrentIds] = useState<number[]>([]);

  useEffect(() => {
    if (news) {
      const lastRead = localStorage.getItem(`${storageKeyPrefix}_lastReadNews`) ?? '1970';
      setCurrentIds(
        Object.values(news)
          .flatMap((section) => section.items)
          .filter(Boolean)
          .filter((item) => new Date(item.publishedDate) > new Date(lastRead))
          .map((item) => item.id)
      );
    }
  }, [news, userId, companyId, storageKeyPrefix]);

  const setIdAsRead = useCallback(
    (id: number) => {
      if (!getReadNewsIds(storageKeyPrefix).includes(id)) {
        const ids = [...getReadNewsIds(storageKeyPrefix), id];
        localStorage.setItem(`${storageKeyPrefix}_readNewsIds`, JSON.stringify(ids));
        void queryClient.invalidateQueries({ queryKey: ['news-unread'] });
      }
    },
    [queryClient, storageKeyPrefix]
  );

  const setAllRead = () => {
    localStorage.setItem(`${storageKeyPrefix}_lastReadNews`, new Date().toString());
  };

  const unreadIds = currentIds?.filter((id) => !readIds?.includes(id));

  const hasUnread = currentIds?.some((id) => unreadIds.includes(id));
  const numberOfUnread = unreadIds.length;
  return { setIdAsRead, hasUnread, numberOfUnread, unreadIds, setAllRead };
};
