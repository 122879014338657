import { getRequestForSection } from '../customRequest';
import { RequestTypes } from 'shared/startpageService/common/requestTypes';
import BigNumber from './BigNumber';
import { IReportSettings } from 'shared/startpageService/common/types';
import { useGetMyReportsData } from 'sections/newmyia/backendQueries/myReportsQuery';
import { IMyReportsDetailsResponseItem } from 'shared/types/types';

interface Props {
  reportSettings: IReportSettings;
}

const getSumOfData = (item: IMyReportsDetailsResponseItem) => {
  return item?.total && item.total.map((x) => x.data).length > 0
    ? item?.total.map((x) => x.data)?.reduce((a, b) => Number(a) + Number(b))
    : 0;
};

const AggregatedChart = ({ reportSettings }: Props) => {
  const request1 = getRequestForSection(reportSettings?.sections[0]);
  const { data: dataArray1 } = useGetMyReportsData(RequestTypes.CustomChart, request1, {
    enabled: Boolean(request1),
  });

  const request2 = getRequestForSection(reportSettings?.sections[1]);
  const { data: dataArray2 } = useGetMyReportsData(RequestTypes.CustomChart, request2, {
    enabled: Boolean(request2),
  });

  const sum1 = dataArray1?.[0] ? getSumOfData(dataArray1?.[0]) : 0;
  const sum2 = dataArray2?.[0] ? getSumOfData(dataArray2?.[0]) : 0;
  const sum = Number(sum1) + Number(sum2);
  return <BigNumber count={sum} />;
};

export default AggregatedChart;
