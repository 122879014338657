import { Box, Button, Link, Stack, styled, Typography } from '@mui/material';
import CreateNewReportFromStandard from './CreateNewReportFromStandard';

const SettingsInfo = styled(Box)`
  margin-top: 1rem;
  background-color: #eee;
  padding: 0.5rem;
  border-radius: 3px;
`;

interface Props {
  showStandardReports?: boolean;
}

const MakeChartInfo = ({ showStandardReports = false }: Props) => {
  return (
    <SettingsInfo>
      <Stack spacing={0.5}>
        <Typography variant="h3">Moduler</Typography>
        <Typography variant="body2">
          Moduler att visa på startsidan skapas genom rapporter. Det första summerade diagrammet i rapporten visas på
          startsidan. Den rubrik du ger rapporten visas som rubrik och kommentar för summerade diagram visas i tooltip.
          När man klickar på modulen öppnas rapporten.
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="end" mt="0.5rem">
        <Link href="../Reports/MyReports.aspx?ForStartPage=True" target="new" underline="none">
          <Button variant="outlined" color="primary" sx={{ background: '#fff', flex: 'flex-end' }}>
            Skapa modul (rapport)
          </Button>
        </Link>
      </Stack>
      {showStandardReports && <CreateNewReportFromStandard />}
    </SettingsInfo>
  );
};

export default MakeChartInfo;
