import { baseUrl } from 'shared/constants';
import { iafetch } from 'shared/utils';
import { DetailType } from 'shared/types/enums';
import {
  IDistribution,
  ILinkContent,
  IMyReportRequest,
  IReport,
  IReportSettings,
} from 'shared/startpageService/common/types';
import { ReportActivation, Systempart } from 'shared/startpageService/charts/enums';
import { useQuery } from 'shared/queries/useQueryWithError';

export const useGetAllStartpageLinkContentFromServer = () => {
  const queryFn = (): Promise<ILinkContent[]> =>
    iafetch(
      `${baseUrl}/PreventionIA/IA/api/Web/Global/LinkContent/${Systempart.START_PAGE_MY},${Systempart.START_PAGE_ORG},${Systempart.START_PAGE_STANDARD},${Systempart.START_PAGE_INSPIRATION},${Systempart.START_PAGE_SECTOR}`,
      {
        method: 'GET',
        headers: new Headers({ 'content-type': 'application/json; charset=UTF-8' }),
      }
    );

  return useQuery<ILinkContent[]>({ queryKey: ['links-query'], queryFn });
};

export const useGetLinkContentDataFromserver = () => {
  const { data: allAvailableReports } = useGetAllStartpageLinkContentFromServer();

  const getModules = (systemPart: Systempart) => {
    if (!allAvailableReports) return [];
    return allAvailableReports
      .filter((a) => a.SystemPartId === systemPart)
      .sort((b, c) => b.SystemPartId - c.SystemPartId || b.SortOrder - c.SortOrder);
  };

  const getNotAlreadyAddedReports = () => {
    const orgContentNames = orgModules?.map((a) => a.Text) ?? [];
    return standardModules.filter((a) => !orgContentNames?.includes(a.Text)) ?? [];
  };

  const standardModules = getModules(Systempart.START_PAGE_INSPIRATION);

  const orgModules = getModules(Systempart.START_PAGE_ORG);
  const ownContentUser = getModules(Systempart.START_PAGE_MY);

  const activeContentOrg = orgModules.filter((a) => a.SystemPart?.includes(ReportActivation.Active));
  const activeContentOrgCount = activeContentOrg.length;
  const notAlreadyAddedStandardContent = getNotAlreadyAddedReports();

  const selectableContentUser = orgModules.filter((a) => a.SystemPart?.includes(ReportActivation.Selectable));

  const defaultContentUser = orgModules.filter((a) => a.SystemPart?.includes(ReportActivation.Default));

  const allContentUser = ownContentUser.concat(selectableContentUser);

  return {
    standardModules,
    orgModules,
    ownContentUser,
    activeContentOrg,
    activeContentOrgCount,
    notAlreadyAddedStandardContent,
    defaultContentUser,
    allContentUser,
  };
};

export const useGetReportFromServer = (id?: number) => {
  const fetchUrl: string = `${baseUrl}/PreventionIA/IA/api/Web/Reports/MyReports/Settings?id=${id}`;

  const queryFn = (): Promise<IReport> =>
    iafetch(fetchUrl, {
      method: 'GET',
      headers: new Headers({ 'content-type': 'application/json; charset=UTF-8' }),
    });

  return useQuery<IReport>({ queryKey: ['chart-query', id], queryFn, enabled: typeof id === 'number' });
};

export const useGetLinkContentIds = (systempart: Systempart, ids?: number[]) => {
  const { data: links } = useGetAllStartpageLinkContentFromServer();

  return links?.filter((link) => link.SystemPartId === systempart).map((link) => link.Id) ?? [];
};

export const useGetReportIds = (systempart: Systempart) => {
  const { data: links } = useGetAllStartpageLinkContentFromServer();
  const linksForSystempart = links?.filter((link) => link.SystemPartId === systempart);
  return linksForSystempart?.map((a) => Number(a.Link.split('=')[1])) ?? [];
};

interface ChartByLinkId {
  link: ILinkContent | undefined;
  isLoading: boolean;
  isFetching: boolean;
  reportSettings: IReportSettings;
  settings: IMyReportRequest;
  serie: DetailType;
  distribution: IDistribution | undefined;
  reportTitle: string;
}

export const useGetCustomChartByLinkId = (linkId: number): ChartByLinkId => {
  const { data: allLinks, isLoading: isLoadingLinks } = useGetAllStartpageLinkContentFromServer();
  const link = allLinks?.find((link) => link.Id === linkId);
  const reportId = link?.Link.split('=')[1];
  const { data, isLoading: isLoadingChartSettings, isFetching } = useGetReportFromServer(Number(reportId));

  const reportSettings = JSON.parse(data?.Settings ?? 'false') as IReportSettings;
  const section = reportSettings?.sections?.[0];
  const distributions = section?.settings.details?.distributions ?? [];
  const settings = section?.settings;
  const serie = section?.settings.mainChart?.load?.[0] ?? DetailType.STATUS;
  const distribution = distributions?.[0];

  const reportTitle = link?.Text ?? '';

  return {
    link,
    isLoading: isLoadingLinks || isLoadingChartSettings,
    isFetching,
    reportSettings,
    settings,
    serie,
    distribution,
    reportTitle,
  };
};

export const useGetCustomCharts = () => {};
